import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback
} from 'react';

export type PopupProps = {
  dismissable?: boolean,
  header?: React.ReactNode,
  title?: React.ReactNode,
  children?: React.ReactNode,
  footer?: React.ReactNode,

  onDissmiss?: () => void
}
export const OverlayContext = createContext<{
  loading: boolean,
  popup: PopupProps | undefined,
  setLoading: (t: boolean) => void,
  showPopup: (props: PopupProps) => void
  hidePopup: () => void
}>(undefined!);

export const useOverlayContext = () => useContext(OverlayContext);

export const OverlayProvider: React.FC = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [popup, setPopup] = useState<PopupProps>();

  const showPopup = useCallback(function showPopup(props:PopupProps){
    setPopup({...props});
  },[]);
  const hidePopup = useCallback(function hidePopup(){
    setPopup(undefined);
  },[]);

  const value = useMemo(() => ({
    loading,
    popup,
    setLoading,
    showPopup,
    hidePopup,
  }),[
    loading,
    popup,
    setLoading,
    showPopup,
    hidePopup
  ]);

  return <OverlayContext.Provider value={value}>
        {children}
    </OverlayContext.Provider>;
};