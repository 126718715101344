import React, {SVGProps} from 'react';

type LoadingProps = {
  variant?: 'vertical' | 'inline' | 'icon';
  width?: number;
  height?: number;
  className?: string;
};
export const Logo: React.FC<LoadingProps & SVGProps<SVGSVGElement>> = ({
  variant = 'vertical',
  width,
  height,
  ...rest
}) => {
  return variant === 'vertical'
    ?
    <svg width={width||310} height={height||271} viewBox="0 0 310 271" {...rest}>
      <LogoIcon x={63} y ={0} />
      <Name x={0} y ={195} />
      <Slogan x={53} y ={250}  />
    </svg>
    :
    variant === 'icon'
    ?
    <svg width={width||186} height={height||174} viewBox="0 0 186 174" {...rest}>
      <LogoIcon x={0} y ={0} />
    </svg>
    :
    variant === 'inline'
    ?
    <svg width={width||1110} height={height||174} viewBox="0 0 1110 174" {...rest}>
      <LogoIcon x={0} y ={0} />
      <Name x={236} y={37} height={110} width={874} fill="black" />
    </svg>
    :
    <>{console.error('add another type to use it in Logo.tsx')}</>;
};

const LogoIcon: React.FC<{
  gradientTop?: string;
  gradientBottom?: string;
} & SVGProps<SVGSVGElement>> = ({
  gradientTop = '#0062AF',
  gradientBottom = '#00ADEE',
  x = 0,
  y = 0,
  ...rest
}) => (
  <svg className="logo-icon" x={x} y={y} width="186" height="174" viewBox="0 0 186 174" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M159.494 118.34V140.707C191.803 88.2265 185.02 28.5861 185.02 28.5861C136.645 28.5861 93.3762 0.0112305 93.3762 0.0112305C93.3762 0.0112305 93.2472 0.102678 93 0.258678C92.7527 0.102678 92.6237 0.0112305 92.6237 0.0112305C92.6237 0.0112305 49.3334 28.5861 0.97993 28.5861C0.97993 28.5861 -5.80332 88.2265 26.5058 140.707V118.34C26.5058 116.328 23.5012 115.085 20.3192 113.768C19.4108 113.386 18.1692 112.869 17.4221 112.471C17.4221 112.471 89.0171 50.2432 91.9411 51.4643V51.3514C92.2958 51.3514 92.6452 51.3514 93.0161 51.3514C93.387 51.3514 93.7202 51.3514 94.0911 51.3514V51.4643C96.9828 50.2379 125.927 77.1344 128.625 78.7482V66.6394H149.368V96.2579L168.578 112.471C167.841 112.869 166.589 113.386 165.681 113.768C162.499 115.085 159.494 116.328 159.494 118.34Z" fill="url(#paint0_linear_3081_37648)"/>
    <path d="M93.0002 124.946C80.7839 125.192 69.145 130.196 60.5567 138.894C52.8965 146.585 48.3283 156.826 47.7212 167.668C49.7601 169.712 51.885 171.721 54.0959 173.693C56.1093 172.715 58.1766 171.853 60.2879 171.111C60.2879 170.928 60.2879 170.745 60.2879 170.573C60.0676 151.455 76.3269 137.974 93.0056 137.598C109.668 137.974 125.944 151.455 125.723 170.573C125.723 170.756 125.723 170.939 125.723 171.111C127.835 171.853 129.902 172.715 131.915 173.693C134.13 171.724 136.255 169.716 138.29 167.668C137.683 156.826 133.115 146.585 125.454 138.894C116.864 130.193 105.22 125.189 93.0002 124.946Z" fill="url(#paint1_linear_3081_37648)"/>
    <path d="M92.9998 152.219C88.729 152.273 84.6114 153.819 81.3581 156.589C78.1049 159.358 75.9203 163.178 75.1816 167.388C80.95 166.469 86.7825 166.012 92.6235 166.022H93.376C99.2171 166.012 105.05 166.469 110.818 167.388C110.079 163.178 107.895 159.358 104.641 156.589C101.388 153.819 97.2706 152.273 92.9998 152.219Z" fill="url(#paint2_linear_3081_37648)"/>
    <path d="M92.9999 98.77C73.967 99.0659 55.3211 107.086 41.6955 120.884C35.6479 126.982 30.7724 134.141 27.312 142.004C30.0985 146.438 33.1162 150.722 36.3527 154.839C43.394 129.158 67.9524 112.1 92.9999 111.637C118.047 112.116 142.606 129.158 149.647 154.839C152.884 150.722 155.901 146.438 158.688 142.004C155.227 134.141 150.352 126.982 144.304 120.884C130.679 107.086 112.033 99.0659 92.9999 98.77Z" fill="url(#paint3_linear_3081_37648)"/>

    <defs>
      <linearGradient id="paint0_linear_3081_37648" x1="93" y1="167.964" x2="93" y2="65.0417" gradientUnits="userSpaceOnUse">
      <stop stopColor={gradientBottom}/>
      <stop offset="1" stopColor={gradientTop}/>
      </linearGradient>
      <linearGradient id="paint1_linear_3081_37648" x1="93.0002" y1="167.964" x2="93.0002" y2="65.0418" gradientUnits="userSpaceOnUse">
      <stop stopColor={gradientBottom}/>
      <stop offset="1" stopColor={gradientTop}/>
      </linearGradient>
      <linearGradient id="paint2_linear_3081_37648" x1="92.9998" y1="167.964" x2="92.9998" y2="65.0416" gradientUnits="userSpaceOnUse">
      <stop stopColor={gradientBottom}/>
      <stop offset="1" stopColor={gradientTop}/>
      </linearGradient>
      <linearGradient id="paint3_linear_3081_37648" x1="92.9999" y1="167.964" x2="92.9999" y2="65.0417" gradientUnits="userSpaceOnUse">
      <stop stopColor={gradientBottom}/>
      <stop offset="1" stopColor={gradientTop}/>
      </linearGradient>
    </defs>
  </svg>
);

const Name: React.FC<{
  fill?: string
} & SVGProps<SVGSVGElement>> = ({
  fill = 'white',
  x = 0,
  y = 0,
  ...rest
}) => (
  <svg className="logo-name" x={x} y={y} width="310" height="39" viewBox="0 0 310 39" xmlns="http://www.w3.org/2000/svg" color={fill} {...rest}>
    <path d="M30.2437 2.1199C30.2437 0.673293 30.0296 0.458984 28.5842 0.458984H27.8882C26.4963 0.458984 26.2286 0.673293 26.2286 2.1199V16.961H4.86815V2.1199C4.86815 0.673293 4.60048 0.458984 3.15503 0.458984H2.51261C1.06717 0.458984 0.853027 0.673293 0.853027 2.1199V36.9456C0.853027 38.1243 1.06717 38.3922 2.35201 38.3922H3.36917C4.60048 38.3922 4.86815 38.1243 4.86815 36.9456V20.3364H26.2286V36.9456C26.2286 38.1243 26.4963 38.3922 27.7276 38.3922H28.7983C30.0296 38.3922 30.2437 38.1243 30.2437 36.9456V2.1199Z" fill="currentColor"/>
    <path d="M38.8951 26.0692C38.8951 33.838 43.8738 38.7672 52.9212 38.7672C61.8081 38.7672 66.8939 33.838 66.8939 26.0692C66.8939 18.3004 61.8081 13.2105 52.9212 13.2105C43.8738 13.2105 38.8951 18.3004 38.8951 26.0692ZM42.9102 26.0692C42.9102 20.4971 46.4435 16.5324 52.9212 16.5324C59.399 16.5324 62.8788 20.4971 62.8788 26.0692C62.8788 31.6413 59.399 35.4454 52.9212 35.4454C46.4435 35.4454 42.9102 31.6413 42.9102 26.0692Z" fill="currentColor"/>
    <path d="M75.7631 13.5856C75.0136 13.5856 74.9601 13.907 74.9601 14.925V36.892C74.9601 38.1779 75.2278 38.3922 76.5126 38.3922H77.3156C78.6005 38.3922 78.8682 38.1779 78.8682 36.892V22.6403C79.35 19.2113 81.652 16.4788 86.256 16.4788C90.3782 16.4788 91.7701 19.1041 91.7701 22.5331V36.892C91.7701 38.1779 92.0913 38.3922 93.3226 38.3922H94.1256C95.4105 38.3922 95.6781 38.1779 95.6781 36.892V22.5867C96.16 19.2113 98.462 16.4252 102.905 16.4252C106.867 16.4252 108.687 18.6219 108.687 22.8546V36.892C108.687 38.1779 108.901 38.3922 110.24 38.3922H110.989C112.274 38.3922 112.542 38.1779 112.542 36.892V22.1045C112.542 16.4788 109.704 13.2105 103.762 13.2105C99.1044 13.2105 96.3206 15.3001 94.9822 17.8718C93.9115 14.925 91.5024 13.2105 87.3802 13.2105C83.151 13.2105 80.4207 14.8179 78.8682 17.0146V16.5859C78.8682 14.4964 77.9045 13.5856 75.7631 13.5856Z" fill="currentColor"/>
    <path d="M144.274 27.7302C145.987 27.7302 146.308 26.7122 146.308 25.5335C146.308 18.1933 142.347 13.2105 133.674 13.2105C125.216 13.2105 120.558 17.9254 120.558 26.5514C120.558 35.3918 125.751 38.7672 134.049 38.7672C138.278 38.7672 141.865 37.91 143.953 36.9456C145.505 36.3026 146.201 35.0703 146.201 33.838C146.201 33.1415 145.827 32.9272 145.024 33.2487C142.026 34.3202 139.402 35.3382 134.531 35.3382C128.749 35.3382 125.109 33.5166 124.68 27.7302H144.274ZM133.621 16.4252C139.563 16.4252 142.293 19.6399 142.507 24.6762H124.68C125.216 19.5863 128.107 16.4252 133.621 16.4252Z" fill="currentColor"/>
    <path d="M181.943 37.1599C182.371 38.1779 182.586 38.3922 183.71 38.3922H185.423C186.226 38.3922 186.547 38.0171 186.226 37.1599L171.343 1.58412C171.022 0.780451 170.754 0.458984 169.577 0.458984H168.452C167.275 0.458984 167.007 0.673293 166.632 1.58412L151.696 37.1599C151.321 38.0171 151.589 38.3922 152.552 38.3922H154.051C155.176 38.3922 155.497 38.2314 155.925 37.2135L159.887 27.5158H177.982L181.943 37.1599ZM168.934 5.38815L176.59 24.1404H161.279L168.934 5.38815Z" fill="currentColor"/>
    <path d="M218.316 34.0524L211.143 15.2465C210.607 13.7463 210.393 13.5856 208.948 13.5856H207.824C206.432 13.5856 206.217 13.7463 205.629 15.2465L198.455 34.0524L191.763 15.2465C191.335 13.7999 191.067 13.5856 189.622 13.5856H188.551C187.159 13.5856 187.105 14.1214 187.641 15.4072L196.153 37.3742C196.474 38.1779 196.688 38.3922 197.598 38.3922H199.311C200.222 38.3922 200.436 38.1779 200.757 37.3742L208.412 17.6039L215.961 37.3742C216.282 38.1779 216.496 38.3922 217.406 38.3922H219.173C220.029 38.3922 220.244 38.1779 220.565 37.3742L229.184 15.4072C229.666 14.1214 229.612 13.5856 228.274 13.5856H227.15C225.811 13.5856 225.49 13.7463 225.008 15.2465L218.316 34.0524Z" fill="currentColor"/>
    <path d="M232.373 31.5342C232.373 36.3026 235.371 38.7136 241.42 38.7136C247.042 38.7136 249.986 37.1599 251.592 36.0347C251.699 37.8028 252.716 38.6065 254.697 38.6065C255.447 38.6065 255.5 38.285 255.5 37.267V21.6223C255.5 14.0678 248.219 13.2105 244.151 13.2105C241.099 13.2105 237.887 13.7463 236.335 14.2285C234.996 14.6571 234.407 15.4608 234.407 16.9074C234.407 17.6039 234.622 17.7647 236.013 17.3896C237.887 16.9074 240.671 16.4252 243.562 16.4252C246.988 16.4252 251.592 16.9074 251.592 22.1581V25.4263C248.701 25.0513 245.382 24.9441 242.866 24.9441C235.96 24.9441 232.373 26.6586 232.373 31.5342ZM242.331 35.6597C238.53 35.6597 236.228 34.6417 236.228 31.5342C236.228 28.3731 239.065 27.8909 243.669 27.8909C245.757 27.8909 248.969 27.9445 251.592 28.3195V33.1415C249.879 34.3202 247.416 35.6597 242.331 35.6597Z" fill="currentColor"/>
    <path d="M276.612 16.3716C277.843 16.3716 278.593 16.5324 279.878 16.8003C280.681 16.9074 280.734 16.7467 280.734 15.8894C280.734 14.1749 279.824 12.9962 276.452 12.9962C273.025 12.9962 270.349 14.3892 268.85 18.3004V16.5859C268.85 14.4964 267.886 13.5856 265.745 13.5856C264.995 13.5856 264.942 13.907 264.942 14.925V36.892C264.942 38.1779 265.209 38.3922 266.494 38.3922H267.297C268.582 38.3922 268.85 38.1779 268.85 36.892V26.9801C268.957 20.3364 271.419 16.3716 276.612 16.3716Z" fill="currentColor"/>
    <path d="M307.441 27.7302C309.154 27.7302 309.475 26.7122 309.475 25.5335C309.475 18.1933 305.514 13.2105 296.841 13.2105C288.382 13.2105 283.725 17.9254 283.725 26.5514C283.725 35.3918 288.918 38.7672 297.216 38.7672C301.445 38.7672 305.032 37.91 307.12 36.9456C308.672 36.3026 309.368 35.0703 309.368 33.838C309.368 33.1415 308.993 32.9272 308.19 33.2487C305.192 34.3202 302.569 35.3382 297.697 35.3382C291.916 35.3382 288.275 33.5166 287.847 27.7302H307.441ZM296.787 16.4252C302.73 16.4252 305.46 19.6399 305.674 24.6762H287.847C288.382 19.5863 291.273 16.4252 296.787 16.4252Z" fill="currentColor"/>
  </svg>
);

const Slogan: React.FC<{
  fill?: string
} & SVGProps<SVGSVGElement>> = ({
  fill = '#00A1D5',
  x = 0,
  y = 0,
  ...rest
})  => (
  <svg className="logo-slogan" x={x} y={y} width="206" height="20" viewBox="0 0 206 20" xmlns="http://www.w3.org/2000/svg" color={fill} {...rest}>
    <path d="M8.0779 1.23753C7.58821 1.23753 7.55054 1.27523 6.94786 1.93494L1.50483 7.0242V1.7276C1.50483 1.31292 1.44833 1.23753 1.03398 1.23753H0.826807C0.431293 1.23753 0.355957 1.31292 0.355957 1.7276V14.1492C0.355957 14.5073 0.431293 14.5827 0.789139 14.5827H1.09048C1.42949 14.5827 1.50483 14.5073 1.50483 14.1492V7.45773L7.53171 14.168C7.85189 14.5638 7.88956 14.5827 8.3604 14.5827H8.73708C9.1326 14.5827 9.22677 14.4507 8.83126 13.9795L2.78554 7.23154L8.45457 1.89725C8.96309 1.38832 8.85009 1.23753 8.41691 1.23753H8.0779Z" fill="currentColor"/>
    <path d="M17.9663 14.1303V9.04105C17.9663 7.0242 17.0811 5.72361 14.8964 5.72361C13.4273 5.72361 12.5045 6.34563 12.0148 7.1373V6.74146C12.0148 6.13829 11.7511 5.85556 11.1108 5.85556C10.9036 5.85556 10.8847 5.9498 10.8847 6.25139V14.1303C10.8847 14.5261 10.9601 14.5827 11.3368 14.5827H11.5628C11.9395 14.5827 12.0148 14.5261 12.0148 14.1303V9.00336C12.222 7.68392 13.013 6.72262 14.6139 6.72262C16.2524 6.72262 16.8363 7.70277 16.8363 9.11645V14.1303C16.8363 14.5261 16.9116 14.5827 17.2883 14.5827H17.5143C17.891 14.5827 17.9663 14.5261 17.9663 14.1303Z" fill="currentColor"/>
    <path d="M20.5469 10.2474C20.5469 12.9805 22.1101 14.7146 24.9917 14.7146C27.8356 14.7146 29.4365 12.9805 29.4365 10.2474C29.4365 7.51428 27.8356 5.72361 24.9917 5.72361C22.1101 5.72361 20.5469 7.51428 20.5469 10.2474ZM21.6958 10.2474C21.6958 8.174 22.8635 6.72262 24.9917 6.72262C27.1388 6.72262 28.2688 8.174 28.2688 10.2474C28.2688 12.3208 27.1388 13.7156 24.9917 13.7156C22.8635 13.7156 21.6958 12.3208 21.6958 10.2474Z" fill="currentColor"/>
    <path d="M40.0907 13.301L37.7364 6.36448C37.5858 5.9121 37.5104 5.85556 37.0961 5.85556H36.7759C36.3615 5.85556 36.305 5.9121 36.1355 6.36448L33.7813 13.301L31.54 6.36448C31.427 5.93095 31.3517 5.85556 30.9185 5.85556H30.6172C30.2217 5.85556 30.2028 6.00635 30.3347 6.40218L33.0844 14.2623C33.1786 14.5073 33.2539 14.5827 33.5176 14.5827H34.0261C34.2898 14.5827 34.3651 14.5073 34.4593 14.2623L36.9454 7.08075L39.4127 14.2623C39.488 14.5073 39.5633 14.5827 39.8458 14.5827H40.3355C40.5992 14.5827 40.6745 14.5073 40.7687 14.2623L43.5373 6.40218C43.688 6.0252 43.6691 5.85556 43.2736 5.85556H42.9535C42.5579 5.85556 42.4638 5.89326 42.3319 6.36448L40.0907 13.301Z" fill="currentColor"/>
    <path d="M58.4465 13.301L56.0922 6.36448C55.9415 5.9121 55.8662 5.85556 55.4519 5.85556H55.1317C54.7173 5.85556 54.6608 5.9121 54.4913 6.36448L52.1371 13.301L49.8958 6.36448C49.7828 5.93095 49.7075 5.85556 49.2743 5.85556H48.973C48.5775 5.85556 48.5586 6.00635 48.6905 6.40218L51.4402 14.2623C51.5344 14.5073 51.6097 14.5827 51.8734 14.5827H52.3819C52.6456 14.5827 52.7209 14.5073 52.8151 14.2623L55.3012 7.08075L57.7684 14.2623C57.8438 14.5073 57.9191 14.5827 58.2016 14.5827H58.6913C58.955 14.5827 59.0303 14.5073 59.1245 14.2623L61.8931 6.40218C62.0438 6.0252 62.0249 5.85556 61.6294 5.85556H61.3092C60.9137 5.85556 60.8196 5.89326 60.6877 6.36448L58.4465 13.301Z" fill="currentColor"/>
    <path d="M71.0123 14.1303V9.04105C71.0123 7.0242 70.1082 5.72361 67.9423 5.72361C66.4733 5.72361 65.5504 6.32678 65.0607 7.11845V1.7653C65.0607 1.16213 64.7971 0.879395 64.1567 0.879395C63.9495 0.879395 63.9307 0.97364 63.9307 1.27523V14.1303C63.9307 14.5261 64.006 14.5827 64.3827 14.5827H64.6087C64.9854 14.5827 65.0607 14.5261 65.0607 14.1303V9.00336C65.2679 7.68392 66.0589 6.72262 67.6598 6.72262C69.2984 6.72262 69.8822 7.70277 69.8822 9.11645V14.1303C69.8822 14.5261 69.9576 14.5827 70.3343 14.5827H70.5603C70.9369 14.5827 71.0123 14.5261 71.0123 14.1303Z" fill="currentColor"/>
    <path d="M73.5911 10.2474C73.5911 12.9805 75.1543 14.7146 78.0359 14.7146C80.8798 14.7146 82.4807 12.9805 82.4807 10.2474C82.4807 7.51428 80.8798 5.72361 78.0359 5.72361C75.1543 5.72361 73.5911 7.51428 73.5911 10.2474ZM74.7399 10.2474C74.7399 8.174 75.9076 6.72262 78.0359 6.72262C80.183 6.72262 81.313 8.174 81.313 10.2474C81.313 12.3208 80.183 13.7156 78.0359 13.7156C75.9076 13.7156 74.7399 12.3208 74.7399 10.2474Z" fill="currentColor"/>
    <path d="M84.19 4.38533C84.1335 4.61152 84.3219 4.70576 84.4725 4.55497C85.6026 3.17899 86.2806 2.46272 86.4125 2.02919C86.5631 1.55796 86.1865 1.25638 85.8098 1.25638C85.5838 1.25638 85.3389 1.36947 85.2071 1.61451C85.0564 1.93494 84.8116 2.802 84.19 4.38533Z" fill="currentColor"/>
    <path d="M90.2256 6.66607C91.2803 6.66607 91.9395 6.85456 92.4857 7.00535C92.9 7.11845 92.9942 7.11845 92.9942 6.85456C92.9942 6.42103 92.787 6.19484 92.335 6.04405C91.6946 5.83671 91.092 5.72361 90.2068 5.72361C88.6059 5.72361 86.9673 6.19484 86.9673 8.00435C86.9673 11.1145 92.2032 9.66307 92.2032 12.2077C92.2032 13.4706 91.1673 13.7722 89.8678 13.7722C88.7754 13.7722 87.8148 13.5083 87.1557 13.2256C86.7225 13.0371 86.6471 13.0559 86.6471 13.3575C86.6471 13.791 86.7601 14.0738 87.2122 14.2246C87.8148 14.4884 88.6624 14.6958 89.8866 14.6958C91.6193 14.6958 93.2767 14.2434 93.2767 12.17C93.2767 8.75832 88.0409 10.1155 88.0409 7.96666C88.0409 7.04305 88.8884 6.66607 90.2256 6.66607Z" fill="currentColor"/>
    <path d="M101.22 5.85556C101.012 5.85556 100.993 5.9498 100.993 6.25139V14.1303C100.993 14.5261 101.069 14.5827 101.446 14.5827H101.672C102.048 14.5827 102.124 14.5261 102.124 14.1303V6.74146C102.124 6.13829 101.841 5.85556 101.22 5.85556ZM100.824 2.42502C100.824 2.82085 101.125 3.08474 101.54 3.08474C101.973 3.08474 102.274 2.82085 102.274 2.42502C102.274 2.01034 101.973 1.74645 101.54 1.74645C101.125 1.74645 100.824 2.01034 100.824 2.42502Z" fill="currentColor"/>
    <path d="M112.467 14.1303V9.04105C112.467 7.0242 111.582 5.72361 109.397 5.72361C107.928 5.72361 107.006 6.34563 106.516 7.1373V6.74146C106.516 6.13829 106.252 5.85556 105.612 5.85556C105.405 5.85556 105.386 5.9498 105.386 6.25139V14.1303C105.386 14.5261 105.461 14.5827 105.838 14.5827H106.064C106.44 14.5827 106.516 14.5261 106.516 14.1303V9.00336C106.723 7.68392 107.514 6.72262 109.115 6.72262C110.753 6.72262 111.337 7.70277 111.337 9.11645V14.1303C111.337 14.5261 111.413 14.5827 111.789 14.5827H112.015C112.392 14.5827 112.467 14.5261 112.467 14.1303Z" fill="currentColor"/>
    <path d="M122.025 16.0152C121.29 17.9755 120.556 18.2017 119.953 18.2017C119.539 18.2017 119.313 18.0886 119.106 17.9944C118.936 17.9001 118.823 17.9567 118.823 18.1829C118.823 18.7106 119.275 19.0499 120.217 19.0499C121.441 19.0499 122.251 18.2771 123.08 16.2226L127.054 6.42103C127.242 5.9498 127.091 5.85556 126.846 5.85556H126.432C126.131 5.85556 126.055 5.89325 125.867 6.38333L123.023 13.5083L120.443 6.34563C120.292 5.89326 120.236 5.85556 119.859 5.85556H119.501C119.2 5.85556 119.106 5.9875 119.275 6.40218L122.176 14.2623C122.27 14.5073 122.345 14.5827 122.59 14.5827L122.025 16.0152Z" fill="currentColor"/>
    <path d="M128.622 10.2474C128.622 12.9805 130.185 14.7146 133.066 14.7146C135.91 14.7146 137.511 12.9805 137.511 10.2474C137.511 7.51428 135.91 5.72361 133.066 5.72361C130.185 5.72361 128.622 7.51428 128.622 10.2474ZM129.771 10.2474C129.771 8.174 130.938 6.72262 133.066 6.72262C135.214 6.72262 136.344 8.174 136.344 10.2474C136.344 12.3208 135.214 13.7156 133.066 13.7156C130.938 13.7156 129.771 12.3208 129.771 10.2474Z" fill="currentColor"/>
    <path d="M140.337 5.85556C140.13 5.85556 140.111 5.9498 140.111 6.25139V11.4914C140.111 13.4329 140.977 14.6958 143.106 14.6958C144.593 14.6958 145.573 14.0549 146.081 13.2444V13.7722C146.081 14.3753 146.345 14.6581 146.985 14.6581C147.193 14.6581 147.211 14.5638 147.211 14.2623V6.74146C147.211 6.13829 146.929 5.85556 146.307 5.85556C146.081 5.85556 146.081 5.9498 146.081 6.25139V11.3218C145.912 12.6412 145.083 13.6591 143.35 13.6591C141.825 13.6591 141.241 12.7543 141.241 11.4726V6.74146C141.241 6.13829 140.977 5.85556 140.337 5.85556Z" fill="currentColor"/>
    <path d="M153.954 6.66607C154.35 6.66607 154.595 6.72262 154.99 6.81686C155.235 6.87341 155.254 6.81686 155.254 6.53412C155.254 6.04405 154.971 5.66707 153.917 5.66707C152.768 5.66707 151.901 6.17599 151.43 7.62737V6.74146C151.43 6.13829 151.167 5.85556 150.526 5.85556C150.319 5.85556 150.3 5.9498 150.3 6.25139V14.1303C150.3 14.5261 150.376 14.5827 150.752 14.5827H150.978C151.355 14.5827 151.43 14.5261 151.43 14.1303V10.5301C151.468 8.1363 152.278 6.66607 153.954 6.66607Z" fill="currentColor"/>
    <path d="M168.898 14.1303V9.04105C168.898 7.0242 167.994 5.72361 165.828 5.72361C164.359 5.72361 163.436 6.32678 162.946 7.11845V1.7653C162.946 1.16213 162.682 0.879395 162.042 0.879395C161.835 0.879395 161.816 0.97364 161.816 1.27523V14.1303C161.816 14.5261 161.891 14.5827 162.268 14.5827H162.494C162.871 14.5827 162.946 14.5261 162.946 14.1303V9.00336C163.153 7.68392 163.944 6.72262 165.545 6.72262C167.184 6.72262 167.768 7.70277 167.768 9.11645V14.1303C167.768 14.5261 167.843 14.5827 168.22 14.5827H168.446C168.822 14.5827 168.898 14.5261 168.898 14.1303Z" fill="currentColor"/>
    <path d="M171.476 10.2474C171.476 12.9805 173.04 14.7146 175.921 14.7146C178.765 14.7146 180.366 12.9805 180.366 10.2474C180.366 7.51428 178.765 5.72361 175.921 5.72361C173.04 5.72361 171.476 7.51428 171.476 10.2474ZM172.625 10.2474C172.625 8.174 173.793 6.72262 175.921 6.72262C178.068 6.72262 179.198 8.174 179.198 10.2474C179.198 12.3208 178.068 13.7156 175.921 13.7156C173.793 13.7156 172.625 12.3208 172.625 10.2474Z" fill="currentColor"/>
    <path d="M183.267 5.85556C183.06 5.85556 183.041 5.9498 183.041 6.25139V14.1303C183.041 14.5261 183.116 14.5827 183.493 14.5827H183.719C184.096 14.5827 184.171 14.5261 184.171 14.1303V8.96566C184.359 7.70277 185.094 6.70377 186.601 6.70377C187.938 6.70377 188.409 7.64622 188.409 8.87141V14.1303C188.409 14.5261 188.484 14.5827 188.861 14.5827H189.087C189.463 14.5827 189.539 14.5261 189.539 14.1303V8.92796C189.708 7.70277 190.462 6.68492 191.912 6.68492C193.174 6.68492 193.795 7.43888 193.795 8.98451V14.1303C193.795 14.5261 193.871 14.5827 194.247 14.5827H194.473C194.831 14.5827 194.925 14.5261 194.925 14.1303V8.75832C194.925 6.83571 194.021 5.72361 192.138 5.72361C190.65 5.72361 189.765 6.47758 189.35 7.40118C189.03 6.36448 188.258 5.72361 186.921 5.72361C185.527 5.72361 184.642 6.32678 184.171 7.1373V6.74146C184.171 6.13829 183.907 5.85556 183.267 5.85556Z" fill="currentColor"/>
    <path d="M205.147 10.7563C205.655 10.7563 205.749 10.4547 205.749 10.1155C205.749 7.45773 204.487 5.72361 201.738 5.72361C199.063 5.72361 197.594 7.38233 197.594 10.417C197.594 13.4706 199.176 14.7146 201.851 14.7146C203.226 14.7146 204.393 14.3753 205.052 14.0361C205.523 13.8099 205.712 13.4517 205.712 13.0936C205.712 12.8674 205.599 12.8109 205.354 12.9051C204.412 13.301 203.565 13.6968 201.983 13.6968C200.043 13.6968 198.875 12.924 198.762 10.7563H205.147ZM201.738 6.68492C203.678 6.68492 204.6 7.89126 204.638 9.83272H198.781C198.913 7.87241 199.873 6.68492 201.738 6.68492Z" fill="currentColor"/>
  </svg>

);
