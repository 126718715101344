import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react';

import {

  startOfDay,
  endOfDay,

  startOfWeek,
  endOfWeek,

  startOfHour,
  startOfMonth,
  endOfMonth
} from 'date-fns';

import {
  getRawActivityReport,
  ActivityReportItem,
} from 'api';
import {
  PROVIDERS_ARRAY,

} from 'config';
import {
  useMQTTCellScan
} from 'hooks';
import {
  perSessionStorage
} from 'storage';
import {
  processRawActivityReportItem,
  processRawCellScanMqttToRawActivity,
  RawActivityReport,
  RawActivityReportItem
} from 'utils';

type IndexedActivityReport = {
  label: string,
  items: ActivityReportItem[]
};

type DailyProviderActivityItem = {
  items: any[],
  amount: number,
  startDate: Date,
  label: string;
}

export type UseActivityReportContext = {
  unsortedActivityReport: (ActivityReportItem&{label:string})[],
  indexedActivityReports: IndexedActivityReport[],
  dailyProviderActivity: DailyProviderActivityItem[],
  loading: boolean,
  enabledProviders: {
    [key: string]: boolean
  },
  setEnabledProviders: (value: {
    [key: string]: boolean
  }) => void,
  sensorId: string|undefined,
  setSensorId: (v: string) => void,
  dateMode: 'day'|'week'|'month',
  setDateMode: (value:'day'|'week'|'month') => void,
  dateRange: [Date, Date?],
  setDateRange: (range:[Date, Date?]) => void,
  filteredActivityReport: IndexedActivityReport[],
  startDate: Date
  endDate?: Date,
  cutoffDate: Date|number
};
export type UseActivityReportPayload = {
  sensorId?: string;
  startDate?: Date;
  dateMode?: UseActivityReportContext['dateMode'];

  liveGraphUpdateRate?: number;
  allowLive?: boolean
  preserveByName?: string;
}

export type UseActivityReportHook = (payload?:UseActivityReportPayload) => (UseActivityReportContext);

export const useActivityReport: UseActivityReportHook = (props={}) => {
  const {
    sensorId: passedSensorId,
    startDate: passedStartDate,
    dateMode: passedDateMode,
    liveGraphUpdateRate = 60 * 1000,
    allowLive = true,

    preserveByName
  } = props;
  const [unsortedActivityReport, setUnsortedActivityReport] = useState<UseActivityReportContext['unsortedActivityReport']>([]);
  const [indexedActivityReports, setIndexedActivityReports] = useState<UseActivityReportContext['indexedActivityReports']>([]);
  //const [dailyTmsiActivityByProvider, setDailyTmsiActivityByProvider] = useState<Array<DailyTmsiActivityByProviderItem>>();
  //const dailyTmsiActivityByProviderIndexRef = useRef<{[key: number]: DailyTmsiActivityByProviderItem}>();
  const [dailyProviderActivity, setDailyProviderActivity] = useState<UseActivityReportContext['dailyProviderActivity']>([]);

  const [loading, setLoading] = useState<UseActivityReportContext['loading']>(false);

  const [enabledProviders, setEnabledProviders] = useState<UseActivityReportContext['enabledProviders']>({
    'at&t':true,
    'verizon':true,
    't-mobile':true,
    'other':true
  });
  const [sensorId, setSensorId] = useState<UseActivityReportContext['sensorId']|undefined>(passedSensorId);
  //const [epochStart, setEpochStart] = useState<number>(1659330000);
  //const [epochEnd, setEpochEnd] = useState<number>(1659848400);
  const [dateMode, setDateModeState] = useState<UseActivityReportContext['dateMode']>( useMemo(function(){
    if(passedDateMode && preserveByName){
      perSessionStorage.setItem('useActivityReport_date_mode_'+preserveByName, String(passedDateMode));
    }
    const preservedDateMode = preserveByName ? perSessionStorage.getItem('useActivityReport_date_mode_'+preserveByName) : null;
    const datemode = (passedDateMode||preservedDateMode||'week') as UseActivityReportContext['dateMode'];

    return datemode;
  }, [passedDateMode, preserveByName]) );

  const defaultDates = useMemo(function(){
    // assign default date
    const newDefaultDates = [];
    if(passedStartDate && preserveByName){
      perSessionStorage.setItem('useActivityReport_date_'+preserveByName, String(Number(passedStartDate)));
    }
    const preservedDateValue = preserveByName ? perSessionStorage.getItem('useActivityReport_date_'+preserveByName) : null;
    const preservedDate = new Date(Number(preservedDateValue));
    const preservedIsValidDate = preservedDate && !isNaN(Number(preservedDate));

    const now = passedStartDate || (preservedDate && preservedIsValidDate ? preservedDate : new Date());

    if(dateMode === 'day'){
      const startDate = startOfDay(now);
      const endDate = endOfDay(now);

      newDefaultDates.push(startDate, endDate);
    }
    else if (dateMode === 'week'){
      const startDate = startOfWeek(now);
      const endDate = endOfWeek(now);

      newDefaultDates.push(startDate, endDate);
    }
    else if (dateMode === 'month'){
      const startDate = startOfMonth(now);
      const endDate = endOfMonth(now);

      newDefaultDates.push(startDate, endDate);
    }
    return newDefaultDates as [Date, Date?];
  },[passedStartDate, preserveByName, dateMode]);

  const [dateRange, setDateRangeState] = useState<UseActivityReportContext['dateRange']>(defaultDates);

  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  const setDateMode = useCallback(function(value: typeof dateMode){
    if(preserveByName){
      perSessionStorage.setItem('useActivityReport_date_mode_'+preserveByName, value);
    }
    setDateModeState(value);
  },[setDateModeState, preserveByName]);

  const setDateRange = useCallback(function(value: typeof dateRange){
    if(preserveByName){
      perSessionStorage.setItem('useActivityReport_date_'+preserveByName, String(Number(value[0])) );
    }

    setDateRangeState(value);
  },[setDateRangeState, preserveByName]);

  const [cutoffDate, setCutoffDate] = useState<Date|number>(new Date());

  useEffect(function(){
    setSensorId(passedSensorId||'');
  },[passedSensorId, setSensorId]);


  // MQTT subscribtion
  const onNewCellScanDataRef = useRef<(item: RawActivityReportItem) => void>();
  const onCloseFn = useRef<() => void>();
  useMQTTCellScan(sensorId||'', {
    onNext: function(newData){
      const rawActivity = processRawCellScanMqttToRawActivity(newData);

      console.log('[useActivityReport] got new data', {newData, rawActivity});
      if(rawActivity){
        onNewCellScanDataRef.current?.(rawActivity);
      }
    },
    onClose: function(){
      onCloseFn.current?.();
      console.log('[useActivityReport] closed connection');
    }
  });

  // GET AND INDEX DATA
  // get test data set
  const processRawitemsToState = useCallback(function(rawItems: RawActivityReport){
    const newUnsortedActivityReport: (ActivityReportItem&{label:string})[] = [];
    const newIndexedActivityReportsObject: {
      [key: string]: IndexedActivityReport
    } = {};
    // const newDailtyActivityIndex: {
    //   [key: string]: DailyTmsiActivityByProviderItem
    // } = {};
    const newDailyProviderActivityIndex: {
      [key: string]: DailyProviderActivityItem
    } = {};

    const matchProvidersRegex = new RegExp(
      PROVIDERS_ARRAY.join('|'),
      'i'
    );

    // process and idex items
    (rawItems).forEach(rawItem => {
      const processedItem = processRawActivityReportItem(rawItem);

      const label = processedItem.networkProvider.match(matchProvidersRegex)?.[0].toLowerCase()||'other';

      if(label){
        if(!newIndexedActivityReportsObject[label]){
          newIndexedActivityReportsObject[label] = {
            label,
            items: [] as ActivityReportItem[]
          };
        }
        const indexedReport = newIndexedActivityReportsObject[label];
        indexedReport.items.push(processedItem);

        const dayDate = startOfDay(processedItem.date);
        const dayIndexLabel = String(dayDate.getTime())+'_'+label;
        // if(!newDailtyActivityIndex[dayIndexLabel]){
        //   newDailtyActivityIndex[dayIndexLabel] = {
        //     itemsByProviderLabel: {},
        //     startDate: dayDate
        //   }
        // }
        // if(!newDailtyActivityIndex[dayIndexLabel]['itemsByProviderLabel'][label]){
        //   newDailtyActivityIndex[dayIndexLabel]['itemsByProviderLabel'][label] = [];
        // }

        if(!newDailyProviderActivityIndex[dayIndexLabel]){
          newDailyProviderActivityIndex[dayIndexLabel] = {
            items: [],
            amount: 0,
            startDate: dayDate,
            label
          };
        }

        const dailyIndexItem = newDailyProviderActivityIndex[dayIndexLabel];
        dailyIndexItem.items.push(processedItem);
        dailyIndexItem.amount += processedItem.amount;
      }
      newUnsortedActivityReport.push({...processedItem, label});
    });

    setUnsortedActivityReport(newUnsortedActivityReport);
    setIndexedActivityReports(Object.values(newIndexedActivityReportsObject));
    setDailyProviderActivity(Object.values(newDailyProviderActivityIndex));

    //setDailyTmsiActivityByProvider(Object.values(newDailtyActivityIndex));
    // preserve index for quick search
    //dailyTmsiActivityByProviderIndexRef.current = newDailtyActivityIndex;

    console.log('[ActivityReport] raw items', {
      rawItems,
      newUnsortedActivityReport,
      newIndexedActivityReportsObject,
      newDailyProviderActivityIndex
    });
  },[
    setUnsortedActivityReport,
    setIndexedActivityReports,
    setDailyProviderActivity
  ]);
  useEffect(function(){
    if(!sensorId){ return; }

    const epochStart = Math.round(startDate.getTime()/1000);
    const epochEnd = Math.round( (!endDate ? new Date() : endDate).getTime()/1000);
    const isLiveGraph = allowLive && (!endDate||endDate.getTime() >= Date.now());

    const rawItems: RawActivityReport = [];
    const rawItemsIndex: {
      [key: string]: RawActivityReport[0]
    } = {};

    (async function getActivityReportData(){

      setLoading(true);

      const newRawItems = //testData["Items Returned"]; // use testData instead
        await getRawActivityReport({
          sensorId: Number(sensorId),
          epochStart: Number(epochStart),
          epochEnd: Number(epochEnd)
        }) || [];

      if(isLiveGraph){
        setCutoffDate(Date.now());
      }

      // Handle items update indexing
      // update existing items
      newRawItems.forEach(item => {
        const itemIdentifier = String(item.NETWORK_PROVIDER + item.DATETIME);
        const existingItem = rawItemsIndex[itemIdentifier];

        if(existingItem){
          existingItem.NUM_RECORDS += item.NUM_RECORDS;
        } else {
          rawItemsIndex[itemIdentifier] = item;
          rawItems.push(item);
        }
      });

      processRawitemsToState(rawItems);
      setLoading(false);
    })();

    if(isLiveGraph){
      onNewCellScanDataRef.current = function(item){

        const itemIdentifier = String(item.NETWORK_PROVIDER + startOfHour(new Date(item.DATETIME)).toUTCString() );
        const existingItem = rawItemsIndex[itemIdentifier];

        console.log('[useActivityReport] added new data:', {rawItems, itemIdentifier, existingItem, item});

        if(existingItem){
          existingItem.NUM_RECORDS += item.NUM_RECORDS;
        } else {
          rawItemsIndex[itemIdentifier] = item;
          rawItems.push(item);
        }

        processRawitemsToState(rawItems);
        setCutoffDate(new Date());

        console.log('[useActivityReport] added new data:', {rawItems, itemIdentifier, existingItem, item});
      };
    }

    // cleanup
    return () => {
      onNewCellScanDataRef.current = undefined;
    };
  }, [startDate, endDate, sensorId, processRawitemsToState, allowLive, liveGraphUpdateRate]);

  const filteredActivityReport = useMemo(() =>
    indexedActivityReports?.filter(group => enabledProviders[group.label]),
    [indexedActivityReports, enabledProviders]);

  return {
    unsortedActivityReport,
    indexedActivityReports,
    dailyProviderActivity,
    loading,
    enabledProviders,
    setEnabledProviders,
    dateMode,
    setDateMode,
    dateRange,
    setDateRange,
    sensorId,
    setSensorId,
    filteredActivityReport,
    startDate,
    endDate,
    cutoffDate
  };
};
