export type NetworkProviderLabel = 'at&t'|'verizon'|'t-mobile'|'other';
export const PROVIDERS_ARRAY = [ 'at&t', 't-mobile', 'verizon', 'other' ];

export const TMSILabelsMap = new Map<NetworkProviderLabel, string>();
TMSILabelsMap.set('at&t', 'AT&T');
TMSILabelsMap.set('t-mobile', 'T-mobile');
TMSILabelsMap.set('verizon', 'Verizon');
TMSILabelsMap.set('other', 'Other');

export const TMSILabelColorsMap = new Map<NetworkProviderLabel, string>();
TMSILabelColorsMap.set('at&t', '#0FB5DA');
TMSILabelColorsMap.set('verizon', '#F00202');
TMSILabelColorsMap.set('t-mobile', '#E100AF');
TMSILabelColorsMap.set('other', '#EF9A26');
