import styled from 'styled-components';

export type SelectProps = {
  disabled?:boolean;

  value?: string;
  values?: {
    [key: string]: string
  };

  status?: 'accepted' | 'bad' | 'unfulfilled' | undefined;

  variant?: 'normal' | 'inverse';

  id?: string;

  el?: React.MutableRefObject<HTMLSelectElement>;
  name?: string;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
export const Select: React.FC<SelectProps> = ({
  disabled,
  el,
  id,
  name,
  variant='normal',
  value:passedValue,
  defaultValue,
  values={},
  status,
  className,
  // TODO enable default values
  //defaultValue,
  onChange=()=>{/**/}
}) => {

  return <StyledSelectElement
    disabled={disabled}
    variant={variant}
    ref={el}
    key={id}
    name={name}
    status={status}
    className={className}
    onChange={onChange}
    defaultValue={defaultValue}
    value={passedValue}>

    {Object.entries(values).map( ([key, value]) =>
      <option
        key={key}
        value={key}>{value}</option> )}

  </StyledSelectElement>;
};



const StyledSelectElement = styled.select.attrs({} as {
  status: string;
  variant: string;
})`${({theme, status, variant}) => `
  border: 1px solid ${theme.colors.sky.light};
  padding: ${theme.fontPadding.base}px;
  border-radius: ${theme.fontPadding.base/2}px;
  font-family: ${theme.fontFamily.inter};
  font-size: ${theme.fontSize.base}px;
  color: ${theme.colors.ink.darkest};

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  position: relative;

  background-color: transparent;

  /* icon */
  ${variant === 'inverse' ? `
    background-image: url("data:image/svg+xml,%3Csvg stroke='${encodeURIComponent(theme.colors.sky.lightest)}' fill='${encodeURIComponent(theme.colors.sky.lightest)}' stroke-width='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  `:''}
  ${variant === 'normal' ? `
    background-image: url("data:image/svg+xml,%3Csvg stroke='${encodeURIComponent(theme.colors.ink.lightest)}' fill='${encodeURIComponent(theme.colors.ink.lightest)}' stroke-width='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  `:''}

  padding-right: 50px;

  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 1.25em top 50%;
  /* icon size, then gradient */
  background-size: 0.75em auto;

  /* outlines */
  outline: none;

  outline-offset: -2px;
  outline-width: 2px;

  &:focus {
    box-shadow: 0 0 10px rgba(${
      (!status && theme.colorsRgb.blue.base.toString())
      || (status === 'accepted' && theme.colorsRgb.blue.base.toString())
      || (status === 'unfulfilled' && theme.colorsRgb.orange.base.toString())
      || (status === 'bad' && theme.colorsRgb.red.base.toString()) }, 0.7);
  }

  ${!status ? `
    outline-color: rgba(${theme.colorsRgb.blue.base}, 0.7);
  `:''}
  ${status === 'accepted' ? `
    outline-color: rgba(${theme.colorsRgb.blue.light}, 0.7);

    outline-style: solid;
  `:''}

  ${status === 'unfulfilled' ? `
    outline-color: rgba(${theme.colorsRgb.orange.light}, 0.7);
    outline-style: solid;
  `:''}

  ${status === 'bad' ? `
    outline-color: rgba(${theme.colorsRgb.red.light}, 0.7);
    outline-style: solid;
  `:''};

  &:focus {
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid;

    ${!status?`
      outline-color: rgba(${theme.colorsRgb.blue.base}, 0.5);
    `:''}
  }


`}`;
