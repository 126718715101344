import {useState, useRef, useCallback, useMemo, useEffect} from 'react';

import {
  FiArrowRight
} from 'react-icons/fi';
import {
  IoIosArrowBack,
  IoIosAlert
} from 'react-icons/io';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {
  createTmsiReport,
  saveTmsiReport,
  TmsiReport,
  TmsiReportIndex
} from 'api';
import {
  Breadcrumbs,
  Progressline,
  Button,
  InputGroup,
  PlainForm,
  FormIndex
} from 'components';
import {
  ENV
} from 'config';
import {
  useGraphQLContext,
  useOverlayContext
} from 'context';
import {
  FlexContainer,
} from 'layout';
import {
  downloadTmsiReport
} from 'pages';
import {
  useValidator
} from 'utils';

export const NewTMSIExport: React.FC = () => {
  const totalSteps = 3;
  const [stateUpdated, setStateUpdated] = useState<Date>(new Date());
  const [errors, setErrors] = useState<string[]>();
  const [step, setStep] = useState<number>(1);
  const {
    loading, setLoading,
    showPopup, hidePopup
  } = useOverlayContext();

  const [tmsiReports, setTmsiReports] = useState<TmsiReport[]>();
  const [tmsiReportsIndex, setTmsiReportsIndex] = useState<TmsiReportIndex[]>();

  const savingReportsState = useState<boolean>();
  const setSavingReports = savingReportsState[1];

  const navigate = useNavigate();

  const clear = useCallback(function clearData(){
    setTmsiReports(undefined);
    setTmsiReportsIndex(undefined);
    successInitiated.current = false;
  }, [setTmsiReports, setTmsiReportsIndex]);

  const handleDownload = useCallback(function downloadReports(){
    console.log('[NewTMSIExport] tmsiReports are', tmsiReports);

    if(!tmsiReports || tmsiReports?.length !== tmsiReportsIndex?.length){ return; }

    for(let i=0; i<tmsiReports.length; i++){
      const report = tmsiReports[i];
      const index = tmsiReportsIndex[i];

      const name = (index.sensors +' '+ index.startDate +'-'+ index.endDate + '.csv').replace(/,/g, ' ');

      downloadTmsiReport(report, name);
    }

  }, [tmsiReports, tmsiReportsIndex]);

  const handleSave = useCallback(async function saveReports(){

    if(!tmsiReports || tmsiReports?.length !== tmsiReportsIndex?.length){ return; }

    try {
      setSavingReports(true);
      const promises = [];

      for(let i=0;i<tmsiReports.length;i++){
        const report = tmsiReports[i];
        const index = tmsiReportsIndex[i];

        promises.push(saveTmsiReport({
          report,
          index
        }));
      }

      const response = await Promise.all(promises);

      console.log('[NewTMSIExport:handleSave] saving reports success', response);
      setSavingReports(false);
    }
    catch (e){
      console.error('[NewTMSIExport:handleSave] error saving reports:', e);
      setErrors([String(e)]);
      setSavingReports(false);
    }

  }, [tmsiReports, tmsiReportsIndex, setSavingReports]);

  const successInitiated = useRef<boolean>();

  useEffect(function handleSuccess(){
    if(!successInitiated.current && tmsiReports && tmsiReportsIndex){
      successInitiated.current = true;
      (async () => {
        handleDownload();
        await handleSave();
        showPopup({
          title: 'Export Succeded',
          children: <>
            We have generated and saved your export. <br />You may redownload it again later from your exports.
          </>,
          footer: <>
            <Button onClick={() => {
              navigate('/tmsi-report');
              hidePopup();
              clear();
            }} variant="primary">Go to your Exports <FiArrowRight /></Button>
            <Button onClick={() => {
              hidePopup();
              clear();
            }} >Stay on current export</Button>
          </>
        });
      })();
    }

  }, [navigate, hidePopup, tmsiReports, tmsiReportsIndex, handleDownload, handleSave, clear, showPopup]);

  // useEffect(function displayDownloadPopup(){
  //   if(tmsiReports){
  //     showPopup({
  //       dismissable: true,
  //       onDissmiss: () => console.log('[NewTMSIExport] window dismissed'),
  //       title: 'Export Succeded',
  //       children: <>
  //         You may now download and save your exports

  //       </>,
  //       footer: <>
  //         <StyledFooterCheckbox
  //           name="saveExport"
  //           type="checkbox"
  //           label="Save export"
  //           reverse={true}
  //           defaultChecked={saveExport}
  //           onChange={(e: any) => { setSaveExport(e.target.checked) }}
  //           />
  //         {/* download and save */}
  //         <Button loading={savingReports} variant="primary" onClick={async () => {
  //           handleDownload();
  //           if(saveExport){
  //             await handleSave();
  //           }
  //           clear();
  //         }}><FiDownload />&nbsp;&nbsp;&nbsp;Download {saveExport ? '& Save' : ''}</Button>
  //         {/* save only */}
  //         <Button loading={savingReports} onClick={async () => {
  //           await handleSave();
  //           clear();
  //         }}><FiUploadCloud />&nbsp;&nbsp;&nbsp;Save only</Button>
  //       </>
  //     });
  //   }
  //   else {
  //     hidePopup();
  //   }
  // },[tmsiReports, saveExport, handleDownload, savingReports]);

  // refference the form for data
  const formElRef = useRef<any>();

  const handlePrevStep = useCallback(function(){
    setStep(step-1);
  }, [step]);
  const handleNextStep = useCallback(function(){
    setStep(step+1);
  },[step]);

  const handleExport = useCallback(async function(){
    if(!formElRef.current){ return false; }
    const formData = new FormData(formElRef.current);
    const sensorsGroupsArray = formData.getAll('sensorGroup[]');
    const aggregate = formData.get('aggregate');

    const dateStart = formData.get('dateStart');
    const dateEnd = formData.get('dateEnd');
    const timeStart = formData.get('timeStart');
    const timeEnd = formData.get('timeEnd');

    const startDate = new Date(dateStart+' '+timeStart);
    const endDate = new Date(dateEnd+' '+timeEnd);

    const providers = formData.getAll('providers[]');
    const providersAll = formData.get('providersAll');

    console.log('export providers', {providers, providersAll});

    try {
      setLoading(true);

      const selectedProviders = providersAll ? [] : providers as string[];
      const reportGroups = sensorsGroupsArray.map( (sensorsId) => String(sensorsId).split(',') );

      const promises: Promise<TmsiReport|null>[] = [];
      reportGroups.forEach( group => {
        group.forEach( sensorId => {
          promises.push(createTmsiReport({
            sensorId: Number(sensorId),
            epochStart: startDate.getTime()/1000,
            epochEnd: endDate.getTime()/1000,
            providers: selectedProviders
          }));
        });
      });

      const reports: (TmsiReport|null)[] = await Promise.all(promises);

      console.log('[NewTMSIExport] resolved reports are', reports);

      if(!reports) {
        throw new Error('Request failiure. Check your input and try again');
      }

      const aggregate = formData.get('aggregate');
      const createdAt = new Date();

      // Combine results if so specified
      if(aggregate){
        const combinedReport: TmsiReport = [];
        reports.forEach(report => {
          combinedReport.push(...(report||[]));
        });

        const reportsIndex = [{
          sensors: reportGroups.map(group => group.join(', ')).join(', '),
          startDate,
          endDate,
          createdAt
        }] as TmsiReportIndex[];

        setTmsiReports([combinedReport]);
        setTmsiReportsIndex(reportsIndex);
      }
      // Or combine by report groups
      else {

        const groupedReports: TmsiReport[] = [];
        const reportsIndex: TmsiReportIndex[] = [];
        let r = 0;

        reportGroups.forEach( (group, g) => {
          group.forEach( () => {
            const current = reports[r] || [];
            const currentInGroup = groupedReports[g] || [];

            currentInGroup.push(...current);
            groupedReports[g] = currentInGroup;

            r++;
          });
          reportsIndex.push({
            sensors: group.join(', '),
            startDate,
            endDate,
            createdAt
          });
        });

        setTmsiReports(groupedReports);
        setTmsiReportsIndex(reportsIndex);
      }

      setLoading(false);
    }
    catch (e) {
      console.error('[NewTMSIExport] error getting reports', e);
      setErrors([String(e)]);

      setLoading(false);
    }

    console.log('[NewTMSIExport] export payload data is', {sensorsGroupsArray, aggregate, startDate, endDate, providers});

  },[setLoading]);

  const canGoForward = useMemo(function(){
    if(!formElRef.current || !stateUpdated){ return false; }
    const formData = new FormData(formElRef.current);

    // check that at least one sensor is selected
    if(step === 1){
      const sensorsGroupsArray = formData.getAll('sensorGroup[]');
      return sensorsGroupsArray.length > 0;
    }
    else if(step === 2){
      const dateStart = formData.get('dateStart');
      const dateEnd = formData.get('dateEnd');
      const timeStart = formData.get('timeStart');
      const timeEnd = formData.get('timeEnd');

      if(String(dateStart).length !==8 || String(dateEnd).length !==8){
        // return quitely
        return false;
      }
      else if( isNaN(Number(new Date(String(dateStart)))) || isNaN(Number(new Date(String(dateEnd))))){
        return false;
      }

      if(String(dateStart).length !==8 || String(dateEnd).length !==8){
        // return quitely
        return false;
      }

      const startDate = new Date(dateStart+' '+timeStart);
      const endDate = new Date(dateEnd+' '+timeEnd);

      if(startDate && endDate && startDate.getTime() > endDate.getTime()){
        setErrors(['Start date/time can not be greater than end date/time']);
        return false;
      } else if( startDate.getTime() === endDate.getTime() ){
        setErrors(['Start time can not be the same as end time']);
      } else {
        setErrors([]);
      }

      return dateStart && dateEnd && timeStart && timeEnd;
    } else if(step === 3){
      const providers = formData.getAll('providers[]');
      const providersAll = formData.get('providersAll');

      console.log('filters changed:', {providers, providersAll});

      if(!providersAll&&(!providers||!providers.length)){
        setErrors(['Please select at least one provider']);
        return false;
      }

      setErrors([]);

      return true;
    }
    return true;
  },[stateUpdated, step]);

  const canGoBack = useMemo(function(){
    return true;
  },[]);

  return <>
    <Breadcrumbs />

    <Container>
      <h2>New TMSI Export</h2>
      <Progressline step={step} of={totalSteps} />

      {errors?.length ?
        <ErrorMessage>{errors.map(error => <p key={error}>{error}</p>)}<IoIosAlert /></ErrorMessage> : null}

      {/* STEPS */}
      <Form condenced={step!==1} refference={formElRef} action="#" method="GET">

        <SensorSelection hidden={step!==1} onChaged={() => setStateUpdated(new Date())} />
        <DateRangeSelection hidden={step!==2} onChaged={() => setStateUpdated(new Date())} />
        <Filters hidden={step!==3} onChaged={() => setStateUpdated(new Date())} />

      </Form>

      {/* BUTTONS */}
      {step > 1
        ?
        <Button disabled={!canGoBack} variant="plain" onClick={handlePrevStep}><IoIosArrowBack /> Back</Button>
        :
        null
      }
      {step < totalSteps
        ?
        <Button disabled={!canGoForward} variant="faint" onClick={handleNextStep}>Next</Button>
        :
        <Button disabled={!canGoForward||loading} loading={loading} variant="primary" onClick={handleExport}>Export</Button>
      }
    </Container>
  </>;
};

type StepFormProps = {
  onChaged?: () => void,
  onSubmit?: () => void,
  hidden?:boolean
};

const SensorSelection: React.FC<StepFormProps> = ({
  onChaged=()=>{/**/},
  hidden
}) => {

  const [allowMultiple] = useState<boolean>(true);
  const {sensorArray, home} = useGraphQLContext();

  // group sensor ids by hardware id
  const sensorsGroups = useMemo(function(){
    const index: {
      [key: string]: string[];
    } = {};

    sensorArray.forEach(sensor => {
      const id = sensor?.hardwareId || '';
      const sensorsGroup = index[id] || [];
      sensorsGroup.push(sensor.uid);
      index[id]  = sensorsGroup;
    });

    return Object.entries(index).map(([hardwareId, sensors]) => ({
      hardwareId,
      sensors
    }));
  },[sensorArray]);

  // pull sensors
  // check that all sensors are in in the same timezone and allow or forbid selecting multiple sensors
  // ** as of now sensor does not have an origin, only home does

  return <span style={hidden?{display:'none'}:{}}>
      <h3>Select sensor</h3>

      {(sensorsGroups).map((sensorsGroup) => (
        <InputGroup
          onChange={() => onChaged()}
          reverse
          key={sensorsGroup.hardwareId}
          name="sensorGroup[]"
          value={sensorsGroup.sensors.join(',')}
          type={allowMultiple?'checkbox':'radio'}
          label={(sensorsGroup.hardwareId ? (sensorsGroup.hardwareId+' - '):'')+sensorsGroup.sensors.join(', ')+' '+(home?' ('+home.timezone+')':'')}
           />
      ))}

      {ENV.environment === 'dev' ?
        <InputGroup
          onChange={() => onChaged()}
          reverse
          key="12290"
          name="sensorGroup[]"
          value="12290"
          type={allowMultiple?'checkbox':'radio'}
          label={'(dev) 12290 - Demohouse'+(home?' ('+home.timezone+')':'')}
           />
      :null}

      <hr />

      <InputGroup
        onChange={() => onChaged()}
        reverse
        name="aggregate"
        type="checkbox"
        value="true"
        label='Aggregate data'
        sublabel='Data from multiple sensors will be comined into a single report.' />

  </span>;
};

const timeSelection: {
  [key: string]: string
} = {};
for(let i=1;i<12;i++){ timeSelection[i+':00'] = (i)+' am'; }
timeSelection['12:00'] = '12 pm';
for(let i=1;i<12;i++){ timeSelection[(i+12)+':00'] = (i)+' pm'; }
timeSelection['24:00'] = '12 am';

const DateRangeSelection: React.FC<StepFormProps> = ({
  onChaged=()=>{/**/},
  hidden
}) => {

  const dateEndRef = useRef<HTMLInputElement>();
  const dateStartRef = useRef<HTMLInputElement>();

  const formObj = useRef<FormIndex>({});
  const [validations, validationApi] = useValidator(formObj, {
    // make sure it full and object is not
    dateStart: (v) => v.replace(/\D/g,'').length === 6 && !isNaN(Number(new Date(v))),
    dateEnd: (v) => v.replace(/\D/g,'').length === 6 && !isNaN(Number(new Date(v)))
  } );

  useEffect(function(){

    console.log('formObj', formObj);

  },[formObj]);

  const handleOnDateStartChanged = useCallback(() => {
    if(!dateStartRef.current || !dateEndRef.current){
      return;
    } else if (dateStartRef.current.value && !dateEndRef.current.value){
      dateEndRef.current.value = dateStartRef.current.value;
    }
    validationApi.validate();

  }, [validationApi]);

  return <TimerangePage style={hidden?{display:'none'}:{}}>

    <h3>Select date & time range</h3>

    <FlexContainer className="field-group">
      <InputGroup
        form={formObj}
        refference={dateStartRef}
        onFormattedValue={() => {validationApi.validate(); handleOnDateStartChanged(); onChaged();}}
        onChange={() => {validationApi.validate(); onChaged();}}
        type="date"
        name="dateStart"
        label="Date Start"
        placeholder="MM / DD / YY"
        status={validations.dateStart}
        />

      <InputGroup
        form={formObj}
        refference={dateEndRef}
        onFormattedValue={() => {validationApi.validate(); onChaged();}}
        onChange={() => {validationApi.validate(); onChaged();}}
        type="date"
        name="dateEnd"
        label="Date End"
        placeholder="MM / DD / YY"
        status={validations.dateEnd}
        />
    </FlexContainer>

    <FlexContainer className="field-group">
      <InputGroup
        form={formObj}
        onChange={() => onChaged()}
        type="select"
        name="timeStart"
        label="Time Start"
        placeholder=""
        defaultValue="9:00"
        values={timeSelection}
        />
      <InputGroup
        form={formObj}
        onChange={() => onChaged()}
        type="select"
        name="timeEnd"
        label="Time End"
        placeholder=""
        defaultValue="21:00"
        values={timeSelection}
        />
    </FlexContainer>

  </TimerangePage>;
};

const Filters: React.FC<StepFormProps> = ({
  onChaged=()=>{/**/},
  hidden
}) => {
  const [allSelected, setAllSelected] = useState<boolean>(true);

  return <FiltersPage style={hidden?{display:'none'}:{}}>
    <h3>Filter by provider</h3>
    <h6 className="subheading">By default, everything is selected. Deselect cellular providors you wish to omit.</h6>

    <InputGroup
      reverse
      type="checkbox"
      label="All cell providers"
      value="all"
      name="providersAll"
      defaultChecked
      onChange={(e) => {
        setAllSelected(e.target.checked);
        onChaged();
      }}
      />

    <hr />
    <StyledActiveOpacity
      active={!allSelected}>

      <InputGroup
        reverse
        type="checkbox"
        label="AT&T"
        value="AT&T"
        name="providers[]"
        defaultChecked
        onChange={() => onChaged()}
        />

      <InputGroup
        reverse
        type="checkbox"
        label="Verizon"
        value="Verizon"
        name="providers[]"
        defaultChecked
        onChange={() => onChaged()}
        />

      <InputGroup
        reverse
        type="checkbox"
        label="T-Mobile"
        value="T-Mobile"
        name="providers[]"
        defaultChecked
        onChange={() => onChaged()}
        />

    </StyledActiveOpacity>

  </FiltersPage>;
};

const StyledActiveOpacity = styled.div.attrs({} as {
  active: boolean
})`${({active}) => !active ? `
  opacity: 0.4;
  pointer-events: none;
` : ''}`;

const Container = styled.div`${({theme}) => `
  padding: ${theme.boxPadding.xl5}px ${theme.boxPadding.xl8}px;

  text-align: right;
  > * {
    text-align: left;
  }
`}`;

const Form = styled(PlainForm)`${({theme}) => `
  /* outside */
  border: 1px solid ${theme.colors.sky.base};
  border-radius: ${theme.boxPadding.xl}px;
  margin: ${theme.boxMargins.xl}px 0;
  padding: ${theme.boxPadding.xl3}px ${theme.boxPadding.xl5}px ${theme.boxPadding.xl5}px;

  p {
    font-size: ${theme.fontSize.m}px;
    color: ${theme.colors.ink.dark};
  }

  h3 + *:not(.subheading),
  h3 + .subheading + * {
    margin-top: ${theme.boxMargins.xl}px;
  }

  h5 {
    font-weight: 500;
  }

  /* forms elements and props */
  display: block;
  overflow: visible;

  .field-group {
    overflow: visible;
  }

  hr {
    margin: ${theme.boxMargins.base}px 0;
    border: none;
    height: 1px;
    background-color: ${theme.colors.sky.lighter};
  }

  input, label {
    vertical-align: middle;
    line-height: ${theme.fontSize.fontMargins}px;
  }
`}`;

const TimerangePage = styled.div`${({theme})=> `
  .input-group {
    padding: 0;
    margin-top: ${theme.boxMargins.m}px;
  }

  .input-group:not(:last-child) {
    margin-right: ${theme.boxMargins.xl7*2}px;
  }

  .field-group {
    margin-bottom: ${theme.boxMargins.xl2}px;
  }
`}`;

const FiltersPage = styled.span`${({theme}) => `
  h5 {
    margin-bottom: ${theme.boxMargins.l}px;
  }

  .input-group.type-checkbox.variant-pill {
    margin-right: ${theme.boxMargins.l}px;
  }
`}`;

const ErrorMessage = styled.div`${({theme}) => `
  border-radius: ${theme.boxPadding.xl}px;
  background-color: rgba(${theme.colorsRgb.red.base.toString()}, 0.5);
  padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl5}px;
  border: none;
  margin-top: ${theme.boxMargins.l}px;

  position: relative;

  font-weight: bold;
  color: ${theme.colors.red.dark};

  > :not(svg) {
    margin: ${theme.boxMargins.s}px 0;
  }
  > svg:last-child {
    width: ${theme.boxPadding.l*2}px;
    height: ${theme.boxPadding.l*2}px;
    color: rgba(${theme.colorsRgb.sky.white.toString()},0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: ${theme.boxPadding.xl5}px;
  }
`}`;
