import styled from 'styled-components';

export type CheckboxGroupProps = {
  variant?: 'pill' | 'normal';
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void
}
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  children,
  variant='normal',
  ...args}) =>

  <StyledGroup variant={variant} {...args}>{children}</StyledGroup>;

const StyledGroup = styled.div<{
  variant?: CheckboxGroupProps['variant']
}>`${({theme, variant}) => `

  input, label {
    vertical-align: middle;
    line-height: ${theme.fontSize.fontMargins}px;
  }

  ${variant === 'pill'?`
    display: inline-block;


    > input {
      display: none !important;
    }

    label {
      display: inline-block;

      padding: ${theme.fontPadding.base/2}px ${theme.fontPadding.base}px;
      border-radius: ${theme.fontPadding.base*2}px;

      color: ${theme.colors.primary.base};

      cursor: pointer;
    }

    label {
      background-color: ${theme.colors.gray.lightest};
    }
    input:checked + label {
      background-color: ${theme.colors.primary.lightest};
    }
  `:''}

  cursor: pointer;
  > * {
    cursor: pointer;
  }
`}`;
