import { apiRequest , getGraphQLData } from 'api';

export type RawFeedback = {
  home_id: string;
  user_id: string;
  alert_id?: string;
  additional_comments?: string;
} & (
  | {
      feedback_type: 'INCREASED_ACTIVITY_CORRECT';
      alert_correct?: boolean;
      estimated_number_devices?: number;
      estimated_number_people?: number;
    }
  | {
      feedback_type: 'NEW_METADATA_CORRECT';
      alert_correct?: boolean;
      estimated_number_devices?: number;
      estimated_number_people?: number;
    }
  | {
      feedback_type: 'INCREASED_ACTIVITY_MISSING';
      missed_alert_time?: number;
      estimated_number_devices?: number;
      estimated_number_people?: number;
    }
);

export const addFeedback = (feedback: RawFeedback) =>
  apiRequest({
    service: 'dataAPI',
    path: '/api/feedback',
    method: 'POST',
    data: feedback,
  });

export type FeedbackCountResponseData = {
  data: { allFeedback: { totalCount: number | null } };
};
export const getTotalFeedbackCount = (userId: string) => {
  const query = `
      {
        allFeedback(filters: {userId: "${userId}"}) {
          totalCount
        }
      }
    `;
  return getGraphQLData<FeedbackCountResponseData>(query);
};

export const getFeedbackCountForAlert = (alertId: string) => {
  const query = `
      {
        allFeedback(filters: {alertId: "${alertId}"}) {
          totalCount
        }
      }
    `;
  return getGraphQLData<FeedbackCountResponseData>(query);
};
