import { getAuth } from 'firebase/auth';
import { getDatabase, ref, child, push, set, get } from 'firebase/database';

import { apiRequest } from 'api';
import { firebaseApp } from 'index';
import { processRawTmsiReport, RawTmsiReport, processRawActivityReport, RawActivityReport } from 'utils';
export type TmsiReportItem = {
  type: string,
  networkProvider: string,
  powerEstimate: number,
  tmsi: number,
  epochTimestamp: number,
  timestamp: number,
  date: Date
}
export type TmsiReport = TmsiReportItem[]
export type TmsiReportIndex = {
  sensors: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
}
//
export type CreateTmsiReportPayload = {
  sensorId: number,
  epochStart: number,
  epochEnd?: number,
  providers?: string[]
};
export type TmsiRespose = {
  'Status': 'Success'|'Failure',
  'Status Code'?: number,
  'Reason'?: string,
  'Items Returned': RawTmsiReport
}

export const getRawTmsiReport = async (payload: CreateTmsiReportPayload) => {
  const res = await apiRequest<TmsiRespose>({
    service: 'dataAPI',
    path: '/api/tmsi_report',
    method: 'POST',
    data: {
      sensor_id:payload.sensorId,
      start_epoch:payload.epochStart,
      end_epoch:payload.epochEnd,
      providers: payload.providers
    }  
  });

  if(res.data?.['Items Returned']){
    const items = res.data['Items Returned'];
    return items;
  } else if(res.data?.['Status Code'] !== 200){
    console.error('[getTmsiReport] status is not 200:', res.data?.['Status Code'], res.data?.Reason);
    throw new Error( String(res.data?.['Status Code'])+' '+String(res.data?.Reason) );
  } else {
    return null;
  }
  
  console.log('[getRawTmsiReport] response is', res);
};
export const createTmsiReport = async (payload: CreateTmsiReportPayload) => {
  const report = await getRawTmsiReport(payload);

  return report ? processRawTmsiReport(report) : report;
};
export type SaveTmsiReportPayload = {
  report: TmsiReport,
  index: TmsiReportIndex,
}
export type SavedTmsiReport = {
  userUid: string;
  report: string; //json string
  sensors: string;
  records: number;

  // stringify dates
  startDate: number;
  endDate: number;
  createdAt: number;
  //
  uid?: string;
}
export type SavedTmsiReportDoc = {
  body: string; 
}
export const saveTmsiReport = (payload: SaveTmsiReportPayload) => {
  const db = getDatabase(firebaseApp);
  const user = (getAuth()).currentUser;
  const reportsBodiesRef = child(ref(db), 'tmsiReportsBodies');
  const reportsRef = child(ref(db), 'tmsiReports');
  
  const { report, index } = payload;

  if(!user){ return; }
  const newReportBodyRef = push(reportsBodiesRef);
  const bodyKey = newReportBodyRef.key;

  const saveTmsiPayload: SavedTmsiReport = {
    userUid: user.uid,
    report: bodyKey||'',
    records: report.length,
    sensors: index.sensors,

    startDate: Number(index.startDate),
    endDate: Number(index.endDate),
    createdAt: Number(index.createdAt)
  };

  console.log('[saveTmsiReport]', {db, user, payload, saveTmsiPayload});

  return Promise.all([
    set(newReportBodyRef, {
    body: JSON.stringify(report)
  }), push(reportsRef, saveTmsiPayload)]);
};

export const getTmsiReportDoc = async (docId: string): Promise<SavedTmsiReportDoc|null> => {
  const db = getDatabase(firebaseApp);
  const user = (getAuth()).currentUser;
  const reportsBodiesRef = child(ref(db), 'tmsiReportsBodies');
  
  if(!user){ return null; }

  const snapshot = await get(child(reportsBodiesRef, docId));

  if(!snapshot.exists()){ return null; }

  return snapshot.val();
};

export type ActivityReportItem = {
  type: string,
  networkProvider: string,
  amount: number;

  epochTimestamp: number,
  timestamp: number,
  date: Date
}
export type CreateActivityReportPayload = {
  sensorId: number,
  epochStart: number,
  epochEnd?: number
};
export type ActivityRespose = {
  'Status': 'Success'|'Failure',
  'Status Code'?: number,
  'Reason'?: string,
  'Items Returned': RawActivityReport
}
export const getRawActivityReport = async (payload: CreateActivityReportPayload) => {
  const res = await apiRequest<ActivityRespose>({
    service: 'dataAPI',
    path: '/api/activity_report',
    method: 'POST',
    data: {
      sensor_id:payload.sensorId,
      start_epoch:payload.epochStart,
      end_epoch:payload.epochEnd
    }  
  });

  if(res.data?.['Items Returned']){
    const items = res.data['Items Returned'];
    return items;
  } else if(res.data?.['Status Code'] !== 200){
    console.error('[getTmsiReport] status is not 200:', res.data?.['Status Code'], res.data?.Reason);
    throw new Error(String(res.data?.['Status Code'])+' '+String(res.data?.Reason));
  } else {
    return null;
  }
  
  console.log('[getRawActivityReport] response is', res);
};
export const createActivityReport = async (payload: CreateActivityReportPayload) => {
  const report = await getRawActivityReport(payload);

  return report ? processRawActivityReport(report) : report;
};