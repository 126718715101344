import { HTMLProps } from 'react';

import styled from 'styled-components';

import {Loading} from 'components';

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'faint' | 'plain',
  loading?: boolean;

} & HTMLProps<HTMLButtonElement>;

// Button
export const Button: React.FC<ButtonProps> = ({
  variant='secondary',
  loading=false,
  children,
  ...args
}) => {

  return variant === 'primary'
    ?
    <PrimeButton
      {...args}>
      <StyledContent $loading={loading}>{children}</StyledContent>
      {loading ? <StyledLoading /> : null}
    </PrimeButton>
    :
    variant === 'faint'
    ?
    <FaintButton
      {...args}>
      <StyledContent $loading={loading}>{children}</StyledContent>
      {loading ? <StyledLoading /> : null}
    </FaintButton>
    :
    variant === 'plain'
    ?
    <PlainButton
      {...args}>
      <StyledContent $loading={loading}>{children}</StyledContent>
      {loading ? <StyledLoading /> : null}
    </PlainButton>
    :
    <SecondaryButton
      {...args}>
      <StyledContent $loading={loading}>{children}</StyledContent>
      {loading ? <StyledLoading /> : null}
    </SecondaryButton>;

  
};

const StyledLoading = styled(Loading).attrs(({theme}) => ({
  size: theme.fontPadding.xl6/1.5
}))`${() => `
  margin: auto !important;

  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
`}`;

const StyledContent = styled('span').attrs({} as {
  $loading: boolean
})`${({$loading:loading}) => loading ? `
  opacity: 0;
`: `
  opacity: 1;
`}`;


const PrimeButton = styled.button`
  position: relative;
  background-color: ${({theme}) => theme.colors.primary.base};

  color: ${({theme}) => theme.colors.sky.white};
  font-family: ${({theme}) => theme.fontFamily.inter};
  font-size ${({theme}) =>  theme.fontSize.base }px;

  padding: ${({theme}) => theme.fontPadding.base}px ${({theme}) => theme.fontPadding.xl4}px;
  border-radius: ${({theme}) => theme.fontPadding.xl6}px;
  
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.80
  }
  &[disabled] {
    pointer-events: none;
    background-color: ${({theme}) => theme.colors.gray.lighter};
  }

  > svg {
    vertical-align: middle;
    margin-top: -3px;
  }
` as React.FC<ButtonProps>;

const SecondaryButton = styled.button`
  position: relative;
  background-color: ${({theme}) => theme.colors.sky.white};
  
  outline-offset: -1px;
  color: ${({theme}) => theme.colors.primary.base};
  font-family: ${({theme}) => theme.fontFamily.inter};
  font-size ${({theme}) =>  theme.fontSize.base }px;

  padding: ${({theme}) => theme.fontPadding.base}px ${({theme}) => theme.fontPadding.xl4}px;
  border-radius: ${({theme}) => theme.fontPadding.xl6}px;
  
  border: 1px solid ${({theme}) => theme.colors.primary.base};
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    opacity: 0.80
  }
  &[disabled] {
    pointer-events: none;
    outline-color: ${({theme}) => theme.colors.gray.lighter};
  }

  > svg {
    vertical-align: middle;
    margin-top: -3px;
  }
` as React.FC<ButtonProps>;

const FaintButton = styled.button`${({theme}) => `
  position: relative;
  background-color: ${theme.colors.primary.lightest};
  
  color: ${theme.colors.primary.base};
  font-family: ${theme.fontFamily.inter};
  font-size ${theme.fontSize.base}px;

  padding: ${theme.fontPadding.base}px ${theme.fontPadding.xl4}px;
  border-radius: ${theme.fontPadding.xl6}px;
  
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.80
  }
  &[disabled] {
    pointer-events: none;
    background-color: ${theme.colors.sky.light};
    color: ${theme.colors.sky.dark};
  }
  
  > svg {
    vertical-align: middle;
    margin-top: -3px;
  }

`}` as React.FC<ButtonProps>;

const PlainButton = styled.button`${({theme}) => `
  position: relative;
  background: none;
  
  color: ${theme.colors.primary.base};
  font-family: ${theme.fontFamily.inter};
  font-size ${theme.fontSize.base}px;

  padding: ${theme.fontPadding.base}px ${theme.fontPadding.xl4}px;
  border-radius: ${theme.fontPadding.xl6}px;
  
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.80
  }
  &[disabled] {
    pointer-events: none;
    color: ${theme.colors.sky.dark};
  }

  > svg {
    vertical-align: middle;
    margin-top: -3px;
  }
`}` as React.FC<ButtonProps>;