// import analytics from '@react-native-firebase/analytics';
// import auth from '@react-native-firebase/auth';
import * as Sentry from '@sentry/react';
import { 
  getAnalytics, 
  logEvent 
} from 'firebase/analytics';
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { v4 as UUID } from 'uuid';

//import { ENV } from 'config';
import { firebaseApp } from 'index';
// import DeviceInfo from 'react-native-device-info';
import { Maybe, RawDeviceType } from 'types';
// import { getDeviceData } from 'utils/deviceData';
// import { updateUserDeviceInfo } from './account/user';

export const uploadImage = async (uri: any) => {
  const storage = getStorage();

  try {
    const imgId = UUID();
    const imageRef = ref(storage, `images/${imgId}.jpg`);
    const snapshot = await uploadBytes(imageRef, uri);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (e) {
    console.error(e);
    throw e;
  }

};

export const deleteImage = (url: string) => {
  // storage().refFromURL(url).delete();
  const storage = getStorage();
  const imageRef = ref(storage, url);

  deleteObject(imageRef);
};

//
// Analytics
//
// Utility functions for all custom analytic events
//
// These are places here to ensure every time an event is called
//  it is called the same way and we don't have to hunt around
//  the app to ensure all events of the same type are updated
//

export const logFBCustomEvent = async (
  name: string,
  params?: {
    [key: string]: any;
  },
) => {
  //const { apiDebugging } = ENV;
  //const deviceDataRes = await getDeviceData();
  const paramsAndVersion = {
    //currentVersion: deviceDataRes?.readableVersion,
    ...params,
  };
  try {
    const analytics = getAnalytics(firebaseApp);

    await logEvent(analytics, name, paramsAndVersion);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        service: 'firebase analytics',
      },
      contexts: {
        message: { value: 'Firebase Analytics Error' },
        params: params ?? {},
        name: { value: name },
      },
    });
    throw e;
  }
};

// Many of these events will likely be migrated from inside the app to be backend.
// This will happen the same time we move away from Firebase and towards our own event logging system

//
// App State
//
export const logAppStateChange = (appState: string) =>
  logFBCustomEvent('app_state_change', { appState });

//
// Profiles
//
type LogCreateProfileEventData = {
  profileUid: string;
};
export const logCreateProfileEvent = (data: LogCreateProfileEventData) =>
  logFBCustomEvent('create_profile', data);

type LogProfileNameChangeEventData = {
  profileUid: string;
  toName: string;
  fromName: string;
};
export const logProfileNameChangeEvent = (
  data: LogProfileNameChangeEventData,
) => logFBCustomEvent('profile_name_change', data);

//
// Device
//
type LogDeviceTypeChangeEventData = {
  deviceUid: string;
  fromType: RawDeviceType;
  toType: RawDeviceType;
};
export const logDeviceTypeChangeEvent = (data: LogDeviceTypeChangeEventData) =>
  logFBCustomEvent('device_type_change', data);

type LogDeviceNameChangeEventData = {
  deviceUid: string;
  fromName: string;
  toName: string;
};
export const logDeviceNameChangeEvent = (data: LogDeviceNameChangeEventData) =>
  logFBCustomEvent('device_name_change', data);

type LogAssignedDeviceEventData = {
  deviceUid: string;
  fromProfileUid?: Maybe<string>;
  toProfileUid?: Maybe<string>;
};
export const logDeviceAssignedEvent = (data: LogAssignedDeviceEventData) =>
  logFBCustomEvent('assign_device', data);

//
// Sensor Setup
//

export const logSensorSetupStarted = (setupRunUid: string) =>
  logFBCustomEvent('sensor_setup_started', { setupRunUid });

type logSetupBluetoothStatusData = {
  bluetoothStatus: number;
  setupRunUid: string;
  sensorUid?: string;
};

export const logSensorSetupBluetoothStatus = (
  data: logSetupBluetoothStatusData,
) => {
  logFBCustomEvent('setup_bluetooth_status', data);
};

type LogSensorSetupStepChangeData = {
  step: number;
  stepName: string;
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
};
export const logSensorSetupStepChange = (data: LogSensorSetupStepChangeData) =>
  logFBCustomEvent('setup_step', data);

type logSetupCompleteData = {
  duration: number;
  setupRunUid: string;
  sensorUid: string;
};
export const logSensorSetupComplete = (data: logSetupCompleteData) =>
  logFBCustomEvent('setup_complete', data);

type logSetupErrorData = {
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
  errorCode: number;
  userMessage: string;
  systemMessage: string;
};
export const logSensorSetupError = (data: logSetupErrorData) =>
  logFBCustomEvent('setup_error', data);

//
// Wifi Credential Change
//

export const logWifiCredChangeStarted = (credChangeUid: string) =>
  logFBCustomEvent('wifi_cred_change_started', { credChangeUid });

type logWifiCredChangeBluetoothStatusData = {
  bluetoothStatus: number;
  wifiCredChangeRunUid: string;
  sensorUid?: string;
};

export const logWifiCredChangeBluetoothStatus = (
  data: logWifiCredChangeBluetoothStatusData,
) => {
  logFBCustomEvent('wifi_change_bluetooth_status', data);
};

type LogWifiCredChangeStepChangeData = {
  step: number;
  stepName: string;
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
};
export const logWifiCredStepChange = (data: LogWifiCredChangeStepChangeData) =>
  logFBCustomEvent('wifi_change_step', data);

type logWifiCredChangeCompleteData = {
  duration: number;
  setupRunUid: string;
  sensorUid: string;
};
export const logWifiCredChangeComplete = (
  data: logWifiCredChangeCompleteData,
) => logFBCustomEvent('wifi_change_complete', data);

type logWifiCredChangeErrorData = {
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
  errorCode: number;
  userMessage: string;
  systemMessage: string;
};
export const logWifiCredChangeError = (data: logWifiCredChangeErrorData) =>
  logFBCustomEvent('wifi_change_error', data);

//
// Onboarding
//

export const logOnboardingStarted = () =>
  logFBCustomEvent('onboarding_started');

export const logOnboardingGettingStartedComplete = () =>
  logFBCustomEvent('onboarding_getting_started_complete');

type logOnboardingProfilesCompleteData = {
  numberOfProfiles?: number;
};

export const logOnboardingProfilesComplete = (
  data: logOnboardingProfilesCompleteData,
) => logFBCustomEvent('onboarding_profiles_complete', data);

export const logOnboardingComplete = () =>
  logFBCustomEvent('onboarding_complete');

//
// QR Setup
//

type QrScreenOpened = {
  profileUid: string;
  qrScreenUid: string;
  timestamp: number;
};
export const logQrScreenOpened = (data: QrScreenOpened) =>
  logFBCustomEvent('qr_screen_opened', data);

type QrCodeSuccess = {
  profileUid: string;
  qrScreenUid: string;
  timestamp: number;
};
export const logQrCodeSuccess = (data: QrCodeSuccess) =>
  logFBCustomEvent('qr_code_success', data);

type QrCodeError = {
  profileUid: string;
  qrScreenUid: string;
  timestamp: number;
};
export const logQrCodeError = (data: QrCodeError) =>
  logFBCustomEvent('qr_code_error', data);

