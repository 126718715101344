import React, {useEffect} from 'react';

import styled from 'styled-components';

import { Footer } from 'layout/Footer';
import { Navbar } from 'layout/Navbar';


export const ContentWrapper: React.FC = ({
  children
}) => {
  
  // scroll to hash on load
  useEffect(function(){
    const hashId = String(window.location.hash).replace('#','');
    const el = window.document.getElementById(hashId);
    if(el){
      el.scrollIntoView();
    }
  },[]);

  return (
    <MainPanel>
      <Navbar />
      <FrontContentWrapper>

        {children}

      </FrontContentWrapper>
      <Footer />
    </MainPanel>
  );
};

export default ContentWrapper;

const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({theme}) => theme.colors.sky.white};
`;

const FrontContentWrapper = styled.div`${({theme}) =>`
  flex-grow: 1;

  padding: ${theme.boxPadding.xl5}px ${theme.boxPadding.xl2}px;
`}`;
