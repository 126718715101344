import {useState, useEffect, useMemo, useRef, useCallback} from 'react';

import {
  format
} from 'date-fns-tz';
import styled from 'styled-components';

import {
  useFibonachiPowerPlot
} from 'components';
import {
  useGraphQLContext
} from 'context';
import {
  SensorSelect
} from 'elements';
import {
  //useMQTTCellRaw,
  useMQTTCellScan,
  Identifier
} from 'hooks';
import {
  FlexContainer,
  Flex6,
} from 'layout';
import {
  processRawCellScanMqttToCellItem,
  CellItem
} from 'utils';

const PROVIDERS_COLORS: {
  [key: string]: string
} = {
  'Verizon': '#F00202',
  'AT&T': '#0FB5DA',
  'T-Mobile': '#E100AF',
  'Other': '#EF9A26'
};

export const CellMonitor = () => {
  const svgRef = useRef<SVGSVGElement>(null);

  const [latestTMSIIdentifier, setLatestTMSIIdentifier] = useState<Identifier>();

  const [sensorId, setSensorId] = useState<string>();

  const {home} = useGraphQLContext();

  // instantiate fibonachi power plot
  const {
    addWave,
    play,
    stop,
    resetScene,
    //
    clearScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    kickInWawesFrame,
    getSceneVertices
  } = useFibonachiPowerPlot(
    svgRef,
    useMemo(() => ({
      D: 0.25,
      K: 0.23,
      M: 1,
      accentDimStep: 0.01,
      bgColor: '#182e4d',
      cameraX: 0,
      cameraY: 0,
      cameraZ: 200,
      circlesNumber: 15,
      distributionFormula: 'fibonachiSphereFlattened',
      dotSize: 2,
      dotsAccentColorHex: '#bef72d',
      dotsColorHex: '#4eb504',
      framePerSecond: 15,
      height: 500,
      kickDiviation: 0.05,
      linesColorHex: '#008000',
      logcalctime: false,
      logdrawtime: false,
      numberOfDots: 1000,
      scaleAdjustment: 150,
      speed: 0.8,
      viewBoxOffsetX: 0,
      viewBoxOffsetY: 75,
      waveDimStep: 0.01,
      width: 500
    }), [])
  );

  // log
  useEffect(function logFibanachi(){
    console.log('[CellMonitor] fibonachi api:', {addWave, play, stop, resetScene, clearScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    kickInWawesFrame,
    getSceneVertices});

  }, [addWave, play, stop, resetScene,
    clearScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    kickInWawesFrame,
    getSceneVertices]);

  // helpers
  const getProviderColor = useCallback((provider) =>

    PROVIDERS_COLORS[provider]||PROVIDERS_COLORS.Other

  ,[]);

  // set interval for plot
  useEffect(function(){
    play();
    return stop;
  },[play, stop]);

  // kick in wave on TMSI
  useEffect(function kickInTmsiToPlot(){
    if(!latestTMSIIdentifier){ return; }

  }, [latestTMSIIdentifier, addWave, getProviderColor]);

  // Subscribe to identifiers stream
  // MQTT subscribtion
  const onNewCellScanDataRef = useRef<(item: CellItem) => void>();
  const onCloseFn = useRef<() => void>();
  useMQTTCellScan(sensorId||'', {
    onNext: function(newData){
      const cellItem = processRawCellScanMqttToCellItem(newData);

      console.log('[useActivityReport] got new data', {newData, cellItem});
      if(cellItem){
        onNewCellScanDataRef.current?.(cellItem);
      }
    },
    onClose: function(){
      onCloseFn.current?.();
      console.log('[useActivityReport] closed connection');
    }
  });

  useEffect(function onNewCellScanData(){
    onNewCellScanDataRef.current = function(cellItem){
      const color = getProviderColor(cellItem.networkProvider);
      addWave( Math.round((Number(cellItem.powerEstimate)*-1)/10), color);
      //kickInSomeMotion(Math.round((Number(cellItem.powerEstimate)*-1)/10), color);
    };
  },[addWave, getProviderColor]);


  const handleSensorSelect = useCallback(function handleSensorSelect(sensorId){
    setSensorId(sensorId);
    setLatestTMSIIdentifier(undefined);
    resetScene();
  },[resetScene]);

  return <CellMonitorContainer id="CellMonitor">
    <StyledSensorSelect
      variant="inverse"
      onSelect={handleSensorSelect}
      preserveByName="tmsi-monitor"
      />

    <Flex6>
      <PlotSvg ref={svgRef} width="540" height="auto" viewBox="0 0 500 500" />

      <ProvidersList>
        {Object.entries(PROVIDERS_COLORS).map(([name, color]) =>
          <li key={name}><i style={{backgroundColor: color}} /> {name}</li>
          )}
      </ProvidersList>
    </Flex6>
    <Flex6>
      {latestTMSIIdentifier ?
        <LabelsList
          onClick={() => {
            const color = getProviderColor(latestTMSIIdentifier.metadata);
            addWave( Math.round((latestTMSIIdentifier.averageSignalStrength*-1)/10), color);
          }}
          >
          <li>Time: <b>{ format((new Date(latestTMSIIdentifier.timestamp)), 'hh:mm:ss a zzz', {timeZone: String(home?.timezone)||undefined})}</b></li>
          <li>Network provider: <b>{latestTMSIIdentifier.metadata}</b></li>
          <li className="sea"><u className="tertiaryLighter">TMSI:</u> <b className="insightBlueBase">{latestTMSIIdentifier.value}</b></li>
          <li><u>RF Power:</u> <b className="waveGreenBase">{latestTMSIIdentifier.averageSignalStrength}</b></li>
          <li>Type: <b>cell scan</b></li>
        </LabelsList>

        : null}
    </Flex6>

  </CellMonitorContainer>;
};

const CellMonitorContainer = styled(FlexContainer)`${({theme}) => `

  background-color: #161C23;

  padding: ${theme.boxPadding.xl10}px ${theme.boxPadding.xl7}px;

  select, input {
    color: ${theme.colors.sky.dark};
  }

  border-radius: ${theme.boxPadding.base}px;

  position: relative;

  .tertiaryLighter{
    color: ${theme.colors.tertiary.lighter};
  }
  .insightBlueBase{
    color: ${theme.colors.insightBlue.base};
  }
  .waveGreenBase{
    color: ${theme.colors.waveGreen.base};
  }

  > * {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`}`;

const PlotSvg = styled.svg`
  max-width: 100%;
`;

const StyledSensorSelect = styled(SensorSelect)`${({theme}) => `
  position: absolute;
  top: ${theme.boxMargins.xl}px;
  right: ${theme.boxMargins.xl}px;
`}`;

const ProvidersList = styled.ul`${({theme}) => `
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 540px;
  justify-content: space-around;

  li {
    list-style-type: none;

    color: ${theme.colors.sky.lightest};
    font-size: ${theme.fontSize.base}px;

    display: inline-block;

  }

  li > i {
    display: inline-block;
    height: ${theme.fontSize.base}px;
    width: ${theme.fontSize.base}px;
    border-radius: ${theme.fontSize.base/2}px;
  }
`}`;

const LabelsList = styled.ul`${({theme}) => `
  padding: 0;
  padding-left: ${theme.boxPadding.xl5}px;

  margin: ${theme.boxPadding.xl5}px 0;

  li {
    list-style-type: none;

    color: ${theme.colors.sky.lightest};
    font-size: ${theme.fontSize.l}px;
  }

  li:not(:last-child){
    margin-bottom: ${theme.boxMargins.l}px;
  }

  .underlined {
    text-decoration: underline;
  }

`}`;
