import { apiRequest, ApiRequestData } from 'api';

export type ServerTimestampData = {
  clock_milliseconds: number;
  clock_seconds: number;
};
export const getServerTimestamp = (axiosConfig?: ApiRequestData) =>
  apiRequest<ServerTimestampData>({
    service: 'dataAPI',
    path: '/api/clock',
    method: 'GET',
    ...axiosConfig,
  });

export type AppVersionData = {
  uid?: string;
  current_version?: string;
  minimum_version?: string;
};
export const getAppVersion = (axiosConfig?: ApiRequestData) =>
  apiRequest<AppVersionData>({
    service: 'dataAPI',
    path: '/api/version/1',
    method: 'GET',
    withCredentials: false,
    ...axiosConfig,
  });
