export const testProps = (id: string) => ({
  testID: id,
  accessibilityLabel: id,
});

export const getUniqueStringArray = (
  possibleValueArray: (string | undefined | null)[],
) => {
  const filteredOptions = possibleValueArray.filter(
    (value) => value && value !== '-',
  );
  // Forced because typescript doesn't infer from filter above
  const set = new Set(filteredOptions) as Set<string>;
  return [...set];
};
