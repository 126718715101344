import { apiRequest ,
  getGraphQLData,
  getGraphQLDevicesQuery,
  GraphQLDevicesQueryTypes,
} from 'api';
import { Device, Maybe, RawDeviceType } from 'types';
import { deviceTypeToRawDeviceType } from 'utils/device';

import {
  logDeviceAssignedEvent,
  logDeviceTypeChangeEvent,
  logDeviceNameChangeEvent,
} from '../firebase';


export type DeviceData = {
  name?: string;
  device_type?: RawDeviceType;
  acknowledged_time?: number;
  profile_id?: Maybe<string>;

  qr_brand?: string;
  qr_model?: string;
  qr_os?: string;
  qr_os_version?: string;
  qr_user_agent?: string;
  assigned_by_qr?: boolean;
};
export type UpdateDeviceData = {
  name?: string;
  device_type?: RawDeviceType;
  acknowledged_time?: number;
  profile_id?: Maybe<string>;

  qr_brand?: string;
  qr_model?: string;
  qr_os?: string;
  qr_os_version?: string;
  qr_user_agent?: string;
  assigned_by_qr?: boolean;
}
export const updateDevice = async (
  deviceUid: string,
  data: DeviceData,
  previousDevice: Device,
) => {
  try {
    const { device_type: newRawDeviceType, ...restOfDataToSave } = data;

    const payload = {
      ...restOfDataToSave,
      device_type: newRawDeviceType,
    };
    if( Object.prototype.hasOwnProperty.call(restOfDataToSave, 'profile_id') &&
       !Object.prototype.hasOwnProperty.call(restOfDataToSave, 'assigned_by_qr')
      ){
      payload.assigned_by_qr = false;
    }
    const response = await apiRequest({
      service: 'dataAPI',
      path: `/api/device/${deviceUid}`,
      method: 'PUT',
      data: payload,
    });

    // Log device type change any time they are different and a new device type was passed in
    const oldRawDeviceType = deviceTypeToRawDeviceType(
      previousDevice.deviceType,
    );
    if (
      newRawDeviceType !== undefined &&
      newRawDeviceType !== oldRawDeviceType
    ) {
      logDeviceTypeChangeEvent({
        fromType: oldRawDeviceType,
        toType: newRawDeviceType,
        deviceUid,
      });
    }

    // Log every time device name changes and a new name was passed in
    const newDeviceName = restOfDataToSave.name;
    const oldDeviceName = previousDevice.name;
    if (newDeviceName && oldDeviceName && newDeviceName !== oldDeviceName) {
      logDeviceNameChangeEvent({
        fromName: oldDeviceName,
        toName: newDeviceName,
        deviceUid,
      });
    }

    // Log every time a device changes profile id
    if (previousDevice?.profileId !== data?.profile_id) {
      logDeviceAssignedEvent({
        deviceUid,
        fromProfileUid: previousDevice?.profileId?.toString(),
        toProfileUid: data?.profile_id,
      });
    }

    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const removeDevice = (deviceId: number) =>
  apiRequest({
    service: 'dataAPI',
    path: `/api/device/${deviceId}`,
    method: 'DELETE',
    data: {
      device_id: deviceId,
    },
  });

export const getDevicesGraphQLData = (accountId: string) =>
  getGraphQLData<GraphQLDevicesQueryTypes>(getGraphQLDevicesQuery(accountId));
