import { UserResponseData } from 'api';
import { User } from 'types';

export const processRawUser = (user?: UserResponseData): User | undefined => {
  if (!user) {
    return undefined;
  }
  const {
    beta_accepted,
    demo,
    devices,
    email,
    first_name,
    last_name,
    permissions,
    phone,
    terms_accepted,
    uid,
    onboarding_steps,
  } = user;

  // TODO: Add data validation to make type easier

  return {
    uid,
    email,
    firstName: first_name,
    lastName: last_name,
    phone,
    devices,
    termsAccepted: terms_accepted,
    betaAccepted: beta_accepted,
    demo,
    permissions,
    onboardingSteps: onboarding_steps,
  };
};
