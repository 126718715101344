import { apiRequest, ApiRequestData } from 'api';

export type CreateSensorResponse = {
  certificatePem: string;
  message: string;
  privateKey: string;
  sensor_id: string;
  status: number;
};
export const createIoTSensor = (
  accountId: string,
  userId: string,
  axiosConfig?: ApiRequestData,
) =>
  apiRequest<CreateSensorResponse>({
    service: 'accountManagement',
    path: '/sensor/create',
    method: 'POST',
    data: {
      account: accountId,
      user: userId,
    },
    ...axiosConfig,
  });

type DeleteIoTSensorConfig = {
  factory_reset: boolean;
  delete_cloud_resources: boolean;
};
export const deleteIoTSensor = (
  sensorId: string,
  data: DeleteIoTSensorConfig,
  axiosConfig?: ApiRequestData,
) =>
  apiRequest({
    service: 'accountManagement',
    path: `/sensor/${sensorId}`,
    method: 'DELETE',
    data,
    ...axiosConfig,
  });
