import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AxiosResponse } from 'axios';

import {
  
  getAllGraphQLData,
  getHomeGraphQLData,
  getProfilesGraphQLData,
  getSensorsGraphQLData,
  getTotalFeedbackCount,
  getDevicesGraphQLData,

  GraphQLAlertResponseData,
  GraphQLGetAllData,
  GraphQLHomeQueryTypes,
  GraphQLProfilesQueryTypes,
  GraphQLSensorsQueryTypes,
  GraphQLDevicesQueryTypes,

  FeedbackCountResponseData,
} from 'api';
import { Home, Profile, Device, Sensor } from 'types';
import {
  processRawDeviceArray,
  processRawProfileArray,
  processRawSensorArray,
} from 'utils';

import { useAccountContext } from './AccountContext';
import { useAuthContext } from './AuthContext';

export const GraphQLContext = createContext<{
  home?: Home;
  homeLoaded: boolean;
  profiles: Record<string, Profile | undefined>;
  profilesLoaded: boolean;
  profileArray: Profile[];
  profileArrayLoaded: boolean;
  devices: Record<string, Device | undefined>;
  devicesLoaded: boolean;
  deviceArray: Device[];
  deviceArrayLoaded: boolean;
  devicesByProfileUid: Record<
    string,
    Record<string, Device | undefined> | undefined
  >;
  devicesByProfileUidLoaded: boolean;
  deviceArrayByProfileUid: Record<string, Device[] | undefined>;
  deviceArrayByProfileUidLoaded: boolean;
  sensors: Record<string, Sensor | undefined>;
  sensorsLoaded: boolean;
  sensorArray: Sensor[];
  sensorArrayLoaded: boolean;
  activeSensor?: Sensor;
  activeSensorLoaded: boolean;

  getAllData: () => Promise<
    [
      AxiosResponse<GraphQLGetAllData>,
      AxiosResponse<FeedbackCountResponseData>,
      AxiosResponse<GraphQLAlertResponseData> | undefined,
    ]
  >;
  getHome: () => Promise<AxiosResponse<GraphQLHomeQueryTypes>>;
  getProfiles: () => Promise<AxiosResponse<GraphQLProfilesQueryTypes>>;
  getDevices: () => Promise<AxiosResponse<GraphQLDevicesQueryTypes>>;
  getSensors: () => Promise<AxiosResponse<GraphQLSensorsQueryTypes>>;
  reset: () => void;
}>(undefined!);
export const useGraphQLContext = () => useContext(GraphQLContext);

export const GraphQLProvider: React.FC = (props) => {
  //const { setActiveSensor: setErrorActiveSensorData } = useErrorDataContext();
  const { fbUser } = useAuthContext();
  const { account, user } = useAccountContext();
  const accountId = account?.uid;
  const userId = user?.uid;
  const firebaseUserUid = fbUser?.uid;
  const [home, setHome] = useState<Home>();
  const [homeLoaded, setHomeLoaded] = useState(false);
  const [profiles, setProfiles] = useState<Record<string, Profile>>({});
  const [profilesLoaded, setProfilesLoaded] = useState(false);
  const [profileArray, setProfileArray] = useState<Profile[]>([]);
  const [profileArrayLoaded, setProfileArrayLoaded] = useState(false);
  const [devices, setDevices] = useState<Record<string, Device>>({});
  const [devicesLoaded, setDevicesLoaded] = useState(false);
  const [deviceArray, setDeviceArray] = useState<Device[]>([]);
  const [deviceArrayLoaded, setDeviceArrayLoaded] = useState(false);
  const [devicesByProfileUid, setDevicesByProfileUid] = useState<
    Record<string, Record<string, Device>>
  >({});
  const [devicesByProfileUidLoaded, setDevicesByProfileUidLoaded] =
    useState(false);
  const [deviceArrayByProfileUid, setDeviceArrayByProfileUid] = useState<
    Record<string, Device[]>
  >({});
  const [deviceArrayByProfileUidLoaded, setDeviceArrayByProfileUidLoaded] =
    useState(false);
  const [sensors, setSensors] = useState<Record<string, Sensor>>({});
  const [sensorsLoaded, setSensorsLoaded] = useState(false);
  const [sensorArray, setSensorArray] = useState<Sensor[]>([]);
  const [sensorArrayLoaded, setSensorArrayLoaded] = useState(false);
  const [activeSensor, setActiveSensor] = useState<Sensor>();
  const [activeSensorLoaded, setActiveSensorLoaded] = useState(false);

  // useEffect(() => {
  //   setErrorActiveSensorData(activeSensor);
  // }, [setErrorActiveSensorData, activeSensor]);

  const getAllData = useCallback(async () => {
    try {
      if (!accountId) {
        throw Error('No Account Id to use to load data');
      }
      if (!userId) {
        throw Error('No User Id to use to load data');
      }
      const [response, feedbackResponse] = await Promise.all([
        getAllGraphQLData(accountId),
        getTotalFeedbackCount(userId),
      ]);

      let unreadAlertsResponse:
        | AxiosResponse<GraphQLAlertResponseData>
        | undefined;

      if (response?.data?.data?.allHomes?.edges?.[0]?.node) {
        const {
          accountId: acctId,
          name,
          uid,
          timezone,
          profiles: rawProfileArray,
          sensors: rawSensorArray,
          devices: rawDeviceArray,
        } = response?.data?.data?.allHomes?.edges?.[0]?.node ?? {};

        if (acctId) {
          setHome({ accountId: acctId, name, uid, timezone });
          setHomeLoaded(true);
        }
        if (rawSensorArray.edges) {
          const { newActiveSensor, newSensorArray, newSensors } =
            processRawSensorArray(rawSensorArray.edges);
          setActiveSensor(newActiveSensor);
          setActiveSensorLoaded(true);
          setSensorArray(newSensorArray);
          setSensorArrayLoaded(true);
          setSensors(newSensors);
          setSensorsLoaded(true);
        }
        if (rawProfileArray.edges) {
          const { newProfileArray, newProfiles } = processRawProfileArray(
            rawProfileArray.edges,
          );
          setDeviceArrayByProfileUidLoaded(true);
          setProfileArray(newProfileArray);
          setProfileArrayLoaded(true);
          setProfiles(newProfiles);
          setProfilesLoaded(true);
        }
        if (rawDeviceArray.edges) {
          const {
            newDeviceArray,
            newDevices,
            newDevicesByProfileUid,
            newDeviceArrayByProfileUid,
          } = processRawDeviceArray(rawDeviceArray.edges);

          setDeviceArray(newDeviceArray);
          setDeviceArrayLoaded(true);
          setDevices(newDevices);
          setDevicesLoaded(true);
          setDevicesByProfileUid(newDevicesByProfileUid);
          setDevicesByProfileUidLoaded(true);
          setDeviceArrayByProfileUid(newDeviceArrayByProfileUid);
          setDeviceArrayByProfileUidLoaded(true);
        }
      }

      return [response, feedbackResponse, unreadAlertsResponse] as [
        AxiosResponse<GraphQLGetAllData>,
        AxiosResponse<FeedbackCountResponseData>,
        AxiosResponse<GraphQLAlertResponseData> | undefined,
      ];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [accountId, userId]);

  const getHome = useCallback(async () => {
    try {
      if (!accountId) {
        throw Error('No Account Id to use to load data');
      }
      const response = await getHomeGraphQLData(accountId);
      if (response?.data?.data?.allHomes?.edges?.[0]?.node) {
        const {
          accountId: acctId,
          name,
          uid,
          timezone,
        } = response?.data?.data?.allHomes?.edges?.[0]?.node ?? {};

        if (acctId) {
          setHome({ accountId: acctId, name, uid, timezone });
          setHomeLoaded(true);
        }
      }
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [accountId]);

  const getProfiles = useCallback(async () => {
    try {
      if (!accountId) {
        throw Error('No Account Id to use to load data');
      }

      const res = await getProfilesGraphQLData(accountId);
      const rawProfileArray =
        res?.data?.data?.allHomes?.edges?.[0]?.node?.profiles?.edges;
      if (rawProfileArray) {
        const { newProfileArray, newProfiles } =
          processRawProfileArray(rawProfileArray);

        setDeviceArrayByProfileUidLoaded(true);
        setProfileArray(newProfileArray);
        setProfileArrayLoaded(true);
        setProfiles(newProfiles);
        setProfilesLoaded(true);
      }
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [accountId]);

  const getDevices = useCallback(async () => {
    try {
      if (!accountId) {
        throw Error('No Account Id to use to load data');
      }
      // TODO: This is lazy and we should do this in one load or figure out how to get presnce information on the profile without loading the devices
      const res = await getDevicesGraphQLData(accountId);
      const rawDeviceArray =
        res?.data?.data?.allHomes?.edges?.[0]?.node?.devices?.edges;
      if (rawDeviceArray) {
        const {
          newDeviceArray,
          newDevices,
          newDevicesByProfileUid,
          newDeviceArrayByProfileUid,
        } = processRawDeviceArray(rawDeviceArray);

        setDeviceArray(newDeviceArray);
        setDeviceArrayLoaded(true);
        setDevices(newDevices);
        setDevicesLoaded(true);
        setDevicesByProfileUid(newDevicesByProfileUid);
        setDevicesByProfileUidLoaded(true);
        setDeviceArrayByProfileUid(newDeviceArrayByProfileUid);
        setDeviceArrayByProfileUidLoaded(true);
      }
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [accountId]);

  const getSensors = useCallback(async () => {
    try {
      if (!accountId) {
        throw Error('No Account Id to use to load data');
      }
      const response = await getSensorsGraphQLData(accountId);
      if (response?.data?.data?.allHomes?.edges?.[0]?.node) {
        const { sensors: rawSensorArray } =
          response?.data?.data?.allHomes?.edges?.[0]?.node ?? {};

        if (rawSensorArray?.edges) {
          const { newActiveSensor, newSensorArray, newSensors } =
            processRawSensorArray(rawSensorArray.edges);
          setActiveSensor(newActiveSensor);
          setActiveSensorLoaded(true);
          setSensorArray(newSensorArray);
          setSensorArrayLoaded(true);
          setSensors(newSensors);
          setSensorsLoaded(true);
        }
      }
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [accountId]);

  const reset = useCallback(() => {
    setHome(undefined);
    setHomeLoaded(false);
    setProfiles({});
    setProfilesLoaded(false);
    setProfileArray([]);
    setProfileArrayLoaded(false);
    setDevices({});
    setDevicesLoaded(false);
    setDeviceArray([]);
    setDeviceArrayLoaded(false);
    setDevicesByProfileUid({});
    setDevicesByProfileUidLoaded(true);
    setDeviceArrayByProfileUid({});
    setDeviceArrayByProfileUidLoaded(true);
    setSensors({});
    setSensorsLoaded(false);
    setSensorArray([]);
    setSensorArrayLoaded(false);
    setActiveSensor(undefined);
    setActiveSensorLoaded(false);
  }, []);

  // initiate loading
  useEffect(() => {
    
    if (firebaseUserUid && accountId && userId) {
      getAllData();
    }

    return () => {
      reset();
    };
  }, [firebaseUserUid, accountId, userId, getAllData, reset]);

  const value = useMemo(
    () => ({
      home,
      homeLoaded,
      profiles,
      profilesLoaded,
      profileArray,
      profileArrayLoaded,
      sensors,
      sensorsLoaded,
      sensorArray,
      sensorArrayLoaded,
      activeSensor,
      activeSensorLoaded,
      devices,
      devicesLoaded,
      deviceArray,
      deviceArrayLoaded,
      devicesByProfileUid,
      devicesByProfileUidLoaded,
      deviceArrayByProfileUid,
      deviceArrayByProfileUidLoaded,
      getAllData,
      getHome,
      getSensors,
      getProfiles,
      getDevices,
      reset,
    }),
    [
      home,
      homeLoaded,
      profiles,
      profilesLoaded,
      profileArray,
      profileArrayLoaded,
      sensors,
      sensorsLoaded,
      sensorArray,
      sensorArrayLoaded,
      activeSensor,
      activeSensorLoaded,
      devices,
      devicesLoaded,
      deviceArray,
      deviceArrayLoaded,
      devicesByProfileUid,
      devicesByProfileUidLoaded,
      deviceArrayByProfileUid,
      deviceArrayByProfileUidLoaded,
      getAllData,
      getHome,
      getSensors,
      getProfiles,
      getDevices,
      reset,
    ],
  );

  return (
    <GraphQLContext.Provider value={value}>
      {props.children}
    </GraphQLContext.Provider>
  );
};
