import React, {
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';

import {
  format
} from 'date-fns-tz';
import {
  BsArrowUpShort,
  BsArrowDownShort
} from 'react-icons/bs';
import {
 //FiChevronRight,
 FiArrowUpRight,
 //FiRefreshCw,
 FiAlertCircle
} from 'react-icons/fi';
import {
  Link
} from 'react-router-dom';
import styled from 'styled-components';

import {
  //Logo,
  Button
} from 'components';
import {
  useGraphQLContext,
  useConfigContext
} from 'context';
import {
  SensorSelect
} from 'elements';
import {
  // useScrollUpByTo,
  // useScrollDownByTo,
  usePrevious
} from 'hooks';
import {
  FlexContainer,
  Flex4,
  Flex6,
  Flex8
} from 'layout';
import {
  ActivityReportModule,
  TMSIMonitor,
  //CellMonitor,
  TmsiActivityModule
} from 'pages';

export const TimeNow:React.FC<{
  connector?: React.ReactNode;
}> = ({
  connector
}) => {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(function(){
    const interval = setInterval(function(){
      setTime(new Date());
    },1000);

    return function(){
      clearInterval(interval);
    };
  },[]);

  return <>{format(time, 'EEEE, MMMM d')}{(connector||' ')}{format(time, 'pp zzz')}</>;
};
export const Landing: React.FC = () => {

  //const headerRef = useRef<HTMLDivElement>(null);
  //const [elBottom, setElBottom] = useState<number|undefined>();
  //const offsetTop = 61;
  const [tmsiPingsSensor, setTmsiPingsSensor] = useState<string>();
  const [cellularActivitySensor, setCellularActivitySensor] = useState<string>();

  const [pingsExpanded, setPingsExpanded] = useState<boolean>(false);

  // useScrollDownByTo(2, elBottom);
  // useScrollUpByTo(elBottom, 0);

  // useEffect(function hookUpOnResize(){
  //   let timeout: any;
  //   function getHeaderSize(){
  //     if(!headerRef.current){ return; }
  //
  //     const bbBox = headerRef.current.getBoundingClientRect();
  //     const offsetY = window.scrollY;
  //
  //     const top = offsetY + bbBox.top - offsetTop;
  //     const bottom = top + bbBox.height;
  //
  //     setElBottom(bottom);
  //
  //     console.log('bbBox, offsetY', {bbBox, offsetY});
  //   }
  //   function checkHeaderSize(){
  //     clearTimeout(timeout);
  //     timeout = setTimeout(getHeaderSize, 500);
  //   }
  //   getHeaderSize();
  //   window.addEventListener('resize', checkHeaderSize);
  //   return function(){
  //     clearTimeout(timeout);
  //     window.removeEventListener('resize', checkHeaderSize);
  //   };
  // },[setElBottom]);

  return (
    <>

      <WarningsDisplay />

      <Section>

        <Flex6>
          <TimeBox className="center">

            <h2><TimeNow connector={<br />}/></h2>

          </TimeBox>
        </Flex6>

        <Flex6>
          <ShaddowBox>
            <p><b>👋 Welcome!</b> This is your very own hub for your HomeAware V3F Sensor. Here you can explore all the data your sensor collects. {'\n\n'}</p>
          </ShaddowBox>
        </Flex6>
      </Section>

      <Section>
        <Flex6>
          <ShaddowBox>
            <h3>TMSI Pings</h3>
            <StyledSensorSelect
              onSelect={sensor => setTmsiPingsSensor(sensor)}
              preserveByName="landing-pings"
              />
            <TmsiActivityModule
              minHeight={pingsExpanded ? 635 : undefined}
              sensorId={tmsiPingsSensor}
              dateMode='day'
              startDate={new Date()}
              />

            <ActionBox>
              <BoxButton onClick={() => setPingsExpanded(v=> !v)}>{pingsExpanded ? <BsArrowUpShort /> : <BsArrowDownShort />}</BoxButton>
              <Link className="bottomRight" to="tmsi-dashboard">Explore deeper <FiArrowUpRight /></Link>
            </ActionBox>

          </ShaddowBox>

          <ShaddowBox>
            <h3><span className="mediaFull">Cellular Activity Today</span><span className="mediaMobile">Activity</span></h3>
            <StyledSensorSelect
              onSelect={sensor => setCellularActivitySensor(sensor)}
              preserveByName="landing-activity"
              />
            <ActivityReportModule
              sensorId={cellularActivitySensor}
              dateMode='day'
              startDate={new Date()}
              />
            <Link className="bottomRight" to="activity-report">Explore deeper <FiArrowUpRight /></Link>
          </ShaddowBox>

          {/*
            <ShaddowBox>
              <AnnouncementBox/>
            </ShaddowBox>
          */}


          {/*
          <ShaddowBox>
            <h3>Real-time Cell Monitor</h3>
            <CellMonitor />
            <Link className="bottomRight" to="cell-monitor#tmsi-in-time">Explore deeper <FiArrowUpRight /></Link>
          </ShaddowBox>
          */}
        </Flex6>
        <Flex6>

          <ShaddowBox>
            <h3>Real-time TMSI Monitor</h3>
            <TMSIMonitor variant="inline" />
            <Link className="bottomRight" to="tmsi-dashboard">Explore deeper <FiArrowUpRight /></Link>
          </ShaddowBox>

        </Flex6>
      </Section>

    </>
  );
};

const cellularEnabledRegex = /(v3f)/g;

type SensorWarning = {
  name: string,
  text: string,
  status: 'active'|'dismissed'|'disabled'
};

const WarningsDisplay: React.FC = () => {
  const {sensorArray, sensorArrayLoaded} = useGraphQLContext();
  const [warnings, setWarnings] = useState<SensorWarning[]>([]);
  const {config, saveUserConfig, configLoaded} = useConfigContext();
  const prevSensorArray = usePrevious(sensorArray);
  const prevConfig = usePrevious(config);

  // Non Cell Sensor Warning
  useEffect(function(){
    console.log('[WarningsDisplay] user config', {config, saveUserConfig, configLoaded});

    const warningType = 'non-cell-sensors';
    if(!sensorArrayLoaded || !configLoaded){ return; }
    if(!(prevSensorArray !== sensorArray||config !== prevConfig)){ return; }

    const disabledWarnings = config?.disabledWarnings;
    const nonCellArray = sensorArray.filter(s => !cellularEnabledRegex.test(String(s.sensorType)) ).map(s => s.uid);
    if(!nonCellArray.length){ return; }
    const currentIndex = warnings.findIndex(w => w.name === warningType);
    const sensorWarning: SensorWarning = currentIndex > -1 ? warnings.splice(currentIndex,1)[0] : {
      name: 'non-cell-sensors',
      text: '',
      status: 'active'
    };

    sensorWarning.text = `We have detected non cellular-enabled sensor${nonCellArray.length>1?'s':''} (${nonCellArray.join(', ')}) on your account. Please note that those wont produce outcome on this dashboard`;

    if( disabledWarnings?.[warningType] ){
      sensorWarning.status = 'disabled';
    }

    warnings.splice(0,0, sensorWarning);

    setWarnings([...warnings]);
  },[sensorArray, warnings, sensorArrayLoaded, config, configLoaded, prevConfig, prevSensorArray, saveUserConfig]);

  const currentWarning = useMemo(() => warnings.find(w => w.status === 'active'),[warnings]);

  const dismissWarning = useCallback(function markWarningAsDismissed(warning: typeof warnings[0]){
    warning.status = 'dismissed';

    setWarnings([...warnings]);
  },[warnings]);

  const disableWarning = useCallback(function markWarningAsDismissed(warningType: string){
    if(!configLoaded){ return; }
    const newConfig = config || {};

    // adjust config
    if(!newConfig.disabledWarnings){
      newConfig.disabledWarnings = {};
    }

    (newConfig.disabledWarnings as any)[warningType] = true;

    saveUserConfig(newConfig);
  }, [config, configLoaded, saveUserConfig]);

  return currentWarning
    ?
    <ErrorMessage>
      <Flex8>
        <FiAlertCircle />
        <p>{currentWarning.text}</p>
      </Flex8>
      <Flex4>
        <Button onClick={() => dismissWarning(currentWarning)} variant='plain'>Dismiss</Button>

        <Button onClick={() => {
          disableWarning(currentWarning.name);
        }} variant='plain'>Don't show again</Button>
      </Flex4>

    </ErrorMessage>
    :
    null;
};
// const AnnouncementBox: React.FC = () => {
//
//   const empty = true;
//
//   return !empty ? <StyledAnnouncementBox>
//
//     <mark className="badge">{1}</mark>
//     <p>Announcement Title</p>
//     <p>Some description lorem ipsum. Some description lorem ipsum. Some description lorem ipsum. Some description lorem ipsum.</p>
//     <button>next <FiChevronRight /></button>
//
//   </StyledAnnouncementBox> :
//
//   <StyledAnnouncementBox className="empty">
//
//     <Logo
//       variant='icon'
//       className="logo"
//       />
//
//     <p>Your are HomeAware! More announcements will show up here as they come up.</p>
//     <button>start over <FiRefreshCw /></button>
//
//   </StyledAnnouncementBox>;
//
// };


// const Header = styled(FlexContainer)`${({theme})=> `
//   padding: ${theme.boxPadding.xl3}px ${theme.boxPadding.xl7}px;
//   border-radius: ${theme.boxPadding.l}px;
//   background-color: ${theme.colors.sky.lightest};
//
//   > * {
//     position: relative;
//   }
//
//   p {
//     margin-right: ${theme.boxMargins.xl9*2}px;
//   }
// `}`;

// const GrayInset = styled.div`${({theme}) => `
//   padding: ${theme.boxPadding.xl5}px;
//   border-radius: ${theme.boxPadding.base}px;
//   background-color: ${theme.colors.sky.light};
//   min-height: 100%;
//   box-sizing: border-box;
//
//   a {
//
//     display: block;
//
//     color: ${theme.colors.ink.lighter};
//     cursor: pointer;
//
//     padding: ${theme.boxPadding.base}px ${theme.boxPadding.xl}px;
//
//     margin-left: ${-theme.boxPadding.xl+theme.boxPadding.base}px;
//
//   }
// `}`;

const Section = styled(FlexContainer)`${() => `


`}`;

const ShaddowBox = styled.div`${({theme}) => `
  position: relative;
  box-shadow: 0px 0px 8px rgba(${theme.colorsRgb.ink.black.join(',')},0.1);
  border-radius: ${theme.fontPadding.l}px;

  padding: ${theme.fontPadding.s}px ${theme.boxPadding.xl}px;

  > h1,
  > h2,
  > h3,
  > h4 {
    margin-top:0;
  }

  margin-left: ${theme.boxMargins.xl2}px;
  margin-right: ${theme.boxMargins.xl2}px;


  margin-bottom: ${theme.boxMargins.xl3}px;


  &.center {
    text-align: center;
  }

  .bottomRight {
    display: block;
    text-align: right;
    margin-top: ${theme.fontPadding.m}px;
  }

  .spread-around > * {
    display: inline-block;
    justfyContent: space-between;
  }

  @media (min-width: 1400px) {
    .mediaMobile {
      display: none;
    }
  }
  @media (max-width: 1400px) {
    .mediaFull {
      display: none;
    }
  }

`}`;

const ActionBox = styled.div`${() => `
  display: flex;
  > * {
    display: inline-block;
  }
  flexDirection: row;
  justify-content: space-between;
`}`;
const BoxButton = styled.button`${({theme}) => `
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  font-size: ${theme.fontSize.xl2}px;
  color: ${theme.colors.ink.lighter};
`}`;

const TimeBox = styled(ShaddowBox)`${() => `

  display: flex;
  flexDirection: row;

  > h2 {
    flex-grow: 2;
    text-align: center;
    margin: 0 auto;
  }

  .logo-name {
    color: grey;
  }

  .logo-slogan{
    display: none;
  }
`}`;

// const LinksList = styled.div.attrs({} as {
//   variant: 'plain' | 'gray';
// })`${({theme, variant}) => `
//
//   padding: 0;
//   display: inline-block;
//
//   border-radius: ${theme.boxPadding.base}px;
//
//   > a {
//     display: block;
//
//     padding: ${theme.boxPadding.base}px;
//     padding-left: ${theme.boxPadding.xl2}px;
//
//     margin: ${theme.boxMargins.base}px 0;
//   }
//
//   > a {
//     color: ${theme.colors.ink.base};
//   }
//
//   > a > svg:first-child{
//     margin-right: ${theme.boxPadding.base}px;
//   }
//
//   ${variant === 'gray' ? `
//
//     padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl2}px;
//     background-color: ${theme.colors.sky.lighter};
//
//   ` : ''};
//
// `}`;

// const StyledAnnouncementBox = styled.div`${({theme}) => `
//   position: relative;
//
//   .badge {
//     display: inline-block;
//     background-color: ${theme.colors.red.base};
//     min-width: ${theme.fontSize.xl}px;
//     height: ${theme.fontSize.xl}px;
//     line-height: ${theme.fontSize.xl}px;
//     border-radius: ${theme.fontSize.xl}px;
//     color: ${theme.colors.sky.white};
//     text-align: center;
//   }
//   .badge{
//     position: absolute;
//     top: ${theme.fontSize.xxs}px;
//     right: ${theme.fontSize.xxs}px;
//   }
//   button {
//     background: none;
//     border: none;
//     cursor pointer;
//     font-size: ${theme.fontSize.m}px;
//     color: ${theme.colors.ink.lighter};
//   }
//   button:hover {
//     color: ${theme.colors.ink.darker};
//   }
//   button svg {
//     vertical-align: middle;
//     margin-top: -2px;
//     margin-left: 5px;
//   }
//
//   button {
//     position: absolute;
//     right: ${theme.fontSize.xxs}px;
//     bottom: ${theme.fontSize.xxs}px;
//   }
//
//   &.empty button {
//     left: 0;
//   }
//
//   padding-bottom: ${theme.boxPadding.xl10}px;
//   &.empty {
//     padding-top: ${theme.boxPadding.xl}px;
//     padding-bottom: ${theme.boxPadding.xl5}px;
//   }
//
//   .logo {
//     width: 40px;
//     height: auto;
//   }
//
//   max-width: 300px;
//   text-align: center;
//   margin: 0 auto;
//   line-height: ${theme.fontSize.xl2}px;
//
// `}`;

const StyledSensorSelect = styled(SensorSelect)`${({theme}) => `
  position: absolute;
  top: 0;
  right: 0;

  select {
    padding-top: ${theme.fontPadding.s}px;
    padding-bottom: ${theme.fontPadding.s}px;
    padding-left: ${theme.fontPadding.l + theme.fontPadding.s}px;

    border-radius: 0 ${theme.fontPadding.l}px 0 ${theme.fontPadding.l}px;
  }
`}`;

const ErrorMessage = styled.div`${({theme}) => `
  border-radius: ${theme.boxPadding.xl}px;
  background-color: rgba(${theme.colorsRgb.red.base.toString()}, 0.5);
  padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl5}px;
  border: none;
  margin-top: ${theme.boxMargins.l}px;

  position: relative;

  font-weight: bold;
  color: ${theme.colors.red.dark};

  > :not(svg) {
    margin: ${theme.boxMargins.s}px 0;
  }
  > svg:last-child {
    width: ${theme.boxPadding.l*2}px;
    height: ${theme.boxPadding.l*2}px;
    color: rgba(${theme.colorsRgb.sky.white.toString()},0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: ${theme.boxPadding.xl5}px;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  p {
    display: inline-block
  }
  button {
    float: right;
    flex-shrink: 0;
  }

  svg {
    width: ${theme.fontSize.xl4}px;
    height: ${theme.fontSize.xl4}px;
    vertical-align: middle;
    margin-top: -${theme.fontSize.s/4}px;
    margin-right: ${theme.fontSize.xl}px;
    flex-shrink: 0;
  }

  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`}`;
