import { formatDistance } from 'date-fns';

import { GraphQLDeviceEdge, GraphQLDeviceNode } from 'api';
import { RawDeviceType, DeviceType ,
  Device,
  DeviceDerivedDisplayData,
  DeviceDerivedDisplayDataItem,
  LabelValueData,
  Maybe,
} from 'types';
import { isPresent } from 'utils/typescript';

import { getUniqueStringArray } from './misc';

export const defaultDeviceLabelValue = (device?: Device) => {
  const labelValueArray: LabelValueData[] = [];
  if (device?.derivedDisplayData) {
    const {
      lastSeen,
      model,
      netBiosName,
      createdAt,
      hostName,
      wifiManufacturer,
      discoveredDeviceName,
      inferredOperatingSystem,
      wifiMacAddress,
      previousWifiNetworks,
      deviceType,
      suggestedName,
      suggestedDeviceType,
      wifiNetworkIPAddress,
    } = device.derivedDisplayData;

    if (lastSeen) {
      labelValueArray.push(lastSeen);
    }
    if (createdAt) {
      labelValueArray.push(createdAt);
    }
    if (suggestedName && device.name !== suggestedName.value) {
      labelValueArray.push(suggestedName);
    }
    if (deviceType) {
      labelValueArray.push(deviceType);
    }
    if (
      suggestedDeviceType &&
      deviceType?.value !== suggestedDeviceType?.value
    ) {
      labelValueArray.push(suggestedDeviceType);
    }
    if (model) {
      labelValueArray.push(model);
    }
    if (discoveredDeviceName) {
      labelValueArray.push(discoveredDeviceName);
    }
    if (netBiosName) {
      labelValueArray.push(netBiosName);
    }
    if (hostName) {
      labelValueArray.push(hostName);
    }
    if (wifiManufacturer) {
      labelValueArray.push(wifiManufacturer);
    }
    if (wifiMacAddress) {
      labelValueArray.push(wifiMacAddress);
    }
    if (wifiNetworkIPAddress) {
      labelValueArray.push(wifiNetworkIPAddress);
    }
    if (inferredOperatingSystem) {
      labelValueArray.push(inferredOperatingSystem);
    }
    if (previousWifiNetworks) {
      labelValueArray.push(previousWifiNetworks);
    }
  }
  return labelValueArray;
};

export const deviceSort = (a: Device, b: Device) => {
  const aIsPresent = a?.state === 'present';
  const bIsPresent = b?.state === 'present';
  if (aIsPresent && bIsPresent) {
    const aNameIsMacAddress =
      a?.name === a?.derivedDisplayData?.wifiMacAddress?.value;
    const bNameIsMacAddress =
      b?.name === b?.derivedDisplayData?.wifiMacAddress?.value;
    // Push Mac Address to bottom
    if (aNameIsMacAddress && bNameIsMacAddress) {
      return 0;
    }
    if (aNameIsMacAddress) {
      return 1;
    }
    if (bNameIsMacAddress) {
      return -1;
    }

    // Sort Alphabetically
    return (a?.name ?? '')?.toLowerCase() < (b?.name ?? '')?.toLowerCase()
      ? -1
      : (a?.name ?? '')?.toLowerCase() > (b?.name ?? '')?.toLowerCase()
      ? 1
      : 0;
  }
  if (aIsPresent) {
    return -1;
  }
  if (bIsPresent) {
    return 1;
  }

  return (b.lastSeen ?? b.createdAt ?? 0) - (a.lastSeen ?? a.createdAt ?? 0);
};

export const rawDeviceTypeToDeviceType = (
  rawDeviceType?: RawDeviceType,
): DeviceType => {
  switch (rawDeviceType) {
    case 'mobile phone':
      return 'Mobile Phone';
    case 'computer':
      return 'Computer';
    case 'tablet':
      return 'Tablet';
    case 'watch':
      return 'Watch';
    case 'smart tv':
      return 'Smart TV';
    case 'gaming console':
      return 'Gaming Console';
    case 'printer':
      return 'Printer';
    case 'thermostat':
      return 'Thermostat';
    case 'light bulb':
      return 'Light Bulb';
    case 'router':
      return 'Router';
    case 'other IoT':
      return 'Other IoT';
    case 'home security':
      return 'Home Security';
    default:
      return 'Unknown';
  }
};
export const deviceTypeToRawDeviceType = (
  deviceType?: DeviceType,
): RawDeviceType => {
  switch (deviceType) {
    case 'Mobile Phone':
      return 'mobile phone';
    case 'Computer':
      return 'computer';
    case 'Tablet':
      return 'tablet';
    case 'Watch':
      return 'watch';
    case 'Smart TV':
      return 'smart tv';
    case 'Gaming Console':
      return 'gaming console';
    case 'Printer':
      return 'printer';
    case 'Thermostat':
      return 'thermostat';
    case 'Light Bulb':
      return 'light bulb';
    case 'Router':
      return 'router';
    case 'Other IoT':
      return 'other IoT';
    case 'Home Security':
      return 'home security';
    case 'Unknown':
    default:
      return null;
  }
};

export const processRawDevice = (rawDevice: GraphQLDeviceNode) => {
  const {
    presence,
    model,
    wifiid,
    deviceType,
    wifiprobeid,
    createdAt,
    suggestedName,
    suggestedDeviceType,
  } = rawDevice;
  const derivedDisplayData: DeviceDerivedDisplayData = {};

  const fbDetails =
    wifiid?.fingerbankDetails && wifiid?.fingerbankDetails !== '-'
      ? JSON.parse(wifiid.fingerbankDetails)
      : undefined;

  if (presence?.state || presence?.lastSeen) {
    const lastSeenMili =
      (presence?.lastSeen && presence.lastSeen !== -1
        ? presence.lastSeen
        : createdAt ?? 0) * 1000;

    derivedDisplayData.lastSeen = {
      label: 'Last Seen',
      value:
        presence?.state === 'present'
          ? 'Present'
          : !lastSeenMili
          ? 'Unknown'
          : `Left ${formatDistance(new Date(), lastSeenMili)} ago`,
      key: 'lastSeen',
      explanation: 'The last time our sensor detected this device',
    };
  }
  if (model && model !== '-') {
    derivedDisplayData.model = {
      label: 'Model',
      value: model,
      key: 'model',
      explanation: 'The model our sensor detected from this device',
    };
  }
  if (createdAt) {
    derivedDisplayData.createdAt = {
      label: 'First Seen',
      value: `${formatDistance(new Date(), createdAt * 1000)} ago`,
      key: 'createdAt',
      explanation: 'The first time our sensor detected this device',
    };
  }

  if (wifiid?.netbiosName && wifiid.netbiosName !== '-') {
    const nameArray = wifiid.netbiosName.split(', ').filter((n) => n);
    if (nameArray.length > 0) {
      derivedDisplayData.netBiosName = {
        label: nameArray.length > 1 ? 'Net Bios Names' : 'Net Bios Name',
        value: nameArray.length > 1 ? nameArray : nameArray[0],
        key: 'netBiosName',
        explanation:
          'This is a potential name your sensor detected from this device. This name may be useful in determining who owns this device',
      };
    }
  }
  const hostName =
    wifiid?.hostName && wifiid.hostName !== '-'
      ? wifiid.hostName
      : fbDetails?.hostname
      ? fbDetails.hostname
      : undefined;
  if (hostName) {
    derivedDisplayData.hostName = {
      label: 'Host Name',
      value: hostName,
      key: 'hostName',
      explanation:
        'This is a potential name your sensor detected from this device. This name may be useful in determining who owns this device',
    };
  }

  const wifiManufacturerArray = getUniqueStringArray([
    wifiid?.manufacturer,
    wifiprobeid?.manufacturer,
  ]);
  if (wifiManufacturerArray.length === 1) {
    derivedDisplayData.wifiManufacturer = {
      label: 'WiFi Manufacturer',
      value: wifiManufacturerArray[0],
      key: 'wifiManufacturer',
      explanation: 'The company that built this device',
    };
  } else if (wifiManufacturerArray.length > 1) {
    derivedDisplayData.wifiManufacturer = {
      label: 'WiFi Manufacturers',
      value: wifiManufacturerArray.sort((a, b) =>
        a.toLowerCase() < b.toLowerCase()
          ? -1
          : a.toLowerCase() > b.toLowerCase()
          ? 1
          : 0,
      ),
      key: 'wifiManufacturer',
      explanation: 'The company that built this device',
    };
  }

  const wifiMacAddressArray = getUniqueStringArray([
    wifiid?.mac,
    wifiprobeid?.mac,
  ]);
  if (wifiMacAddressArray.length === 1) {
    derivedDisplayData.wifiMacAddress = {
      label:
        wifiid?.macType === 'PUBLIC'
          ? 'WiFi Mac Address'
          : 'Private WiFi Mac Address',
      value: wifiMacAddressArray[0],
      key: 'wifiMacAddress',
      explanation: "The unique identifier of this device's wireless interfaces",
    };
  } else if (wifiMacAddressArray.length > 1) {
    derivedDisplayData.wifiMacAddress = {
      label: 'WiFi Mac Address',
      value: wifiMacAddressArray.sort((a, b) =>
        a.toLowerCase() < b.toLowerCase()
          ? -1
          : a.toLowerCase() > b.toLowerCase()
          ? 1
          : 0,
      ),
      key: 'wifiMacAddress',
      explanation: "The unique identifier of this device's wireless interfaces",
    };
  }
  if (wifiid?.ip) {
    derivedDisplayData.wifiNetworkIPAddress = {
      label: 'Local IP Address',
      value: wifiid.ip,
      key: 'wifiNetworkIPAddress',
      explanation:
        'The IP address of this device on your local WiFi Network. It is common that this IP address changes based on what other devices are on the same network and in what order they were connected.',
    };
  }
  if (fbDetails?.device_name) {
    derivedDisplayData.discoveredDeviceName = {
      label: 'Discovered Device Name',
      value: fbDetails.device_name,
      key: 'discoveredDeviceName',
      explanation:
        'Names that your sensor has discovered about this device. These names may be useful in determining who owns this device',
    };
  }
  if (fbDetails?.operating_system) {
    derivedDisplayData.inferredOperatingSystem = {
      label: 'Inferred Operating System',
      value: fbDetails.operating_system,
      key: 'inferredOperatingSystem',
      explanation: 'The operating system that this device is running',
    };
  }

  const ssidArray = getUniqueStringArray(
    wifiprobeid?.ssidVals?.split('+')?.filter((s) => s) ?? [],
  );
  if (ssidArray.length === 1) {
    derivedDisplayData.previousWifiNetworks = {
      label: 'Previous WiFi Network',
      value: ssidArray[0],
      key: 'previousWifiNetworks',
      explanation:
        'WiFi networks that this device has connected to in the past',
    };
  } else if (ssidArray.length > 1) {
    derivedDisplayData.previousWifiNetworks = {
      label: 'Previous WiFi Networks',
      value: ssidArray.sort((a, b) =>
        a.toLowerCase() < b.toLowerCase()
          ? -1
          : a.toLowerCase() > b.toLowerCase()
          ? 1
          : 0,
      ),
      key: 'previousWifiNetworks',
      explanation:
        'WiFi networks that this device has connected to in the past',
    };
  }

  derivedDisplayData.deviceType = {
    label: 'Device Type',
    value: rawDeviceTypeToDeviceType(deviceType),
    key: 'deviceType',
    explanation: 'The category of the device',
  };

  if (suggestedName) {
    derivedDisplayData.suggestedName = {
      label: 'Suggested Name',
      value: suggestedName,
      key: 'suggestedName',
      explanation:
        "This is HomeAware's suggestion based on data about this device.",
    };
  }

  if (suggestedDeviceType) {
    derivedDisplayData.suggestedDeviceType = {
      label: 'Suggested Device Type',
      value: rawDeviceTypeToDeviceType(suggestedDeviceType),
      key: 'suggestedDeviceType',
      explanation:
        "This is HomeAware's suggestion based on data about this device.",
    };
  }

  if (wifiid?.mdnsDetails) {
    const serviceArray: DeviceDerivedDisplayDataItem<string>[] = [];
    const formattedMdns: Record<string, string[]>[] = [];
    const parsedMDNSDetails = JSON.parse(wifiid.mdnsDetails);
    parsedMDNSDetails.forEach((item: any) => {
      const serviceName = item.service_name;
      const innerDetailArray: string[] = [];
      Object.entries(item).map(([k, v]) => {
        if (Array.isArray(v)) {
          return v.map(({ key, value }) =>
            innerDetailArray.push(`${key} : ${value}`),
          );
        }
        return innerDetailArray.push(`${k} : ${v}`);
      });
      formattedMdns.push({
        [serviceName]: innerDetailArray,
      });
    });

    formattedMdns.forEach((i) => {
      const serviceKeys = Object.keys(i);
      serviceKeys.forEach((key) => {
        serviceArray.push({
          label: !key ? 'Unnamed Service' : key,
          value: i[key],
          key: `${key}_${i[key]}`,
          explanation:
            'A list of services our sensor detected from this device.',
        });
      });
    });
    if (serviceArray.length > 0) {
      derivedDisplayData.mdnsServiceArray = serviceArray;
    }
  }

  return {
    ...rawDevice,
    state: presence?.state,
    lastSeen: presence?.lastSeen,
    lastSeenIntervalStart: presence?.lastSeenIntervalStart,
    deviceType: rawDeviceTypeToDeviceType(deviceType),
    suggestedDeviceType: rawDeviceTypeToDeviceType(suggestedDeviceType),
    derivedDisplayData,
  };
};
export const processRawDeviceArray = (
  rawDeviceArray: Maybe<GraphQLDeviceEdge>[],
) => {
  const newDeviceArray: Device[] = rawDeviceArray
    .map((d) => (d?.node ? processRawDevice(d.node) : null))
    .filter(isPresent);
  const newDevices: Record<string, Device> = {};
  const newDevicesByProfileUid: Record<string, Record<string, Device>> = {};
  const newDeviceArrayByProfileUid: Record<string, Device[]> = {};
  newDeviceArray.forEach((d) => {
    newDevices[d.uid] = d;
    if (d.profileId) {
      if (!newDevicesByProfileUid[d.profileId]) {
        newDevicesByProfileUid[d.profileId] = {};
      }
      newDevicesByProfileUid[d.profileId][d.uid] = d;

      if (!newDeviceArrayByProfileUid[d.profileId]) {
        newDeviceArrayByProfileUid[d.profileId] = [];
      }
      newDeviceArrayByProfileUid[d.profileId].push(d);
    }
  });

  return {
    newDeviceArray,
    newDevices,
    newDevicesByProfileUid,
    newDeviceArrayByProfileUid,
  };
};

export const isPhone = (deviceType: DeviceType) =>
  deviceType === 'Mobile Phone';
export const isPersonalDevice = (deviceType: DeviceType) =>
  deviceType === 'Computer' ||
  deviceType === 'Tablet' ||
  deviceType === 'Watch';
export const isOtherDevice = (deviceType: DeviceType) =>
  deviceType === 'Gaming Console' ||
  deviceType === 'Home Security' ||
  deviceType === 'Light Bulb' ||
  deviceType === 'Other IoT' ||
  deviceType === 'Printer' ||
  deviceType === 'Router' ||
  deviceType === 'Thermostat' ||
  deviceType === 'Unknown' ||
  deviceType === 'Smart TV';

export const isAssignedDevice = (device: Device) => {
  if (!device.profileId) {
    return false;
  }
  return true;
};
