import styled from 'styled-components';

import {UID} from 'utils';

export type RadioProps = {
  disabled?: boolean,

  checked?: boolean,
  defaultChecked?: boolean|undefined,

  id?: string,

  el?: React.MutableRefObject<HTMLInputElement>,
  name?: string,
  placeholder?: string,
  className?: string,
  value?: string,

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const Radio: React.FC<RadioProps> = ({
  disabled=false,

  checked,
  defaultChecked=undefined,

  id=UID(),

  el=null,
  name,
  className,

  value,

  onChange,

}) => (

  <StyledInput
    id={id}
    ref={el}
    key={id}
    type="radio"
    name={name}
    checked={checked}
    defaultChecked={defaultChecked}
    onChange={onChange}
    className={className}
    value={value}
    disabled={disabled}
    />

);

const StyledInput = styled.input`${({theme}) => `
  width: ${theme.fontSize.xl2}px;
  height: ${theme.fontSize.xl2}px;
`}`;
