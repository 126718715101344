import {
  FiX
} from 'react-icons/fi';
import styled, {keyframes, css} from 'styled-components';

import {
  Loading
} from 'components';
import {
  useOverlayContext
} from 'context';

// this has to remain separate because hot reload breaks 
// if it is kept in the same file as context
// also sort of make sense

export const OverlayDisplay = () => {
  const {
    loading, popup,
    hidePopup
  } = useOverlayContext();

  const hasOverlay = loading || popup;

  return hasOverlay ?
    <StyledHover>
      {loading ?
        <Loading />: null}

      {popup ?
        <StyledPopup>
          {popup.dismissable ?
            <StyledClose onClick={() => {popup.onDissmiss?.(); hidePopup();}}/>: null}

          {popup.header ?
            <header>{popup.header}</header>: null}
          
          {popup.title ?
            <h3>{popup.title}</h3>: null}

          {popup.children ?
            <main>{popup.children}</main>: null}
          
          {popup.footer ?
            <footer>{popup.footer}</footer>: null}

        </StyledPopup>: null}
      
    </StyledHover>
    : null;
};

const appear = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
`;

const slideIn = keyframes`
  from {
    transform: transalteY(-30px);
  }
  to {
    transform: transalteY(0);
  }
`;

const StyledHover = styled('div')`${({theme}) => css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  background-color: rgba(${theme.colorsRgb.ink.black.toString()},0.5);

  z-index: 1000;

  > svg {
    position: absolute;
    margin: auto;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.7
  }

  animation-name: ${appear};
  animation-duration: 500ms;
`}`;

// position: absolute;
  // top:0;
  // right:0;
  // bottom:0;
  // left:0;

const StyledPopup = styled('div')`${({theme}) => css`
  position: relative;
  background-color: ${theme.colors.sky.lightest};
  display: inline-block;

  padding: ${theme.boxPadding.xl3}px ${theme.boxPadding.xl5}px;
  border-radius: ${theme.boxPadding.xl}px;

  margin: auto;
  
  > header {

  }

  > main {
    margin: ${theme.boxPadding.xl}px 0 ${theme.boxPadding.xl3}px;
  }

  > footer {
    margin-left: -${theme.boxPadding.l}px;
    margin-right: -${theme.boxPadding.l}px;
  }
  > footer > * {
    margin: ${theme.boxMargins.m}px 0;
  }

  button, input[type="text"] {
    box-sizing: border-box;
    min-width: 100%;
  }

  animation-name: ${slideIn};
  animation-duration: 300ms;
`}`;

const StyledClose = styled(FiX)`${({theme}) => `
  cursor: pointer;
  position: absolute;
  right: ${theme.boxPadding.xl}px;
  top: ${theme.boxPadding.xl}px;
  width: ${theme.boxPadding.xl2}px;
  height: ${theme.boxPadding.xl2}px;

  &:hover {
    color: ${theme.colors.ink.lighter};
  }
`}`;