import { GraphQLProfileEdge } from 'api';
import { Maybe, Profile } from 'types';
import { isPresent } from 'utils';

export const profileSort = (a: Profile, b: Profile) => {
  const aPresent = a.state === 'present';
  const bPresent = b.state === 'present';
  // By Present
  if (aPresent && bPresent) {
    // Sort Alphabetically
    return (a?.name ?? '').toLowerCase() < (b?.name ?? '').toLowerCase()
      ? -1
      : (a?.name ?? '').toLowerCase() > (b?.name ?? '').toLowerCase()
      ? 1
      : 0;
  }
  if (aPresent) {
    return -1;
  }
  if (bPresent) {
    return 1;
  }

  // By Last Seen
  return (b.lastSeen ?? 0) - (a.lastSeen ?? 0);
};

export const processRawProfileArray = (
  rawProfileArray: Maybe<GraphQLProfileEdge>[],
) => {
  const newProfileArray: Profile[] = rawProfileArray
    .map((p) => {
      const rawProfile = p?.node;
      if (!rawProfile) {
        return null;
      }

      const { presence, ...rest } = rawProfile;

      return {
        state: presence?.state,
        lastSeen: presence?.lastSeen,
        lastSeenIntervalStart: presence?.lastSeenIntervalStart,
        ...rest,
      };
    })
    .filter(isPresent);

  const newProfiles: Record<string, Profile> = {};
  newProfileArray.forEach((p) => {
    newProfiles[p.uid] = p;
  });

  return {
    newProfileArray,
    newProfiles,
  };
};
