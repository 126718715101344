import styled from 'styled-components';

const StyledLabel = styled.label`
  color: ${({theme}) => theme.colors.ink.darkest};
  font-size: ${({theme}) => theme.fontSize.base}px;
  font-family: ${({theme}) => theme.fontFamily.inter};
`;

export const SubLabel = styled.span`${({theme}) => `
  display: block;
  font-size: ${theme.fontSize.m}px;
  color: ${theme.colors.ink.lighter};
`}`;

export const Label = StyledLabel;

export default Label;