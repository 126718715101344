import { getAuth } from 'firebase/auth';
import { getDatabase, ref, child, set } from 'firebase/database';

import { firebaseApp } from 'index';

export type UserConfig = {
  disabledWarnings?: {
    'non-cell-sensors'?: boolean;
  }
}

export const saveUserConfig = (payload: UserConfig) => {
  const db = getDatabase(firebaseApp);
  const user = (getAuth()).currentUser;
  const uid = user?.uid;

  if(!uid){ return; }

  const userConfigRef = child(ref(db), 'userConfig/'+uid);

  console.log('[saveUserConfig]', {db, user, payload});

  return set(userConfigRef, payload);
};
