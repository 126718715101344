import {TmsiReport, TmsiReportItem} from 'api';
import { RfVisionCellScan, RfVisionRawCell } from 'types';
import {binaryToDecimal} from 'utils';

export type RawTmsiReportItem = {
  'DATA_TYPE': string,
  'NETWORKPROVIDER': string,
  'POWER_ESTIMATE': string,
  'TMSI': string,
  'UNIX_TIMESTAMP': string
}
const getCellularProviderName = (
  provider?: string,
): string | undefined => {
  // TODO: update this to use some regex instead
  switch (provider) {
    case 'Verizon Wireless':
      return 'Verizon';
    case 'AT&T Mobility':
      return 'AT&T';
    case 'T-Mobile USA':
    case 'Sprintcom Inc':
      return 'T-Mobile';
    default:
      return provider;
  }
};
export const processRawTmsiItem = (rawItem: RawTmsiReportItem): TmsiReportItem => {
  const date = new Date(rawItem.UNIX_TIMESTAMP);

  const item = {
    type: String(rawItem.DATA_TYPE),
    networkProvider: String(rawItem.NETWORKPROVIDER),
    powerEstimate: Number(rawItem.POWER_ESTIMATE),
    tmsi: Number(binaryToDecimal(rawItem.TMSI)),
    epochTimestamp: (date.getTime())/1000,
    date: date,
    timestamp: date.getTime()
  };

  return item;
};

export type RawTmsiReport = RawTmsiReportItem[]
export const processRawTmsiReport = (rawJsonArray: RawTmsiReport): TmsiReport =>

  rawJsonArray.map(processRawTmsiItem);

/*
  "DATETIME": "Mon, 01 Aug 2022 05:00:00 GMT",
  "NETWORK_PROVIDER": "unknown",
  "NUM_RECORDS": 198
*/

export type RawActivityReportItem = {
  'DATETIME': string,
  'NETWORK_PROVIDER': string,
  'NUM_RECORDS': number
}
export const processRawActivityReportItem = (rawItem: RawActivityReportItem) => {
  const date = new Date(rawItem.DATETIME);

  const item = {
    type: 'cell-scan',
    networkProvider: String(rawItem.NETWORK_PROVIDER),
    amount: Number(rawItem.NUM_RECORDS),
    epochTimestamp: (date.getTime())/1000,
    date: date,
    timestamp: date.getTime()
  };

  return item;
};

export type RawActivityReport = RawActivityReportItem[]
export const processRawActivityReport= (rawJsonArray:RawActivityReport) =>

  rawJsonArray.map(processRawActivityReportItem);


export const processRawCellMqttToRawTmsi = function(rawCellItem: RfVisionRawCell): RawTmsiReportItem|undefined{
  const {cell, timestamp} = rawCellItem;
  const tmsi = cell.rach_message['s-TMSI']['m-TMSI'];

  if(!tmsi) {return;}

  return {
    'DATA_TYPE': 'cell',
    'NETWORKPROVIDER': cell.downlink_info.networkProvider,
    'POWER_ESTIMATE': String(cell.rf_power_estimate),
    'TMSI': String(tmsi),
    'UNIX_TIMESTAMP': new Date(Number(timestamp)).toUTCString()
  };
};

export const processRawCellScanMqttToRawActivity = function(rawCellScanItem: RfVisionCellScan): RawActivityReportItem|undefined{
  const { timestamp} = rawCellScanItem;
  const cell = rawCellScanItem['cell-scan'];

  if(!cell){return;}

  return {
    'DATETIME': new Date(Number(timestamp)).toUTCString(),
    'NETWORK_PROVIDER': String(cell.network_provider),
    'NUM_RECORDS': 1
  };
};

export type CellItem = {
  type: string,
  networkProvider: string,
  powerEstimate: number,
  epochTimestamp: number,
  date: Date,
  timestamp: number
}

export const processRawCellScanMqttToCellItem = function(rawCellScanItem: RfVisionCellScan): CellItem|undefined{
  const { timestamp} = rawCellScanItem;
  const cell = rawCellScanItem['cell-scan'];
  const date = new Date(Number(timestamp));

  if(!cell){return;}

  return {
    type: 'cell-scan',
    networkProvider: getCellularProviderName(String(cell.network_provider))||'',
    powerEstimate: Number(cell.rf_power_estimate),
    epochTimestamp: (date.getTime())/1000,
    date: date,
    timestamp: date.getTime()
  };
};
