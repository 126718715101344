import {Fragment} from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import {NamedRoutes} from 'app';

export const Breadcrumbs: React.FC = () => {
  const breadcrumbs = useBreadcrumbs(NamedRoutes);

  return <Container>
  {breadcrumbs.map(({ breadcrumb, key }, i) => {

    return <Fragment key={key}>{i>0?'/':''} <Link to={key}>{breadcrumb}</Link> </Fragment>;
  })}
  </Container>;
};

const Container = styled.div`${({theme}) => `
  margin-top: -15px;
  margin-bottom: 15px;

  a:not(:hover) {
    color: ${theme.colors.ink.lighter};
  }
`}`;
