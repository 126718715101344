import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react';

import { getDatabase, ref, onValue, off } from 'firebase/database';

import {
  UserConfig,
  saveUserConfig
} from 'api';
import {
  useAuthContext
} from 'context';


export type ConfigContextType = {
  config: UserConfig|undefined;
  configLoaded: boolean;
  saveUserConfig: (config: UserConfig) => Promise<void>|undefined;
}
export const ConfigContext = createContext<ConfigContextType>(undefined!);

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigProvider: React.FC = ({
  children
}) => {
  const {fbUser} = useAuthContext();
  const [config, setConfig] = useState<ConfigContextType['config']>();
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);

  useEffect(function(){
    function processSnapsot(snapshot: any){
      if(snapshot.exists()){
        const val = snapshot.val();
        console.log('[ConfigProvider] got value', val);
        setConfig(val);
      }
    }

    if(fbUser?.uid){
      const userUid = fbUser?.uid;
      const db = getDatabase();
      const savedConfigRef = ref(db, 'userConfig/'+userUid);

      console.log('[ConfigProvider]', {savedConfigRef});

      onValue(savedConfigRef, snapshot => {
        console.log('[ConfigProvider] on user report value', {userUid, snapshot});
        processSnapsot(snapshot);
        setConfigLoaded(true);
      });

      return () => off(savedConfigRef);
    }
  }, [fbUser, setConfig, setConfigLoaded]);

  return <ConfigContext.Provider value={useMemo(() => ({
    config,
    saveUserConfig,
    configLoaded
  }),
    [
      config,
      configLoaded
    ])}>{children}</ConfigContext.Provider>;
};
