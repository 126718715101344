import {useEffect, useState, useRef, useMemo, useCallback} from 'react';

import {
  scaleTime,
  scaleLinear,

  axisBottom,
  axisRight,
  select,
  range,

} from 'd3';
import {
  startOfDay,
  endOfDay,

  startOfWeek,
  endOfWeek,

  startOfMonth,
  endOfMonth
} from 'date-fns';
import {
  format
} from 'date-fns-tz';
import styled from 'styled-components';

import {
  DataTable,
  InputGroup,
  Switchgroup,
  DatePickerIcon,
  Loading,
} from 'components';
import {
  NetworkProviderLabel,
  PROVIDERS_ARRAY,

  TMSILabelsMap,
  TMSILabelColorsMap
} from 'config';
import {
  SensorSelect
} from 'elements';
import {
  FlexContainer,
  Flex2,
  Flex5,
  Flex7,
  Flex10,
} from 'layout';
import {
  AmountOverDateGraph,
  useTmsiActivity,
  UseTmsiActivityPayload
} from 'pages';

// NOTE: ALL items are mutable since processed from raw server reply!

const LabelsMap = TMSILabelsMap;
const ColorsMap = TMSILabelColorsMap;

/// activity report based on TMSI data
export const TmsiActivity: React.FC = () => {

  const [sensorId, setSensorId] = useState<string>();

  const {
    sampledProviderTmsiActivity,
    loading,
    enabledProviders,
    setEnabledProviders,
    dateMode,
    setDateMode,
    dateRange,
    setDateRange,
    filteredTmsiReport,
    filteredByTmsiAndLabel,
    cutoffDate
  } = useTmsiActivity({
    sensorId,
    preserveByName: 'tmsiActivityPage'
  });

  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);
  const handleSensorSelect  = useCallback(function(sensorId:string){
    setSensorId(sensorId);
  },[]);
  return <>

    <Section id="tmsi-over-time">

      <Flex7>
        <h3>TMSIs Over Time</h3>
        <p>These graphs plot TMSIs we’ve seen over time.</p>

      </Flex7>
      <Flex5>
      <StyledSensorSelect
        onSelect={handleSensorSelect}
        preserveByName="tmsi-over-time"
        />
      </Flex5>

    </Section>

    <Section>

      <Flex10>
        <Switchgroup>
          <InputGroup
            reverse
            type="radio"
            name="sample-size"
            value="day"
            label="D"
            onChange={() => {
              setDateMode('day');
            }}
            checked={dateMode === 'day'}
            />
          <InputGroup
            reverse
            type="radio"
            name="sample-size"
            value="week"
            label="W"
            onChange={() => {
              setDateMode('week');
            }}
            checked={dateMode === 'week'}
            />
          <InputGroup
            reverse
            type="radio"
            name="sample-size"
            value="month"
            label="M"
            onChange={() => {
              setDateMode('month');
            }}
            checked={dateMode === 'month'}
            />
        </Switchgroup>

        <StyledDateSelection>
          <span>
            {format(startDate, 'MMM dd')}
            {(dateMode!=='day'&&endDate)?' - '+format(endDate, 'MMM dd'):null}
          </span>

          <DatePickerIcon
            mode={dateMode}
            onSelect={([dateStart, dateEnd]) => {
              setDateRange([dateStart, dateEnd||endOfDay(dateStart)]);
            }}
            preserveByName="tmsiActivityPage"
            />

        </StyledDateSelection>

      </Flex10>
      <Flex2 />


      <Flex5>

        <AmountOverDateGraph
          dataGroups={filteredTmsiReport}
          pathsCutoff={cutoffDate}
          width={800}
          height={390}
          labelToColorMap={ColorsMap}
          dateTicks={dateMode === 'day' ? 'hours' : dateMode === 'week' ? 'days' : dateMode === 'month' ? 'weeks' : 10}
          padding={{
            top: 25,
            right: 30,
            bottom: 40,
            left: 50
          }}
          startDate={startDate}
          endDate={endDate}
          numberOfVerticalTicks={7}
          numberOfBins={dateMode==='month'?20:7}
          itemizationTimeSample={dateMode==='month'||dateMode==='week'?'day':'hour'}
          label="# of TMSIs"
          >
            {loading?
              <Loading x="340" y="115" />:null}

            {!loading&&!filteredTmsiReport?.length?
              <g className="noData" style={{transform: 'translate(360px,130px)'}}>
                <svg width="94" height="86" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg" opacity="0.5">
                  <path d="M33.4932 55.4999H66.8265M37.6598 30.4999H37.7015M62.6598 30.4999H62.7015M17.8746 16.6582C25.5154 7.30472 37.1403 1.33325 50.1598 1.33325C73.1717 1.33325 91.8265 19.9881 91.8265 42.9999C91.8265 66.0118 73.1717 84.6666 50.1598 84.6666C27.148 84.6666 8.49316 66.0118 8.49316 42.9999C8.49316 40.8757 8.65212 38.7887 8.95879 36.75" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12.6602 12.1276L20.03 19.4974C21.4874 20.9539 22.4802 22.8099 22.8827 24.8308C23.2852 26.8516 23.0793 28.9464 22.2911 30.8502C21.5029 32.754 20.1677 34.3812 18.4546 35.5261C16.7414 36.671 14.7272 37.2821 12.6667 37.2821C10.6062 37.2821 8.59193 36.671 6.87877 35.5261C5.16561 34.3812 3.83049 32.754 3.04227 30.8502C2.25406 28.9464 2.04817 26.8516 2.45066 24.8308C2.85314 22.8099 3.84591 20.9539 5.30339 19.4974L12.6602 12.1276Z" stroke="currentColor" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <text y="115" x="22" fill="currentColor">no data</text>
              </g>
              : null}
        </AmountOverDateGraph>

      </Flex5>

      <Flex5>
        <ItemInTimeGraph
            width={700}
            data={!loading?filteredByTmsiAndLabel:[]}
            startDate={startDate}
            endDate={endDate}
            labelToColorMap={ColorsMap}
            label="TMSIs"
            >

            {loading?
              <Loading x="370" y="110" size={80} />:null}

            {!loading&&!filteredByTmsiAndLabel?.length?
            <g className="noData" style={{transform: 'translate(382px,110px)'}} opacity="0.3">
              <svg x="5" width="40" height="40" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.4932 55.4999H66.8265M37.6598 30.4999H37.7015M62.6598 30.4999H62.7015M17.8746 16.6582C25.5154 7.30472 37.1403 1.33325 50.1598 1.33325C73.1717 1.33325 91.8265 19.9881 91.8265 42.9999C91.8265 66.0118 73.1717 84.6666 50.1598 84.6666C27.148 84.6666 8.49316 66.0118 8.49316 42.9999C8.49316 40.8757 8.65212 38.7887 8.95879 36.75" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.6602 12.1276L20.03 19.4974C21.4874 20.9539 22.4802 22.8099 22.8827 24.8308C23.2852 26.8516 23.0793 28.9464 22.2911 30.8502C21.5029 32.754 20.1677 34.3812 18.4546 35.5261C16.7414 36.671 14.7272 37.2821 12.6667 37.2821C10.6062 37.2821 8.59193 36.671 6.87877 35.5261C5.16561 34.3812 3.83049 32.754 3.04227 30.8502C2.25406 28.9464 2.04817 26.8516 2.45066 24.8308C2.85314 22.8099 3.84591 20.9539 5.30339 19.4974L12.6602 12.1276Z" stroke="currentColor" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <text y="65" fill="currentColor">no data</text>
            </g>
            : null}

        </ItemInTimeGraph>
      </Flex5>

      <Flex2>
        <StyledProvidersChekboxes>
          {PROVIDERS_ARRAY.map(label =>
            <StyledInputGroup
              key={String(label)}
              variant="custom"
              type="checkbox"
              checked={enabledProviders[label]}
              reverse
              label={' '+(LabelsMap.get(label as NetworkProviderLabel)||label)}
              className=""
              color={ColorsMap.get(label as NetworkProviderLabel)|| 'black'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEnabledProviders({...enabledProviders, [label]:e.target.checked}); }}
              />
          )}
        </StyledProvidersChekboxes>
      </Flex2>
    </Section>

    {
      sampledProviderTmsiActivity?.length
        ?
      <Section id="activity-data-table">

        <Flex10>
          <h3>Sampled TMSI's volumes</h3>

          <DataTableContainer>
            <div className="roundedCorners">

              <DataTable
                data={ sampledProviderTmsiActivity }
                index={['date', 'items', 'powerRange', 'label']}
                dataFormating={{
                  items: v => v.length,
                  date: v => dateMode === 'day' ? format(v as Date, 'EEEE, MMMM do - h aaa'): format(v as Date, 'EEEE, MMMM do'),
                  label: v => LabelsMap.get(String(v) as NetworkProviderLabel),
                  powerRange: (v, item) => Math.round(item.powerMin) + ' dBm to ' + Math.round(item.powerMax) + ' dBm' + (v?'':'')
                }}
                titlesFormatting={{
                  items: () => <>Amount of Activity</>,
                  date: () => <>Time</>,
                  powerRange: () => <>Power Range</>,
                  label: () => <>Provider</>
                }}
                />

            </div>
          </DataTableContainer>
        </Flex10>
        <Flex2 />
      </Section>
        : null
    }
  </>;
};

export const TmsiActivityModule: React.FC<UseTmsiActivityPayload & {
  width?: number;
  minHeight?: number;
}> = ({
  width=700,
  minHeight=264,
  ...tmsipayload
}) => {

  const {
    loading,
    dateRange,
    filteredByTmsiAndLabel
  } = useTmsiActivity(tmsipayload);

  console.log('[tmsipayload]', tmsipayload);

  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  return <ItemInTimeGraph
    width={width}
    minHeight={minHeight}
    data={!loading?filteredByTmsiAndLabel:[]}
    startDate={startDate}
    endDate={endDate}
    labelToColorMap={ColorsMap}
    label="TMSIs"
    >

     {loading?
            <Loading x="370" y="110" size={80} />:null}

     {!loading&&!filteredByTmsiAndLabel?.length?
        <g className="noData" style={{transform: 'translate(382px,110px)'}} opacity="0.3">
          <svg x="5" width="40" height="40" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.4932 55.4999H66.8265M37.6598 30.4999H37.7015M62.6598 30.4999H62.7015M17.8746 16.6582C25.5154 7.30472 37.1403 1.33325 50.1598 1.33325C73.1717 1.33325 91.8265 19.9881 91.8265 42.9999C91.8265 66.0118 73.1717 84.6666 50.1598 84.6666C27.148 84.6666 8.49316 66.0118 8.49316 42.9999C8.49316 40.8757 8.65212 38.7887 8.95879 36.75" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.6602 12.1276L20.03 19.4974C21.4874 20.9539 22.4802 22.8099 22.8827 24.8308C23.2852 26.8516 23.0793 28.9464 22.2911 30.8502C21.5029 32.754 20.1677 34.3812 18.4546 35.5261C16.7414 36.671 14.7272 37.2821 12.6667 37.2821C10.6062 37.2821 8.59193 36.671 6.87877 35.5261C5.16561 34.3812 3.83049 32.754 3.04227 30.8502C2.25406 28.9464 2.04817 26.8516 2.45066 24.8308C2.85314 22.8099 3.84591 20.9539 5.30339 19.4974L12.6602 12.1276Z" stroke="currentColor" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

          <text y="65" fill="currentColor">no data</text>
        </g>
        : null}

  </ItemInTimeGraph>;
};

type ItemInTimeGraphPayload = {
  data: {
    items: {date: Date}[];
    value: string;
    label: string;
    powerMax?: number;
    powerMin?: number;
  }[];
  startDate: Date,
  endDate?: Date,
  dateMode?: 'day'|'week'|'month',
  width?: number;
  minHeight?: number;
  timeaxisHeight?: number;
  labelColWidth?: number;
  padding?: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  numberOfHorizontalTicks?: number;

  gridCellWidth?: number;
  //gridCellHeight?: number;

  labelToColorMap?: Map<string, string>;
  marksR?: number;

  lineHeigth?: number;

  label?: string;
}
const ItemInTimeGraph: React.FC<ItemInTimeGraphPayload> = ({
  data: passedData,
  startDate:passedStartDate = new Date(),
  endDate: passedEndDate,
  dateMode = 'day',
  width = 700,
  minHeight = 300,
  timeaxisHeight = 10,
  labelColWidth = 120,
  padding = {
    top: 10,
    right: 10,
    bottom: 0,
    left: 0
  },
  //gridCellHeight=50,

  labelToColorMap=new Map(),
  marksR=3,

  lineHeigth=20,

  numberOfHorizontalTicks=4,
  children,
  label
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const overlaySvgRef = useRef<SVGSVGElement>(null);
  const graphWidth = useMemo(() => width-padding.left-padding.right-labelColWidth, [width, padding, labelColWidth]);

  const dateExtent = useMemo(() => {
    if(passedStartDate&&passedEndDate){
      return [passedStartDate, passedEndDate];
    }
    else if(dateMode === 'month'){
      const startDate = startOfMonth(passedStartDate);
      const endDate = endOfMonth(passedStartDate);

      return [startDate, endDate];
    }
    else if(dateMode === 'week'){
      const startDate = startOfWeek(passedStartDate);
      const endDate = endOfWeek(passedStartDate);

      return [startDate, endDate];
    }

      const startDate = startOfDay(passedStartDate);
      const endDate = endOfDay(passedStartDate);

      return [startDate, endDate];


  },[dateMode, passedStartDate, passedEndDate]);
  const dateScaler = useMemo(() => scaleTime()
    .domain(dateExtent)
    .range([0, graphWidth])
  ,[dateExtent, graphWidth]);

  const [scale, setScale] = useState<number>(1);

  useEffect(function bindScaleUpdate(){
    let timeout: any;

    function updateScale(){
      clearTimeout(timeout);
      timeout = setTimeout(function(){
        if(!svgRef.current){ return; }
        const newScale = svgRef.current.getBoundingClientRect().width/width;
        setScale(newScale);
      },500);
    }
    window.addEventListener('resize', updateScale);
    updateScale();

    return function(){
      clearTimeout(timeout);
      window.removeEventListener('resize', updateScale);
    };
  },[setScale, width]);

  const height = useMemo(() => {
    return Math.max(Number((passedData.length+0.5)*lineHeigth), minHeight);
  },[
    passedData,
    lineHeigth,
    minHeight
  ]);

  useEffect(function drawDataOnPlot(){
    const graphWidth = width-padding.left-labelColWidth-padding.right;

    const group = select(svgRef.current)
      .selectAll('g.dataPlot')
      .data(['one'])
      .join(
        enter => enter.append('g').attr('class', 'dataPlot'),
        update => update,
        exit => exit.remove()
      )
      .attr('style', `transform:translate(${padding.left+labelColWidth}px,${padding.top}px)`);


    const row = group
      .selectAll('g.row')
      .data(passedData) // remove data on loading
      .join(
        enter => {
          const g = enter
            .append('g')
            .attr('class', 'row');

          g
            .append('text')
            .attr('class', 'tmsi');

          g
            .append('text')
            .attr('class', 'dbm');

          g
            .append('line');

          g
            .append('rect')
            .attr('height', '1em')
            .attr('y', '-0.5em')
            .attr('width', graphWidth)
            .attr('opacity', '0');

          return g;
        },
        update => update,
        exit => exit.remove()
      )
      // this is verticallly align to middle
      .attr('style', (d, i) => `transform:translate(0px,${((i+1)-0.5/*vertically align to middle*/)*lineHeigth}px); color:${labelToColorMap.get(d.label)||'currentColor'}`);

    // update label value
    row
      .select('text')
      .attr('dominant-baseline', 'middle')
      .attr('fill', 'currentColor');

    row
      .select('text.tmsi')
      .attr('x','-1em')
      .attr('text-anchor', 'end')
      .html(d=> d.value);

    row
      .select('text.dbm')
      .attr('x', graphWidth-5)
      .attr('text-anchor', 'end')
      .attr('style', 'font-size: 0.75em')
      .attr('opacity', 0.5)
      .html(d => d.powerMin && d.powerMax ? '-('+Math.round(d.powerMin)*-1 + '-' + Math.round(d.powerMax)*-1 + ') dbm' : '' );

    row
      .select('line')
      .attr('x1', '-0.5em')
      .attr('x2', graphWidth)
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .attr('opacity', 0.2);

    row
      .selectAll('circle')
      .data(d => d.items)
      .join(
        enter => enter.append('circle').lower(),
        update => update,
        exit => exit.remove()
      )
      .attr('r', marksR)
      .attr('fill', 'currentColor')
      .attr('cy', '0')
      .attr('cx', d => dateScaler(d.date));


  },[
    width,
    passedData,
    dateScaler,
    graphWidth,
    marksR,
    lineHeigth,
    labelColWidth,
    labelToColorMap,
    padding
  ]);

  useEffect(function drawAxis(){
    if(!svgRef.current){ return; }

    const svgBbrect = svgRef.current.getBoundingClientRect();
    const height = svgBbrect.height / (scale<1?scale:1);

    const graphWidth = (width-padding.left-padding.right-labelColWidth);
    const graphHeight = height-padding.top-padding.bottom;

    const verticalGridTicks = range(0, graphHeight, graphHeight/passedData.length );

    //const height
    const axisX = axisBottom(dateScaler)
      .ticks( numberOfHorizontalTicks );

    const verticalScaler = scaleLinear()
      .domain([0, Math.round(graphHeight)])
      .range([0, Math.round(graphHeight)]);

    const xAxisGrid = axisBottom(dateScaler)
      .tickSize(height-padding.top-padding.bottom) // length
      .tickFormat(() => '')
      .ticks(numberOfHorizontalTicks);

    const xAxisGridSub = axisBottom(dateScaler)
      .tickSize(height-padding.top-padding.bottom) // length
      .tickFormat(() => '')
      .ticks(numberOfHorizontalTicks*2);

      //.tickValues( horizonalGridTicks );
    const yAxisGrid = axisRight(verticalScaler)
      .tickSize( graphWidth )
      .tickFormat(() => '')
      .tickValues( verticalGridTicks );

    const axisGroup = select(svgRef.current)
      .selectAll('g.axis')
      .data(['one'])
      .join(
        enter => {
          return enter.append('g').attr('class','axis');
        },
        update => update,
        exit => exit.remove()
      )
      .attr('style',`transform: translate(${padding.left+labelColWidth}px, ${padding.top}px)`);

    // clear
    axisGroup
      .selectAll('*')
      .remove();

    axisGroup.append('g')
      .call(xAxisGrid);

    axisGroup.append('g')
      .call(yAxisGrid);

    axisGroup.append('g')
      .attr('class', 'sub')
      .call(xAxisGridSub);

    if(!overlaySvgRef.current){ return; }

    select(overlaySvgRef.current)
      .selectAll('g')
      .remove();

      select(overlaySvgRef.current)
      .append('g')
      .call(axisX)
      .attr('style',`transform: translate(${padding.left+labelColWidth}px, 0)`);

  },[
    height,
    width,
    padding,
    dateScaler,
    dateExtent,
    scale,
    labelColWidth,
    numberOfHorizontalTicks,
    passedData
  ]);


  return <StyledItemInTimeGraph className="itemInTimeGraph" height={`${minHeight}px`}>
    <div className="scrollField" style={{bottom: `${timeaxisHeight}px`}}>
      <svg className="canvas" ref={svgRef} width="100%" height={height*(scale<1?scale:1)} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMin meet">
        {children}
      </svg>
    </div>
    <svg className="fixedOverlay" ref={overlaySvgRef} width="100%" height={timeaxisHeight} viewBox={`0 0 ${width} ${timeaxisHeight}`} preserveAspectRatio="xMidYMin meet" overflow="visible">
      {label?
      <text
        className="label"
        style={{transform: 'rotate(-90deg)'}}
        y="-1.5em"
        x={minHeight/2}
        textAnchor="middle">{label}</text>: null}
    </svg>
  </StyledItemInTimeGraph>;
};

const StyledItemInTimeGraph = styled.div.attrs({} as {
  height: string;
})`${({theme, height}) => `
  height: ${height};
  margin-left: 30px;

  position: relative;

  .scrollField{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 0;
    margin: auto;

    overflow-x: scroll;
  }

  .fixedOverlay {
    position: absolute;
    bottom: 0;
    left:0;
  }

  .axis {
    opacity: 0.3
  }
  .sub {
    opacity: 0.3
  }

  text {
    color: ${theme.colors.ink.darkest};
  }

  svg.canvas {
    min-height: 100%;
  }

  .label {
    position: absolute;
    transform: rotate(-90deg);
  }

  svg .row:hover .dbm {
    opacity: 1;
  }

  svg .row:hover line {
    opacity: 0.5;
  }
`}`;


const DataTableContainer = styled.div`${({theme}) => `
  max-height: 200px;
  overflow-x: scroll;
  border-radius: 16px;
  border: 1px solid ${theme.colors.sky.base};

  table {
    border-spacing: 0px;
    border-collapse: collapse;
  }

  th {
    background-color: ${theme.colors.sky.lighter};
    font-weight: normal;
    text-align: left;
  }

  th, td {
    border: 1px solid ${theme.colors.sky.base};
    padding: ${theme.fontPadding.xxs}px ${theme.fontPadding.m}px;
    font-size: ${theme.fontSize.base}px;
  }

  table {
    width:101%;
    margin-left:-1px;
    margin-top:-1px;
    margin-bottom:-1px;
    margin-right:-1px;
  }

  table {
    position: relative;
  }

  th {
    position: sticky;
    top: -1px;
  }

`}`;

const StyledInputGroup = styled(InputGroup).attrs({} as {
  color: string;
})`${({color}) => `
  svg {
    color: ${color};
  }
`}`;

const StyledProvidersChekboxes = styled.div`${({theme}) => `
  display: inline-block;
  vertical-align: top;
  margin-left: ${theme.boxMargins.xl4}px;
  margin-top: 25px;
  text-align: left;
`}`;

const Section = styled(FlexContainer)`${({theme}) => `
  margin-top: ${theme.boxMargins.xl5}px;

  margin: ${theme.boxPadding.xl9}px ${theme.boxPadding.xl5}px;

  h3 {
    margin-bottom: ${theme.fontPadding.m}px;
  }

  .amountOverDateGraph {
    max-width: 100%;
    vertical-align: top;

  }

  .amountOverDateGraph {
    height: auto;
    margin-bottom: 15px;
  }



  @media (min-width: 570px) {
    .itemInTimeGraph {
      height: 100%;
    }
  }

  position: 'relative';
`}`;

const StyledDateSelection = styled.div`${({theme}) => `
  font-size: ${theme.fontSize.base}px;
  padding: ${theme.fontPadding.xxs}px 0;

  svg {
    vertical-align: middle;

    margin: ${theme.fontMargins.s}px;
  }
  > span {
    margin-right: ${theme.fontMargins.xs}px;
  }

  float: right;
`}`;

const StyledSensorSelect = styled(SensorSelect)`${() => `
  float: right;
`}`;
