import { apiRequest, ApiRequestData } from 'api';
import {
  AllDeviceData,
  APIResponse,
  OnboardingStep,
  UserPermission,
} from 'types';

export type UserResponseData = {
  uid: string;
  created_at: number;
  alpha_tester?: boolean;
  beta_accepted?: boolean;
  is_internal?: boolean;
  referral_source?: string;
  beta_requested?: boolean;
  demo?: boolean;
  devices?: Record<string, AllDeviceData | undefined>;
  email?: string;
  first_name?: string;
  last_name?: string;
  permissions?: UserPermission[];
  phone?: string;
  terms_accepted?: boolean;
  onboarding_steps?: Record<OnboardingStep, boolean | undefined>;
};
export type UpdateUserData = {
  alpha_tester?: boolean;
  beta_accepted?: boolean;
  is_internal?: boolean;
  referral_source?: string;
  beta_requested?: boolean;
  demo?: boolean;
  devices?: Record<string, AllDeviceData | undefined>;
  email?: string;
  first_name?: string;
  last_name?: string;
  permissions?: UserPermission[];
  phone?: string;
  terms_accepted?: boolean;
  onboarding_steps?: Record<OnboardingStep, boolean | undefined>;
};

export const updateUser = (
  uid: string,
  data: UpdateUserData,
  axiosConfig?: ApiRequestData,
) =>
  apiRequest<APIResponse<UserResponseData>>({
    service: 'accountManagementV3',
    path: `/user/${uid}`,
    method: 'PUT',
    data,
    ...axiosConfig,
  });

export const getUser = (uid: string) =>
  apiRequest<APIResponse<UserResponseData>>({
    service: 'accountManagementV3',
    path: `/user/${uid}`,
    method: 'GET',
  });
