export type API_ENVIRONMENT = 'dev' | 'staging' | 'prod';

//
// Configurations
//
const LOCAL = {
  dev: true,
  apiDebugging: true,
  acctMgmtApi: 'https://account-management-api.dev.homeaware.io',
  acctMgmtApiV3: 'https://account-management-api.dev.homeaware.io/v3',
  dataApiUrl: 'https://data-management-api.dev.homeaware.io',
  eckleburgApiUrl: 'https://eckleburg.dev.homeaware.io',
  geofenceApiUrl: 'https://geofence-api-dev.homeaware.io',

  googleApiKey: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',

  FIREBASE_API_KEY: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',
  FIREBASE_AUTH_DOMAIN: 'homeaware-dev.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-dev.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-dev',
  FIREBASE_STORAGE_BUCKET: 'homeaware-dev.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '962188949377',
  FIREBASE_APP_ID: '1:962188949377:web:9fb9dfe6b35317cbd45a80',
  FIREBASE_MEASUREMENT_ID: 'G-RYDWYC4089',

  AWS_IDENTITY_POOL_ID: 'us-east-1:6dadea80-eb88-47f8-8099-90f3505400fb',
  AWS_REGION: 'us-east-1',
  AWS_USER_POOL_ID: 'us-east-1_SUpGGjAON',
  AWS_USER_POOL_WEB_CLIENT_ID: '66av11jcono7red88s26ip2i7i',
  AWS_PUBSUB_ENDPOINT:
    'wss://a350em07hnb5k4-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '170448041685',

  // assorted & links
  'tmsi_law_enforcement_link': '/downloads/tmsi_law_enforcement_guide.pdf'
};

const DEVELOPMENT = {
  dev: true,
  apiDebugging: true,
  acctMgmtApi: 'https://account-management-api.dev.homeaware.io',
  acctMgmtApiV3: 'https://account-management-api.dev.homeaware.io/v3',
  dataApiUrl: 'https://data-management-api.dev.homeaware.io',
  eckleburgApiUrl: 'https://eckleburg.dev.homeaware.io',
  geofenceApiUrl: 'https://geofence-api-dev.homeaware.io',

  googleApiKey: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',

  FIREBASE_API_KEY: 'AIzaSyC2YlVG-E2dRs2SvKY73Hke4HpvfzQJPyk',
  FIREBASE_AUTH_DOMAIN: 'homeaware-dev.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-dev.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-dev',
  FIREBASE_STORAGE_BUCKET: 'homeaware-dev.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '962188949377',
  FIREBASE_APP_ID: '1:962188949377:web:9fb9dfe6b35317cbd45a80',
  FIREBASE_MEASUREMENT_ID: 'G-RYDWYC4089',

  AWS_IDENTITY_POOL_ID: 'us-east-1:6dadea80-eb88-47f8-8099-90f3505400fb',
  AWS_REGION: 'us-east-1',
  AWS_USER_POOL_ID: 'us-east-1_mLSJAP5lz',
  AWS_USER_POOL_WEB_CLIENT_ID: '5qq6vist52rv2eab1tjnghbnnl',
  AWS_PUBSUB_ENDPOINT:
    'wss://a350em07hnb5k4-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '',

  // assorted & links
  'tmsi_law_enforcement_link': '/downloads/tmsi_law_enforcement_guide.pdf'
};
const STAGING = {
  dev: true,
  apiDebugging: true,
  acctMgmtApi: 'https://account-management-api.staging.homeaware.io',
  acctMgmtApiV3: 'https://account-management-api.staging.homeaware.io/v3',
  dataApiUrl: 'https://data-management-api.staging.homeaware.io',
  eckleburgApiUrl: 'https://eckleburg.staging.homeaware.io',
  geofenceApiUrl: 'https://geofence-api-staging.homeaware.io',
  googleApiKey: 'AIzaSyAFuTWrgKu6ddgQ87WZBW90LY6tR29hR-M',

  FIREBASE_API_KEY: 'AIzaSyAFuTWrgKu6ddgQ87WZBW90LY6tR29hR-M',
  FIREBASE_AUTH_DOMAIN: 'homeaware-staging-2a67e.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-staging-2a67e.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-staging-2a67e',
  FIREBASE_STORAGE_BUCKET: 'homeaware-staging-2a67e.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '67155199558',
  FIREBASE_APP_ID: '1:67155199558:web:bc2b0df410726801f4ac47',
  FIREBASE_MEASUREMENT_ID: 'G-NHX83N3SC2',

  AWS_IDENTITY_POOL_ID: 'us-east-1:19bc3d01-0e9d-4b2b-a02e-1ce025639889',
  AWS_REGION: 'us-east-1',
  AWS_USER_POOL_ID: 'us-east-1_mLSJAP5lz',
  AWS_USER_POOL_WEB_CLIENT_ID: '5qq6vist52rv2eab1tjnghbnnl',
  AWS_PUBSUB_ENDPOINT:
    'wss://a1wnskyxowv6qs-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '',

  // assorted & links
  'tmsi_law_enforcement_link': '/downloads/tmsi_law_enforcement_guide.pdf'
};
const PRODUCTION = {
  dev: false,
  apiDebugging: false,
  acctMgmtApi: 'https://account-management-api.homeaware.io',
  acctMgmtApiV3: 'https://account-management-api.homeaware.io/v3',
  dataApiUrl: 'https://data-management-api.homeaware.io',
  eckleburgApiUrl: 'https://eckleburg.homeaware.io',
  geofenceApiUrl: 'https://geofence-api.homeaware.io',
  googleApiKey: 'AIzaSyAK6nXXJw2P2yhKNMJr1C_r7n90mcni-GM',

  FIREBASE_API_KEY: 'AIzaSyAK6nXXJw2P2yhKNMJr1C_r7n90mcni-GM',
  FIREBASE_AUTH_DOMAIN: 'homeaware-prod.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://homeaware-prod.firebaseio.com',
  FIREBASE_PROJECT_ID: 'homeaware-prod',
  FIREBASE_STORAGE_BUCKET: 'homeaware-prod.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '59544232385',
  FIREBASE_APP_ID: '1:59544232385:web:d71f484a00a44e9e500f40',
  FIREBASE_MEASUREMENT_ID: 'G-7JKFHMGNRX',
  //
  // AWS_IDENTITY_POOL_ID: 'us-east-1:d4da69ce-05ed-44a6-9d0e-fe4e43933b71',
  // AWS_REGION: 'us-east-1',
  // AWS_USER_POOL_ID: 'us-east-1_mLSJAP5lz',
  // AWS_USER_POOL_WEB_CLIENT_ID: '5qq6vist52rv2eab1tjnghbnnl',
  // AWS_PUBSUB_ENDPOINT:
  //   'wss://a229utg519v7kg-ats.iot.us-east-1.amazonaws.com/mqtt',
  // AWS_ACCOUNT: '',

  AWS_IDENTITY_POOL_ID: 'us-east-1:ad6d302f-6983-4439-8ee3-8eaad70f4e0e',
  AWS_REGION: 'us-east-1',
  AWS_USER_POOL_ID: 'us-east-1_mLSJAP5lz',
  AWS_USER_POOL_WEB_CLIENT_ID: '5qq6vist52rv2eab1tjnghbnnl',
  AWS_PUBSUB_ENDPOINT:
    'wss://a3c3u7bh3lrc3d-ats.iot.us-east-1.amazonaws.com/mqtt',
  AWS_ACCOUNT: '',

  // assorted & links
  'tmsi_law_enforcement_link': '/downloads/tmsi_law_enforcement_guide.pdf'
};

const returnConfigData = (apiEnv: API_ENVIRONMENT) => {
  if (!process.env.REACT_APP_ENVIRONMENT && process.env.NODE_ENV === 'development') {
    return LOCAL;
  }

  // All othe cases
  switch (apiEnv) {
    case 'dev':
      return DEVELOPMENT;
    case 'staging':
      return STAGING;
    case 'prod':
      return PRODUCTION;
    default:
      return DEVELOPMENT;
  }
};

//
//Amplify
//
// const AWS_AMPLIFY = {
//   AWS_IDENTITY_POOL_ID: 'us-east-1:d4da69ce-05ed-44a6-9d0e-fe4e43933b71',
//   AWS_REGION: 'us-east-1',
//   AWS_USER_POOL_ID: 'us-east-1_mLSJAP5lz',
//   AWS_USER_POOL_WEB_CLIENT_ID: '5qq6vist52rv2eab1tjnghbnnl',
//   AWS_PUBSUB_ENDPOINT:
//     'wss://a229utg519v7kg-ats.iot.us-east-1.amazonaws.com/mqtt',
// };

const API_KEYS = {
  X_API_KEY: '204dc991-aef5-49cd-ba0e-1bdc9150610b',
};

const environment =
  (process.env.REACT_APP_ENVIRONMENT as API_ENVIRONMENT | undefined) ?? 'dev';

export const ENV = {
  VERSION: '3.3.0',
  environment,
  //...AWS_AMPLIFY,
  ...API_KEYS,

  // ...DEVELOPMENT,
  // ...STAGING,
  // ...PRODUCTION,
  ...returnConfigData(environment),
};
