export const boxMargins = {
  xs: 2,
  s: 3,
  m: 5,
  base: 10,
  l: 15,
  xl: 20,
  xl2: 25,
  xl3: 30,
  xl4: 35,
  xl5: 40,
  xl6: 45,
  xl7: 50,
  xl8: 55,
  xl9: 60,
  xl10: 70,
  xl11: 80
};

export const boxPadding = {
  xs: 2,
  s: 3,
  m: 5,
  base: 10,
  l: 14,
  xl: 20,
  xl2: 25,
  xl3: 30,
  xl4: 35,
  xl5: 40,
  xl6: 45,
  xl7: 50,
  xl8: 55,
  xl9: 60,
  xl10: 70,
  xl11: 80
};