import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from 'react';

import {
  SelectProps,
  InputGroup
} from 'components';
import {
  ENV
} from 'config';
import {
  useGraphQLContext
} from 'context';
import {
  perSessionStorage
} from 'storage';

const LOCAL_STORAGE_PREFIX = 'sensor-select-'; // resetting it on each user session //localStorage

export const SensorSelect: React.FC<{
  onSelect?: (sensor: string) => void,
  variant?: SelectProps['variant'],
  className?: string,
  preserveByName?: string // name to preserve selected value by in local storage
}> = ({
  onSelect,
  variant='normal',
  className,
  preserveByName
}) =>{

  const {sensorArray} = useGraphQLContext();
  const [sensorId, setSensorId] = useState<string>();

  const activeSensors = useMemo(
    () => sensorArray.filter(sensor => sensor.active),
    [sensorArray]);

  const mappedSensors = useMemo(() => {
    const sensors: any = {};

    console.log('[SensorSelect] filteredSensors are', activeSensors);

    activeSensors.forEach(sensor => {
      sensors[sensor.uid] = sensor.uid + ' - ' + sensor.name;
    });

    if(ENV.environment === 'dev'){
      sensors[12290] = '(dev) 12290 - Demohouse';
    }

    return sensors;

  }, [activeSensors]);

  const handleSensorSelect = useCallback(function handleSensorSelect(e:React.ChangeEvent<HTMLSelectElement>){
    const value = e.target.value;

    if(preserveByName){
      perSessionStorage.setItem(LOCAL_STORAGE_PREFIX+preserveByName, value);
    }

    onSelect?.(value);
    setSensorId(value);
  },[setSensorId, onSelect, preserveByName]);

  // select default of preserved on values change
  const prevByNameRef = useRef<any>('not-initiated');
  useEffect(function pullDataFromLocalOnInitStorage(){
    // return if value is already set or preserveByName has not changed and/or we are pass the first run
    if(sensorId || (preserveByName && prevByNameRef.current === preserveByName)){ return; }

    // get from storage if preserveby name changed or use sensorId
    const preserved = preserveByName && perSessionStorage.getItem(LOCAL_STORAGE_PREFIX+preserveByName);
    const defaultVal = Object.keys(mappedSensors)[0];

    prevByNameRef.current = preserveByName;

    console.log('[SensorsSelect] pullDataFromLocalOnInitStorage:', {
      mappedSensors,
      preserved,
      preserveByName,
      defaultVal
    });

    // if there is a preserved value and sensor exist
    if(preserved && mappedSensors[preserved] ){
      onSelect?.(preserved);
      setSensorId(preserved);

    // select default
  } else if(defaultVal){
      const value = defaultVal;
      onSelect?.(value);
      setSensorId(value);
    }
  }, [
    preserveByName,
    mappedSensors,
    onSelect,
    sensorId
  ]);

  return <InputGroup
    variant={variant}
    type="select"
    values={mappedSensors}
    value={sensorId}
    onChange={handleSensorSelect}
    className={className}
    />;
};
