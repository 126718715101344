import { useMemo, useRef, useState, useEffect, useCallback } from 'react';

import {
  FiChevronDown,
  FiChevronUp
} from 'react-icons/fi';
import styled from 'styled-components';

export type SelectBoxProps = {
  title?: string,
  values: any[],
  mapper?: (value:any) => string,
  defaultValue?: any,
  value?: any,
  onSelect?: (value: any) => void,
  search?: boolean;
  tabIndex?: number;
}
export const SelectBox: React.FC<SelectBoxProps> = ({
  title,
  defaultValue,
  value:passedValue,
  values,
  mapper= value => String(value),
  onSelect,
  search:hasSearch= true,
  tabIndex
}) => {

  const [value, setValue] = useState<any>(defaultValue||values[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const localPropagationBlockedRef = useRef<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const valueBoxRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [optionsBoxMaxHeight, setoptionsBoxMaxHeight] = useState<number>();

  const buttonDispacherRef = useRef<(e:KeyboardEvent) => void>(()=>{/**/});


  useEffect(function forcePassedValue(){
    if(passedValue !== undefined){
      setValue(passedValue);
    }
  },[passedValue]);

  const filteredValues = useMemo(function(){

    if(!searchValue){ return values; }

    const matchString = new RegExp(String(searchValue).replace(/([.+*?^$()[\]{}|\\])/g,'\\$1'), 'i');
    return values.filter(v => mapper(v).match(matchString));

  },[searchValue, values, mapper]);

  const makeActive = useCallback(function makeActive(){
    setActive(true);
    if(hasSearch && searchRef.current){
      searchRef.current.focus();
    }
  },[setActive, hasSearch]);

  const makeInactive = useCallback(function makeActive(){
    setActive(false);
    setSelectedItem(null);
    setSearchValue('');
    if(hasSearch && searchRef.current){
      searchRef.current.blur();
    }
  },[setActive, hasSearch]);

  const selectPrev = useCallback(function(){
    const currentInTheList = filteredValues.indexOf(selectedItem);
    if(currentInTheList > -1){
      setSelectedItem( filteredValues[currentInTheList-1>0?currentInTheList-1:0] );
    }
  }, [filteredValues, selectedItem]);

  const selectNext = useCallback(function(){
    const currentInTheList = filteredValues.indexOf(selectedItem);
    if(currentInTheList > -1){
      setSelectedItem( filteredValues[currentInTheList+1<filteredValues.length?currentInTheList+1:0] );
    } else {
      setSelectedItem( filteredValues[0] );
    }
  }, [filteredValues, selectedItem]);

  const selectItem = useCallback(function(value){
    onSelect?.(value);
    setValue(value);
    setSelectedItem(null);
    setSearchValue('');
    makeInactive();
  },[onSelect, makeInactive]);

  const handleReturn = useCallback(function(){
    if(selectedItem && filteredValues.indexOf(selectedItem) > -1) {
      selectItem(selectedItem);
    }
    else if(filteredValues.length > 0){
      selectItem(filteredValues[0]);
    }

  },[selectedItem, filteredValues, selectItem]);

  useEffect(function assignDispatcherRef(){
    buttonDispacherRef.current = function switchEvent(e){

      if(!active){ return; }

      e.stopPropagation();

      switch(e.key){
        case 'ArrowDown':
          e.preventDefault();
          selectNext();
          break;
        case 'ArrowUp':
          e.preventDefault();
          selectPrev();
          break;
        case 'Escape':
          setSelectedItem(null);
          break;
        case 'Enter':
          handleReturn();
          break;
        default:
          break;
      }
    };
  },[selectPrev, selectNext, selectedItem, selectItem, active, handleReturn]);

  useEffect(function addWindowListeners(){
    const windowClick = function(){
      if(!localPropagationBlockedRef.current){
        makeInactive();
      } else {
        // release propagation blocked
        localPropagationBlockedRef.current = false;
      }
    };

    const keydown = function(e:KeyboardEvent){
      buttonDispacherRef.current(e);
    };

    window.addEventListener('click', windowClick);
    window.addEventListener('keydown', keydown);

    return function clearOutListeners(){
      window.removeEventListener('click', windowClick);
      window.removeEventListener('keydown', keydown);
    };
  },[makeInactive]);

  // set options boundaries
  useEffect(function setOptionsBoxSize(){
    const getAndSetMax = function getAndSetMax(){
      if(!valueBoxRef.current){ return; }
      const windowHeight = window.innerHeight;

      const bb = valueBoxRef.current.getBoundingClientRect();

      const heightLimit = windowHeight-bb.top-bb.height;

      setoptionsBoxMaxHeight(heightLimit);
    };

    window.addEventListener('resize', getAndSetMax);
    window.addEventListener('scroll', getAndSetMax);

    return function(){
      window.removeEventListener('resize', getAndSetMax);
      window.removeEventListener('scroll', getAndSetMax);
    };
  },[setoptionsBoxMaxHeight]);

  // useEffect(function clearSelectedIfoutOfView(){
  //   const indexOfSelected =
  // },[filteredValues])

  return <StyledSelectBox
    className="selectBox"
    hasSearch={hasSearch}
    optionsBoxMaxHeight={optionsBoxMaxHeight}
    >
    <div className="header">
      {title? <p>{title}</p> : null}
      <div ref={valueBoxRef} className={`valueBox ${active?'active':''} ${searchValue?' inSearch':''}`} onClick={() => {
          if(!active){
            localPropagationBlockedRef.current = true;
            makeActive();
          }
        }}>
        <span>{mapper(value)}</span>
        {hasSearch ?
          <input
            tabIndex={tabIndex}
            ref={searchRef}
            value={searchValue}
            placeholder={mapper(value)}
            onChange={(e) => setSearchValue(e.target.value)}
            />
          : null}
        {active
          ?
          <FiChevronUp />
          :
          <FiChevronDown />
        }
      </div>
    </div>

    <div className="optionsBox">
      {active?
        <ul>
        { filteredValues.map( (v) => {
          const mv = mapper(v);

          return <li key={mv} className={(v === value ? 'current' : '')+(v === selectedItem ? ' selected' : '')} onClick={() => selectItem(v)}>{ mv }</li>;

        })}
        </ul>
        : null
      }
    </div>

  </StyledSelectBox>;
};

const StyledSelectBox = styled.div.attrs({} as {
  hasSearch: boolean,
  optionsBoxMaxHeight: number
})`${({theme, hasSearch, optionsBoxMaxHeight}) => `

  /* reset */
  p, u, ul, li {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    margin: 0;

  }

  line-height: ${theme.fontPadding.xl5}px;

  li, input, span {
    line-height: inherit;
  }

  li, input, .valueBox {
    padding: 0 ${theme.fontPadding.xxs}px;
  }

  li {
    text-align: left;
  }

  position: relative;
  display: inline-block;

  input {
    border: none;
    outline: none;
  }
  .valueBox {
    cursor: pointer;
    background-color: inherit;
  }

  /* make fonts and padding the same */
  .valueBox span,
  .valueBox input,
  .valueBox input::placeholder{
    font-size: ${theme.fontSize.base}px;
    color: black;
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: transparent;
  }
  svg {
    vertical-align: middle;
    z-index: 2;
    position: relative;
    top: -1px;
    margin-left: ${theme.boxMargins.s}px;
    pointer-events: none;

    opacity: 1;
    transition: opacity 100ms;
  }

  .inSearch svg {
    opacity: 0;
  }

  .valueBox {
    position: relative;
  }
  .valueBox:not(.active) input{
    cursor: pointer;
  }
  ${hasSearch ? `
    .valueBox span {
      visibility: hidden;
    }
  ` : ''}

  .optionsBox {
    position: absolute;
    top: 100%;
    min-width: 100%;
    ${optionsBoxMaxHeight !== undefined ? `max-height: ${optionsBoxMaxHeight}px;` : ''}
    overflow-y: auto;
    overflow-x: hidden;

  }
  .optionsBox {
    background-color: ${theme.colors.sky.white};
    box-shadow: 0px 0px 5px grey;
  }
  li {
    cursor: pointer;

  }

  li:not(:first-child){
    border-top: 1px solid ${theme.colors.sky.light};
  }

  .selected {
    background-color: rgba(${theme.colorsRgb.primary.light},0.9);
    color: ${theme.colors.sky.white}
  }
`}`;
