import {useEffect, useState, useRef, useCallback, useMemo} from 'react';

import {
    extent,
    scaleTime,
    scaleLinear,

    axisBottom,
    axisLeft,
    axisRight,
    axisTop,

    path,
    select,

    Path,
    range,

} from 'd3';
import {
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,

    startOfHour,

    startOfDay
} from 'date-fns';
import {
    format
} from 'date-fns-tz';

type AmountOverDateGraphPayload = {
    dataGroups: {
      label: string;
      items: {amount: number, date:Date}[]
    }[] | undefined;
    pathsCutoff?: Date|number;
    width: number;
    height: number;
    padding?: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    }
    numberOfBins?: number;
    startDate?: Date;
    endDate?: Date;
    labelToColorMap?: Map<string, string>;
    dateTicks?: 'hours'|'days'|'weeks'|'month'|number;
    numberOfVerticalTicks?: number;
    gridCellWidth?:number;
    gridCellHeight?:number;

    itemizationTimeSample?: 'day'|'hour'

    amountOverDateGraphRef?: React.MutableRefObject<SVGSVGElement>,

    label?: string;

  }
  type DateAmountBin = {
    // array like object containing
    [key: number]: any,
    date: number;
    amount: number;
    // catmulRom handles
    handleF: {
      date: number,
      amount: number
    }
    handleB: {
      date: number,
      amount: number
    }
  };
  type ValuesByDaysItem = {
    timeRefTimestamp: number,
    values: {
      // label/amount value
      [key: string]: number
    }
  }
  export const AmountOverDateGraph: React.FC<AmountOverDateGraphPayload> = ({
    dataGroups,
    width,
    height,
    padding={
      top: 30,
      right: 30,
      bottom: 50,
      left: 50
    },
    numberOfBins=25,
    startDate,
    endDate,
    labelToColorMap= new Map(),
    dateTicks='days',
    numberOfVerticalTicks,
    gridCellWidth=100,
    gridCellHeight=100,

    pathsCutoff, // date to cutoff path at

    itemizationTimeSample='hour',

    label,

    children,


  }) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [binnedData, setBinnedData] = useState<{
      label: string;
      bins: DateAmountBin[]
    }[]>();

    const [allItems, setAllItems] = useState<any[]>();
    // items amount by specified timesample
    const groupValuesByTimesampleRef = useRef<{[key: number]: ValuesByDaysItem}>();

    const amountMaxRef = useRef<number>(0);

    const [svgRefBbBox, setSvgRefBbBox] = useState<DOMRect>();

    // BIN INDEXED DATA FOR PLOTTING
    // group indexed data into bins
    useEffect(function binPassedData(){
      if(!dataGroups||!dataGroups.length){
        setBinnedData(undefined);
        setAllItems(undefined);
        amountMaxRef.current = 0;

        return;
      }

      amountMaxRef.current = 0;

      // INDEX GROUPED ITEMS
      const allItems: any[] = [];
      const newGroupValuesByTimesampleIndex: {
        [key: number]: ValuesByDaysItem
      } = {};
      // push to all and create daily index
      dataGroups.forEach(({items, label}) => {
        items.forEach(item => {
          allItems.push(item);
          const timeRefTimestamp = itemizationTimeSample === 'day'
            ?
            Number(startOfDay(item.date))
            :
            itemizationTimeSample === 'hour'
            ?
            Number(startOfHour(item.date))
            :
            0;

          if(!newGroupValuesByTimesampleIndex[timeRefTimestamp]){
            newGroupValuesByTimesampleIndex[timeRefTimestamp] = {
              timeRefTimestamp,
              values: {}
            };
          }

          const valuesByDayItem = newGroupValuesByTimesampleIndex[timeRefTimestamp];
          valuesByDayItem.values[label] = valuesByDayItem.values[label] ? valuesByDayItem.values[label]+item.amount : item.amount;
        });
      });

      const allItemsExtent = startDate && endDate ? [Number(startDate), Number(endDate)] : extent(allItems, d => Number(d.date));

      const startTimestamp = Number(allItemsExtent[0]);
      const endTimestamp = Number(allItemsExtent[1])+1;

      console.log('[AmountOverDateGraph] extent is', allItemsExtent);

      const newBinnedData = dataGroups.map( ({label, items}) => {

        const binned: any[] = ([...Array(numberOfBins)]).map(() => []);
        const step: number = (endTimestamp-startTimestamp)/binned.length;

        items.forEach(item => {
          //allItems.push(item);
          const binNumber = Math.floor((Number(item.date)-startTimestamp)/step);

          if(Array.isArray(binned[binNumber])){
            binned[binNumber].push(item);
          }

        });

        //const filteredBinned = binned.filter(d => d.length > 0);
        const mappedBinned = binned.map((d: any, i) => {
          //d.powerEstimate = d3.mean(d, d=> Number(d.powerEstimate));

          const largest = d.reduce( (max:any, item:any) =>
            (item.amount > max.amount) ? item : max
          , {amount:0});

          d.amount = largest.amount;
          d.date = new Date( startTimestamp + step*i+step/2);

          // increase max volume if less than current
          if(amountMaxRef.current < d.amount){
            amountMaxRef.current = d.amount;
          }
          return d;
        });

        // calculate camulRom handles for binnned data
        // const tens = 0.7 * 12;
        // for(let i=0; i<mappedBinned.length-1;i++){
        //   const d0 = mappedBinned[ i-1 >= 0 ? i-1 : i ];
        //   const d1 = mappedBinned[ i ];
        //   const d2 = mappedBinned[ i + 1 ];
        //   const d3 = mappedBinned[ i + 2 < mappedBinned.length ? i + 2 : i + 1];

        //   const d1F = {
        //     date: Number(d1.date) + ( Number(d2.date)-Number(d0.date) )/tens,
        //     amount: d1.amount === 0 ? 0 : // prevent rounding below floor values
        //       d1.amount + ( d2.amount-d0.amount )/tens
        //   }

        //   const d2B = {
        //     date: Number(d2.date) - ( Number(d3.date)-Number(d1.date) )/tens,
        //     amount: d1.amount === 0 && d2.amount === 0 ? 0 : //prevent rounding below floor values
        //       d2.amount - ( d3.amount-d1.amount )/tens
        //   };

        //   d1.handleF = {...d1F};
        //   d2.handleB = {...d2B};
        // }
        const handleLength = ((endTimestamp-startTimestamp)/numberOfBins)*0.5;

        for(let i=0; i<mappedBinned.length;i++){
          const d1 = mappedBinned[ i ];

          d1.handleF = {
            date: Number(d1.date) + handleLength,
            amount: d1.amount
          };

          d1.handleB = {
            date: Number(d1.date) - handleLength,
            amount: d1.amount
          };
        }

        return {label, bins: mappedBinned};
      });
      setBinnedData(newBinnedData);
      setAllItems(allItems);
      groupValuesByTimesampleRef.current = newGroupValuesByTimesampleIndex;
      console.log('[AmountOverDateGraph] binned reports', {newBinnedData, allItems, newGroupValuesByTimesampleIndex});
    }, [
      dataGroups,
      endDate,
      itemizationTimeSample,
      numberOfBins,
      startDate
    ]);

    const dateExtent = useMemo(() => startDate && endDate
        ? [startDate, endDate]
        : allItems
          ? extent(allItems, (d:any) => d.date)
          // adding generic extent to draw empty grid
          : null,
      [startDate, endDate, allItems]);

    const timeScaler = useMemo(() => scaleTime()
          .domain(dateExtent||[0,1])
          .range([0, width-padding.left-padding.right]),

      [dateExtent, width, padding]);

    const amountScaler = useMemo( () => scaleLinear()
        .domain(binnedData?.length ? [amountMaxRef.current, 0]: [1,0])
        .range([0, height-padding.top-padding.bottom]),

    [height, padding, binnedData]);

    useEffect(function drawGrid(){
      const svg = select(svgRef.current);
      if(!svg) {return;}

      console.log('[AmountOverDateGraph:addTmsiAxis] svg is', svg);

      // calculate number of ticks
      const gridHorizontalTicks = Math.round((width-padding.left-padding.right)/gridCellWidth);
      const gridVerticalTicks = Math.round((height-padding.top-padding.bottom)/gridCellHeight);

      let numberOfHorizontalTicks;
      if(typeof dateTicks === 'number'){
        numberOfHorizontalTicks = dateTicks;
      }
      else if(dateExtent){
        switch(dateTicks){
          case 'hours':
            numberOfHorizontalTicks = 4;//differenceInHours(dateExtent[1], dateExtent[0]);
            break;
          case 'days':
            numberOfHorizontalTicks = differenceInDays(dateExtent[1], dateExtent[0]);
            break;
          case 'weeks':
            numberOfHorizontalTicks = differenceInWeeks(dateExtent[1], dateExtent[0]);
            break;
          case 'month':
            numberOfHorizontalTicks = differenceInMonths(dateExtent[1], dateExtent[0]);
            break;
          default:
            break;
        }
      }

      // Axis & Grid
      // create axis generator
      const axisX = axisBottom(timeScaler)
        .ticks( numberOfHorizontalTicks );

      let axisY = axisLeft(amountScaler);
      if(numberOfVerticalTicks && amountMaxRef.current){
        axisY = axisY
          .tickValues( range(0, amountMaxRef.current, amountMaxRef.current/(numberOfVerticalTicks-1)) );
      }

      const xAxisGrid = axisTop(timeScaler)
        .tickSize(height-padding.top-padding.bottom) // length
        .tickFormat(() => '')
        .ticks( gridHorizontalTicks );

      const yAxisGrid = axisRight(amountScaler)
        .tickSize(width-padding.left-padding.right)
        .tickFormat(() => '')
        .ticks( gridVerticalTicks );

      // create am axis elements group and attach a call function on it
      svg
        .selectAll('g.axisGroup')
        .data(['one'])
        .join(
          enter =>
            enter
              .append('g')
              .lower()
              .attr('class', 'axisGroup'),
          update => update,
          exit => {
            exit.remove();
          }
        )
        .selectAll('*')
        .remove();


      const axisGroup = svg
        .select('.axisGroup');

      axisGroup.append('g')
        .call(xAxisGrid)
        .attr('transform', `translate(${padding.left}, ${height-padding.bottom})`)
        .attr('color', '#e7e7e7');

      axisGroup.append('g')
        .call(yAxisGrid)
        .attr('transform', `translate(${padding.left}, ${padding.top})`)
        .attr('color', '#e7e7e7');

      axisGroup.append('g')
        .call(axisX)
        .attr('transform', `translate(${padding.left}, ${height - padding.bottom + 15})`);

      axisGroup.append('g')
        .call(axisY)
        .attr('transform', `translate(${padding.left - 15}, ${padding.top})`);

    }, [
      dataGroups,
      amountScaler,
      dateExtent,
      timeScaler,
      numberOfVerticalTicks,
      dateTicks,
      gridCellHeight,
      gridCellWidth,
      height,
      padding.bottom,
      padding.left,
      padding.right,
      padding.top,
      width
    ]);

    // create path objects
    const pathMappedGroups = useMemo<{
        path: Path
        label: string
      }[]|undefined>( () => binnedData?.map( (group) => {

      const {bins, label} = group;
      const tp = path();

      let prevBin:DateAmountBin;

      const cutoff = pathsCutoff ? new Date(pathsCutoff).getTime() : null;

      bins.forEach((d,i) => {
        const datetime = Number(d.date);
        if(cutoff && datetime > cutoff){return;}
        const targetX = timeScaler( datetime );
        const targetY = amountScaler( d.amount );

        if(i===0){
          tp.moveTo(0, targetY);
          tp.bezierCurveTo(
            0, targetY,
            targetX, targetY,
            targetX, targetY);

        } else {

          tp.bezierCurveTo(
            timeScaler(prevBin.handleF.date), amountScaler(prevBin.handleF.amount),
            timeScaler(d.handleB.date), amountScaler(d.handleB.amount),
            targetX, targetY);
        }

        prevBin = d;
      });

      return {
        path: tp,
        label
      };

    }), [timeScaler,amountScaler,binnedData, pathsCutoff]);

    useEffect(function drawDataOntoSvg(){

      const svg = select(svgRef.current);

      svg
        .selectAll('svg.paths')
        .data(['one'])
        .join(
          enter => enter.append('svg').attr('class','paths'),
          update => update,
          exit => exit.remove()
        )
        //.attr('transform', `translate(${padding.left},${padding.top})`)
        .attr('x', padding.left)
        .attr('y', padding.top)
        .attr('width', width-padding.left-padding.right)
        .attr('height', height-padding.top-padding.bottom)
        .attr('style', 'overflow:hidden')

        .selectAll('path')
        .data(pathMappedGroups||[])
          .join(
            enter => enter.append('path'),
            update => update,
            exit => exit.remove()
          )
          .attr('d', d => String(d.path))
          .attr('stroke', d => labelToColorMap.get(d.label) || 'black' )
          .attr('fill', 'none');

    }, [
      binnedData,
      pathMappedGroups,
      padding.left,
      padding.top,
      labelToColorMap,
      height,
      width,
      padding.bottom,
      padding.right
    ]);

    useEffect(function getSvgBbBoxOnResize(){
      const setBbBoxOnChange = function(){
        const bbBox = svgRef.current?.getBoundingClientRect();
        setSvgRefBbBox(bbBox);
      };

      window.addEventListener('resize', setBbBoxOnChange);
      setBbBoxOnChange();

      return () => {
        window.removeEventListener('resize', setBbBoxOnChange);
      };
    },[setSvgRefBbBox]);


    const numberOfTimeSamples = useMemo(() => {
      if(!dateExtent){ return 0; }

      if(itemizationTimeSample === 'hour'){
        return differenceInHours(dateExtent[1], dateExtent[0]);
      }
      else if(itemizationTimeSample === 'day'){
        return differenceInDays(dateExtent[1], dateExtent[0]);
      }

      return 0;
    }, [
      dateExtent,
      itemizationTimeSample
    ]);

    const getTimesampleSlice = useCallback(function getTimesampleSlice(sliceNum){
      if(!dateExtent||!numberOfTimeSamples) {return null;}

      let dateRef;
      switch(itemizationTimeSample){
        case 'hour':
          dateRef = startOfHour(sliceNum*1000 * 60 * 60 + Number(dateExtent[0]));
          break;
        case 'day':
          dateRef = startOfDay(sliceNum*1000 * 60 * 60 * 24 + Number(dateExtent[0]));
          break;
        default:
          break;
      }
      const dateRefTimestamp = Number(dateRef);
      const ref = groupValuesByTimesampleRef.current?.[dateRefTimestamp]||{timeRefTimestamp: dateRefTimestamp, values:{}};

      //console.log('dateRef', {dateRef, num:Number(dateRef), ref, groupValuesByTimesampleRef});

      return ref;
    },[dateExtent, itemizationTimeSample, numberOfTimeSamples]);

    const placeTimesampleLine = useCallback(function(XP: number, xP: number){

      const ref = getTimesampleSlice(Math.round(numberOfTimeSamples*xP));

      if(!ref){
        return;
      }

      const entries = Object.entries(ref.values);

      const timesampleGroup = select(svgRef.current)
        .selectAll('g.timesampleElContainer')
        .attr('style', null)
        .raise()
        .data([XP])
        .join(
          enter => {
            const g = enter.append('g').attr('class', 'timesampleElContainer');

              //console.log('append g', {g, enter, el: svgRef.current, select, selection});
              g
                .append('line').attr('stroke', 'black')
                .attr('x1', 0)
                .attr('x2', 0)
                .attr('y1', 0)
                .attr('y2', height);

              g
               .append('g')
               .attr('class', 'labelsGroup')
               .append('rect');

            return g;
          },
          update => {

            return update;
          },
          exit => {
            exit.remove();
          }
        )
        .attr('transform', `translate(${XP*width},20)`);

      timesampleGroup
        .selectAll('text.date')
        .data([ref])
        .join(
          enter => enter.append('text').attr('class', 'date'),
          update => update,
          exit => exit.remove()
          )

        .html(() => format(new Date(ref.timeRefTimestamp), itemizationTimeSample==='hour'?'MMM d, p':'MMM d'))
        .attr('text-anchor', xP<0.5?'start':'end')
        .attr('x', xP<0.5?'10':'-10')
        .attr('fill', '#404446');

      const labelsGroup =
        timesampleGroup
          .select('g.labelsGroup')
          .attr('transform', `translate(${xP>0.5?-100-10:10},10)`);
          //.moveToFront();

      labelsGroup
        .select('rect')
          .attr('width', '100')
          .attr('height', entries.length?entries.length*1.2+(0.2)+'em':0)
          .attr('fill', 'white')
          .attr('rx', 5)
          .attr('style', 'filter:url(#shadow)');

      labelsGroup
        .selectAll('rect.mark')
        .data(entries)
        .join(
          enter => {
            const mark = enter
              .append('rect')
              .attr('class', 'mark');
            return mark;
          },
          update => {
            return update;
          },
          exit => {
            exit.remove();
          })

        .attr('x', '0.2em')
        .attr('y', (d, i) => (d && i===0? 0.2: i*1.2+0.2)+'em')
        .attr('width', '1em')
        .attr('height', '1em')
        .attr('fill', d => labelToColorMap.get(d[0])||'black');

      labelsGroup
        .selectAll('text.mark')
        .data(entries)
        .join(
          enter => {
            const mark = enter
              .append('text')
              .attr('class', 'mark');
            return mark;
          },
          update => {
            return update;
          },
          exit => {
            exit.remove();
          })

        .html(d => String(d[1]))
        .attr('x', '1.4em')
        .attr('y', (d, i) => (d && i===0? 1.1: i*1.2+1.1)+'em')

        .attr('fill', d => labelToColorMap.get(d[0])||'black');


    }, [
      height,
      numberOfTimeSamples,
      getTimesampleSlice,
      width,
      itemizationTimeSample,
      labelToColorMap
    ]);

    const hideTimesampleLine = useCallback(function(){
      select(svgRef.current)
        .selectAll('g.timesampleElContainer')
        .attr('style', 'display: none');

    },[]);

    // hookupTimesampleLine
    useEffect(function hookUpLineRoller(){
      const svgEl = svgRef.current;

      const graphAreaBoundaryP = [
        (padding.left)/width,
        (width-padding.right)/width
      ];

      const timesampleSumLine = function displayTimasampleSumLine(e: MouseEvent){
        if(!svgRefBbBox){ return; }

        const x = e.clientX - svgRefBbBox.x;
        const xP = x/svgRefBbBox.width;

        //console.log('svgRefBbBox', {svgRefBbBox, e})

        if(xP>=graphAreaBoundaryP[0] && xP<=graphAreaBoundaryP[1]){
          placeTimesampleLine(xP, (xP-graphAreaBoundaryP[0])/(graphAreaBoundaryP[1]-graphAreaBoundaryP[0]) );
        }
        else {
          hideTimesampleLine();
        }
      };

      svgEl?.addEventListener('mousemove', timesampleSumLine);
      svgEl?.addEventListener('mouseleave', hideTimesampleLine);

      return () => {
        svgEl?.removeEventListener('mousemove', timesampleSumLine);
        svgEl?.removeEventListener('mouseleave', hideTimesampleLine);
      };


    },[
      svgRefBbBox,
      placeTimesampleLine,
      hideTimesampleLine,
      padding.left,
      padding.right,
      width
    ]);

    return <svg className="amountOverDateGraph" ref={svgRef} width={width} height={height} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMin meet" overflow="visible">
      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="4" floodOpacity="0.5" />
        </filter>
      </defs>

      {label?
        <text
          className="amountLabel"
          style={{transform: 'rotate(-90deg)'}}
          y="-1.5em"
          x={-height/2}
          textAnchor="middle">{label}</text>
      :null}

      {children}
    </svg>;
  };
