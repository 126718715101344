import { apiRequest, ApiRequestData ,
  getGraphQLData,
  getGraphQLHomeQuery,
  GraphQLHomeQueryTypes,
} from 'api';


export type UpdateHomeData = {
  name?: string;
  primary_email?: string;
};
export const updateHome = (
  uid: string,
  data: UpdateHomeData,
  axiosConfig?: ApiRequestData,
) =>
  apiRequest({
    service: 'dataAPI',
    path: `/api/home/${uid}`,
    method: 'PUT',
    data,
    ...axiosConfig,
  });

export const getHomeGraphQLData = (accountId: string) =>
  getGraphQLData<GraphQLHomeQueryTypes>(getGraphQLHomeQuery(accountId));