import React from 'react';

import { useTheme } from 'styled-components';

import styles from './Loading.module.css';


type LoadingProps = {
  size?: number;
  className?: string; 

  x?: string;
  y?: string;
};
export const Loading: React.FC<LoadingProps> = ({
  size=140,
  className='',

  x,
  y
}) => {

  const theme = useTheme() as any;

  return (
    <svg x={x} y={y} className={styles.loader + ' ' + className + ' loading' } width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
       <circle cx="170" cy="170" r="160" stroke={theme.colors.green.light}/>
       <circle cx="170" cy="170" r="135" stroke={theme.colors.green.base}/>
       <circle cx="170" cy="170" r="110" stroke={theme.colors.blue.base}/>
       <circle cx="170" cy="170" r="85" stroke={theme.colors.blue.light}/>
    </svg>

    );
};