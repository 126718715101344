import { apiRequest } from 'api';
import { Maybe, Profile } from 'types';

import { logCreateProfileEvent, logProfileNameChangeEvent } from '../firebase';
import {
  getGraphQLData,
  getGraphQLProfilesQuery,
  GraphQLProfilesQueryTypes,
} from './globalGraphQL';

export type CreateFirstUserProfileData = {
  ip?: string;
  mac?: string;
  sensorId: string;
  name: string;
  email: string;
  phone: string;
  homeId: string;
};
export const createFirstUserProfile = (data: CreateFirstUserProfileData) => {
  const { ip, mac, sensorId, name, email, phone, homeId } = data;
  if (!ip && !mac) {
    throw new Error('ip or mac required.');
  }
  const apiData = {
    ip,
    mac,
    sensor_id: sensorId,
    profile_name: name,
    profile_email: email,
    profile_phone_number: phone,
    home_id: Number(homeId),
  };
  return apiRequest({
    service: 'dataAPI',
    path: '/api/create_first_profile',
    method: 'POST',
    data: apiData,
  });
};

export type CreateProfileData = {
  home_id?: string;
  name: string;
  email?: string;
  phone_number?: string;
  description?: string;
  photo_url?: string;
  primary_device_id?: Maybe<number>;
  alert_on_enter?: boolean;
  alert_on_leave?: boolean;
};
type NewProfileResponseData = {
  uid: string;
  home_id?: string;
  name: string;
  email?: string;
  phone_number?: string;
  description?: string;
  photo_url?: string;
  primary_device_id?: Maybe<number>;
  alert_on_enter?: boolean;
  alert_on_leave?: boolean;
};
export const createProfile = async (data: CreateProfileData) => {
  try {
    const response = await apiRequest<NewProfileResponseData>({
      service: 'dataAPI',
      path: '/api/profile',
      method: 'POST',
      data,
    });
    logCreateProfileEvent({
      profileUid: response.data.uid,
    });
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export type UpdateProfileData = {
  name?: string;
  email?: Maybe<string>;
  phone_number?: Maybe<string>;
  description?: Maybe<string>;
  photo_url?: Maybe<string>;
  primary_device_id?: Maybe<number>;
  alert_on_enter?: boolean;
  alert_on_leave?: boolean;
};
export const updateProfile = async (
  profileUid: string,
  data: UpdateProfileData,
  previousProfile: Profile,
) => {
  try {
    const response = await apiRequest({
      service: 'dataAPI',
      path: `/api/profile/${profileUid}`,
      method: 'PUT',
      data,
    });

    // Log Changes
    if (
      data.name &&
      previousProfile.name &&
      previousProfile.name !== data.name
    ) {
      logProfileNameChangeEvent({
        fromName: previousProfile.name,
        toName: data.name,
        profileUid,
      });
    }
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const deleteProfile = (profileUid: string) =>
  apiRequest({
    service: 'dataAPI',
    path: '/api/remove_profile',
    method: 'POST',
    data: {
      profile_id: Number(profileUid),
    },
  });

export const getProfilesGraphQLData = (accountId: string) =>
  getGraphQLData<GraphQLProfilesQueryTypes>(getGraphQLProfilesQuery(accountId));
