import {useState, useCallback, useMemo} from 'react';

import {
  format
} from 'date-fns-tz';
import {
  BiInfoCircle
} from 'react-icons/bi';
import {
  FiFileText,
  FiDownload,
  FiChevronLeft,
  FiChevronRight
} from 'react-icons/fi';
import {
  useNavigate
} from 'react-router-dom';
import styled from 'styled-components';

import {
  SavedTmsiReport
} from 'api';
import {
  Button,
  Breadcrumbs,
  Loading
} from 'components';
import {
  ENV
} from 'config';
import {
  useUserReportsContext
} from 'context';
import {
  FlexContainer,
  Flex2,
  Flex4,
  Flex6,
} from 'layout';
import {
  downloadTmsiReport
} from 'pages';


function getReportPreview(savedReport: SavedTmsiReport){
  try {
    const records = savedReport.records;
    const durationHours = (savedReport.endDate - savedReport.startDate) / (1000 * 60 * 60);

    console.log('[getReportPreview] preview:', );

    return {
      records,
      durationHours
    };
  }
  catch (e) {
    console.error('[getReportPreview] error getting preview data:', e);
  }
}

export const TMSIReport = () => {
  const navigate = useNavigate();

  //const {savedReports} = useReports();

  const {savedReportsIndex, reportsLoaded, loadedReportDocs, loadReportDoc} = useUserReportsContext();
  const [previewReport, setPreviewReport] = useState<SavedTmsiReport>();
  const [page, setPage] = useState<number>(0);
  const [showBy] = useState<number>(5);

  const reportPreviewData = useMemo(() => previewReport ? getReportPreview(previewReport) : null, [previewReport]);

  const downloadSavedReport = useCallback(async function downloadSavedReport(savedReport: SavedTmsiReport){
    try {
      const reportDoc = loadedReportDocs[savedReport.report] || await loadReportDoc(savedReport.report);
      if(!reportDoc){ throw new Error(`No report found by provided id: ${savedReport.report}`); }
      const report = JSON.parse(reportDoc.body);
      const name = (savedReport.sensors +' '+ new Date(savedReport.startDate) +'-'+ new Date(savedReport.endDate) + '.csv').replace(',', ' ');
      downloadTmsiReport(report, name);
    }
    catch (e) {
      console.error('[TMSIReport] error saving the report', e);
    }

  }, [loadReportDoc, loadedReportDocs]);

  const pages = useMemo(() =>
    //[...Array(savedReportsIndex.length/showBy)]
    Math.ceil(savedReportsIndex.length/showBy)
    ,
    [savedReportsIndex, showBy]);
  const handlePrev = useCallback( function(){
    if(page-1>-1){
      setPage(page-1);
    }
  },[page]);
  const handleNext = useCallback( function(){
    if(page+1<pages){
      setPage(page+1);
    }
  },[page, pages]);

  return <>
    <Breadcrumbs />

    <Header>

      <Flex6 className="intro-with-button">
        <h2><FiFileText /> TMSI Report</h2>
        <p>A TMSI Report will provide you a list of TMSIs your sensor has seen within a period of time.</p>

        <Button onClick={() => navigate('./new-tmsi-export')}>Start New Export</Button>
      </Flex6>

      <Flex6>
        <GrayInset>
          <h5><BiInfoCircle /> FAQ</h5>

          <ToogleDiv
            title="What is a TMSI?">
            <p>TMSI stands for Temporary Mobile Subscriber Identity. It allows your cellular device to access a cellular network.</p>
            <p>Similar to how WiFi networks utilize IP addresses, cellular network providers use TMSIs to manage traffic coming from many devices at once.</p>
          </ToogleDiv>

          <ToogleDiv
            title="What is a TMSI Report for?">
            <p>You can hand this report to law enforcement officials in the event of a burglary. This can substantially aid in an investigation because it can lead to a real person. </p>
          </ToogleDiv>

          <ToogleDiv
            title="What will happen after it goes to law enforcement?">
            <p>Law enforcement can subpoena wireless carriers to obtain subscriber information (i.e. the phone’s owner) associated with a particular TMSI. Through law enforcement process, a TMSI therefore can be associated with a specific person.</p>
            <a href={ENV.tmsi_law_enforcement_link} target="_blank" download rel="noreferrer">Download instructions for law enforcement</a>
          </ToogleDiv>

        </GrayInset>
      </Flex6>

    </Header>

    <Section>
      <Flex6>
        {!reportsLoaded
        ?
        <Loading />
        :
        savedReportsIndex.length
        ?
        <>
          <h3>Previous Exports</h3>
          <ExportsList>
            {savedReportsIndex.slice(showBy*page,showBy*page+showBy).map( (item) => {
              const {sensors, createdAt, startDate, endDate} = item;
              const date = new Date(createdAt);
              const uid = item.uid;

              return <div
                key={uid}
                className={previewReport?.uid === uid ? 'selected' : ''}
                onClick={() => setPreviewReport(item)}>

                <Flex2>{format(date, 'MM/dd')}</Flex2>
                <Flex6 className="left">{String(new Date(startDate))}<br />{String(new Date(endDate))}<br /><i>Sensor(s): {sensors}</i></Flex6>
                <Flex4 onClick={(e) => {
                  e.stopPropagation();
                  downloadSavedReport(item);
                }}><FiDownload /></Flex4>

              </div>;
            })}
          </ExportsList>
          {pages>1
            ?
            <Pagination>
              <FiChevronLeft onClick={handlePrev}/>
              <span>page {page+1}/{pages}</span>
              <FiChevronRight onClick={handleNext}/>
            </Pagination>
          : null}
        </>
        :
        null
      }
      </Flex6>
      <Flex6>
        {reportPreviewData ?
          <FilePreview>
            <FiFileText />
            <p><b>{reportPreviewData.records}</b> records<br /> over <b>{reportPreviewData.durationHours}</b> hours</p>
          </FilePreview>
          : null}
      </Flex6>
    </Section>
  </>;
};

type ToogleDivProps = {
  open?: boolean,
  title: string
}
const ToogleDiv: React.FC<ToogleDivProps> = ({
  title,
  open: passedOpen,
  children
}) => {
  const [open, setOpen] = useState<boolean>(passedOpen?true:false);

  return <StyledToogleDiv open={open}>
    <h6 onClick={()=>setOpen(!open)}>{title}</h6>
    {children}
  </StyledToogleDiv>;
};

const Header = styled(FlexContainer)`${({theme})=> `
  padding: ${theme.boxPadding.xl3}px ${theme.boxPadding.xl7}px;
  border-radius: ${theme.boxPadding.l}px;
  background-color: ${theme.colors.sky.lightest};
  min-height: 272px;

  > * {
    position: relative;
  }

  .intro-with-button {
    padding-bottom: 65px;
    margin-bottom: 10px;
  }
  button {
    position: absolute;
    bottom: 0;
  }

  p {
    margin-right: ${theme.boxMargins.xl9*2}px;
  }
`}`;

const FilePreview = styled.div`${({theme}) => `

  width: 100%;
  position: sticky;
  top: ${theme.boxMargins.xl4}px;
  margin-top: ${theme.boxMargins.xl4}px;

  text-align: center;

  > svg {
    width: 100px;
    height: 100px;
    color: ${theme.colors.sky.light};
  }

  b {
    font-size: ${theme.fontSize.xl2}px;
  }

`}`;

const Pagination = styled.div`${({theme}) => `
  font-size: ${theme.fontSize.base}px;
  line-height: ${theme.fontSize.l}px;
  text-align: right;
  color: ${theme.colors.ink.lighter};

  margin-top: ${theme.boxMargins.base}px;

  svg {
    cursor: pointer;
    padding: ${theme.boxMargins.base}px;
    vertical-align: middle;
    margin-top: -1px;
  }

`}`;

const Section = styled(FlexContainer)`${({theme}) => `
  margin: ${theme.boxPadding.xl9}px ${theme.boxPadding.xl5}px;

  .loading {
    margin: auto;
    height: ${theme.boxPadding.xl9}px;
    width: 100%;
    opacity: 0.5;
  }
`}`;

const GrayInset = styled.div`${({theme}) => `
  padding: ${theme.boxPadding.xl5}px;
  border-radius: ${theme.boxPadding.base}px;
  background-color: ${theme.colors.sky.light};
  min-height: 100%;
  box-sizing: border-box;
`}`;

const StyledToogleDiv = styled.div.attrs({} as ToogleDivProps)`${({theme, open}) => `
  h6 {
    color: ${theme.colors.ink.lighter};
    cursor: pointer;
  }

  h6 {
    margin-left: ${-theme.boxPadding.xl+theme.boxPadding.base}px;
  }

  padding: ${theme.boxPadding.base}px ${theme.boxPadding.xl}px;

  ${!open?`
    /* closed */

    > *:not(h6) {
      display: none;
    }

    h6 {
      margin-top:0;
      margin-right:0;
      margin-bottom:0;
    }

  `:`
    /* open */

    > * {
      margin-bottom: 10px;
    }
  `}
`}`;

const ExportsList = styled.div`${({theme}) => `

  border: 1px solid ${theme.colors.sky.dark};
  border-left: none;
  border-right: none;

  padding-top: ${theme.boxPadding.base}px;
  padding-bottom: ${theme.boxPadding.xl4}px;

  > * {
    display: flex;
    cursor: pointer;
    padding: 6px 0;

    font-size: ${theme.fontSize.base}px;
    font-family: ${theme.fontFamily.inter};
  }

  > *.selected {
    background-color: ${theme.colors.sky.light};
  }

  > *:not(.selected):hover {
    background-color: ${theme.colors.sky.lightest};
  }

  > * > *:first-child {
    font-size: ${theme.fontSize.l}px;

  }

  > * > *:not(.left) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  *:hover > svg {
    color: ${theme.colors.primary.base};
  }

  i {
    font-style: normal;
    font-size: ${theme.fontSize.m}px;
    color: ${theme.colors.ink.lighter};
  }
`}`;
