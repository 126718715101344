import { Configurations } from '@ha_schemas/ha_schemas';

import { apiRequest } from 'api';

export const getEckleburgConfigurations = () =>
  apiRequest<Configurations>({
    service: 'eckleburgAPI',
    path: '/configurations',
    method: 'GET',
  });

export type EckleburgSensorSnapshot = {
  'Passive Wifi Mac Counter': Record<string, number>;
  'Passive Wifi SSID Counter': Record<string, number>;
  'Passive Wifi Mac Key Counter': { macs: number };
  'Phone Type Clf': Record<string, number>;
  'Phone Type Clf -60': Record<string, number>;
  'Passive Wifi Mac Counter -60': Record<string, number>;
  'Passive Wifi SSID Counter -60': Record<string, number>;
  'Passive Wifi Mac Key Counter -60': { macs: number };
  'Phone Type Clf -50': Record<string, number>;
  'Passive Wifi Mac Counter -50': Record<string, number>;
  'Passive Wifi SSID Counter -50': Record<string, number>;
  'Passive Wifi Mac Key Counter -50': { macs: number };
  'Phone Type Clf -40': Record<string, number>;
  'Passive Wifi Mac Counter -40': Record<string, number>;
  'Passive Wifi SSID Counter -40': Record<string, number>;
  'Passive Wifi Mac Key Counter -40': { macs: number };
};
export const getEckleburgSensorSnapshot = (uid: string) =>
  apiRequest<EckleburgSensorSnapshot>({
    service: 'eckleburgAPI',
    path: `/current_snapshot/${uid}`,
    method: 'GET',
  });

type SnapshotConfig = {
  lower_bound: number;
  upper_bound: number;
  n: number;
  sensor_id: string;
  columns: string[];
};
export type SnapshotDatum = {
  sensor_id: string;
  timestamp: number;
} & {
  [column_key: string]: Record<string, number>;
};
export type SnapshotResponseData = SnapshotDatum[];
export const getAlertSnapshot = (data: SnapshotConfig) =>
  apiRequest<SnapshotResponseData>({
    service: 'eckleburgAPI',
    path: '/snapshots',
    method: 'GET',
    params: {
      ...data,
      orient: 'records',
    },
  });
