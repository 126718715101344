import { HealthPayload } from '@ha_schemas/ha_schemas';

import { apiRequest, ApiRequestData } from 'api';
import { SensorType } from 'types';

import {
  getGraphQLData,
  getGraphQLSensorsQuery,
  GraphQLSensorsQueryTypes,
} from './globalGraphQL';

export const getSensorsGraphQLData = async (accountId: string) =>
  getGraphQLData<GraphQLSensorsQueryTypes>(getGraphQLSensorsQuery(accountId));

type SensorHealthTimelineConfig = {
  sensorUid?: string;
  startEpoch?: number; // Earlier in time
  endEpoch?: number;
};
export const getSensorHealthTimelineMetrics = (
  config: SensorHealthTimelineConfig,
  axiosConfig?: ApiRequestData,
) => {
  const { sensorUid, startEpoch, endEpoch } = config;
  return apiRequest<HealthPayload[]>({
    method: 'GET',
    service: 'dataAPI',
    path: `/metrics/sensor_health_metrics/${sensorUid}/${startEpoch}/${endEpoch}`,
    ...axiosConfig,
  });
};

export type StreamHealthData = {
  cnt: number;
  'should-have-data': 'yes' | 'no';
  status: 'Ok' | 'No Data';
  'traffic-score': number;
};
export type AllStreamHealthData = {
  'bt-active': StreamHealthData;
  'bt-adv-packet': StreamHealthData;
  cell: StreamHealthData;
  'wifi-networked': StreamHealthData;
  'wifi-probe-request': StreamHealthData;
  'sensor-type': SensorType;
};
export type SensorStreamHealth = {
  Data: {
    'data-health': AllStreamHealthData;
    sensor: string;
    sensor_type: SensorType;
  }[];
};
export const getAllSensorStreamHealth = (axiosConfig?: ApiRequestData) =>
  apiRequest<SensorStreamHealth>({
    method: 'GET',
    service: 'dataAPI',
    path: '/api/data_health',
    ...axiosConfig,
  });
export const getSensorStreamHealth = (
  sensorUid: string,
  axiosConfig?: ApiRequestData,
) =>
  apiRequest<SensorStreamHealth>({
    method: 'POST',
    service: 'dataAPI',
    path: '/api/data_health',
    data: {
      sensor_id: sensorUid,
    },
    ...axiosConfig,
  });
