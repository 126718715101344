import { apiRequest, ApiRequestData } from 'api';
import { HomeSize, HomeType, TimeZone } from 'config/localData';
import { APIResponse, Profile, Sensor, AccountPermission } from 'types';

export type CreateAccountData = {
  address_street1: string;
  address_street2?: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
  name: string;
  primary_phone: string;
  home_type: string;
  sq_ft: string;
};
export type AccountDataResponseData = {
  uid: string;
  created_at: number;
  address_city?: string;
  address_state?: string;
  address_street1?: string;
  address_street2?: string;
  address_zip?: string;
  address_country?: string;
  home_type?: string;
  name?: string;
  primary_phone?: string;
  permissions?: AccountPermission[];
  sq_ft?: string;
};
export type HomeDataResponseData = {
  account_id?: string;
  app_last_opened?: number;
  created_at?: number;
  lat?: number;
  lng?: number;
  name?: string;
  primary_email?: string;
  profiles?: Profile[];
  quiet_mode?: boolean;
  quiet_mode_bluetooth?: boolean;
  quiet_mode_cell?: boolean;
  quiet_mode_network?: boolean;
  quiet_mode_wifi?: boolean;
  sensors?: Sensor[];
  timezone?: TimeZone;
  uid?: number;
};
export type AccountUpdateResponseData = {
  account_data?: AccountDataResponseData;
  home_data?: HomeDataResponseData;
};

export type UpdateAccountData = {
  address_street1?: string;
  address_street2?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
  address_country?: string;
  name?: string;
  primary_phone?: string;
  home_type?: HomeType;
  sq_ft?: HomeSize;
};
export const updateAccount = async (
  uid: string,
  data: UpdateAccountData,
  axiosConfig?: ApiRequestData,
) => {
  try {
    const response = await apiRequest<APIResponse<AccountUpdateResponseData>>({
      service: 'accountManagementV3',
      path: `/account/${uid}`,
      method: 'PUT',
      data,
      ...axiosConfig,
    });
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const getAccount = (uid: string) =>
  apiRequest<APIResponse<AccountDataResponseData>>({
    service: 'accountManagementV3',
    path: `/account/${uid}`,
    method: 'GET',
  });

export const deleteAccount = (uid: string) =>
  apiRequest<APIResponse<never>>({
    service: 'accountManagementV3',
    path: `/account/${uid}`,
    method: 'DELETE',
  });
export const updateAccountAddress = (
  uid: string,
  address: Partial<
    Pick<
      CreateAccountData,
      | 'address_street1'
      | 'address_street2'
      | 'address_city'
      | 'address_state'
      | 'address_zip'
    >
  >,
) => updateAccount(uid, address);
