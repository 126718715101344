import { AccountDataResponseData } from 'api';
import { HomeSize, HomeType } from 'config/localData';
import { Account } from 'types';


export const processRawAccount = (
  account?: AccountDataResponseData,
): Account | undefined => {
  if (!account) {
    return undefined;
  }

  const {
    address_city,
    address_state,
    address_street1,
    address_street2,
    address_zip,
    address_country,
    created_at,
    home_type,
    name,
    primary_phone,
    permissions,
    sq_ft,
    uid,
  } = account;

  // TODO: Add data validation to make type easier

  return {
    createdAt: created_at * 1000,
    uid,
    addressCity: address_city,
    addressState: address_state,
    addressStreet1: address_street1,
    addressStreet2: address_street2,
    addressZip: address_zip,
    addressCountry: address_country,
    homeType: home_type as HomeType,
    name,
    primaryPhone: primary_phone,
    sqFt: sq_ft as HomeSize,
    permissions,
  };
};
