import {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react';

import {
  startOfHour,

  startOfDay,
  endOfDay,

  startOfWeek,
  endOfWeek,

  startOfMonth,
  endOfMonth
} from 'date-fns';

import {
  TmsiReport,
  TmsiReportItem,
  getRawTmsiReport
} from 'api';
import {PROVIDERS_ARRAY} from 'config';
import {
  useMQTTCellRaw
} from 'hooks';
import {
  processRawTmsiItem,
  processRawCellMqttToRawTmsi,
  RawTmsiReport,
  RawTmsiReportItem
} from 'utils';

// NOTE: ALL items are mutable since processed from raw server reply!

type SampledProviderTmsiActivityItem = {
  items: any[];
  powerMin: number;
  powerMax: number;
  date: Date;
  amount: number;
  label: string;
}

type IndexedTmsiReport = {
  label: string,
  items: TmsiReportItem[]
};

type IndexedBySample = {
  label: string;
  items: (TmsiReportItem&{amount: number})[]
};

type IndexedByTmsiLabel= {
  items: TmsiReportItem[];
  powerMax: number;
  powerMin: number;
  label: string;
  value: string;
}

type UseTmsiActivityContext = {
  unsortedTmsiReport: TmsiReport,
  indexedTmsiReports: IndexedTmsiReport[],
  sampledProviderTmsiActivity: SampledProviderTmsiActivityItem[],
  dailyByProvider: IndexedBySample[],
  byTmsiAndLabel: IndexedByTmsiLabel[];
  loading: boolean,
  enabledProviders: {
    [key: string]: boolean
  },
  setEnabledProviders: (payload: {
    [key: string]: boolean
  }) => void,
  sensorId: string,
  dateMode: 'day'|'week'|'month',
  setDateMode: (value: 'day'|'week'|'month') => void;
  dateRange: [Date, Date?],
  setDateRange: (payload: [Date, Date?]) => void,
  filteredTmsiReport: IndexedBySample[]
  filteredByTmsiAndLabel: IndexedByTmsiLabel[]
  cutoffDate: Date|number
}

export type UseTmsiActivityPayload = {
  sensorId: string|undefined;
  startDate?: Date;
  dateMode?: UseTmsiActivityContext['dateMode'];
  allowLive?: boolean
  preserveByName?: string;
};

type UseTmsiActivityHook = (payload: UseTmsiActivityPayload) => UseTmsiActivityContext;

export const useTmsiActivity: UseTmsiActivityHook = (payload) => {
  const {
    sensorId: passedSensorId,
    startDate: passedStartDate=new Date(),
    dateMode: passedDateMode,
    allowLive = true,
    preserveByName
  } = payload;
  const [unsortedTmsiReport,setUnsortedTmsiReport] = useState<UseTmsiActivityContext['unsortedTmsiReport']>([]);
  const [indexedTmsiReports, setIndexedTmsiReports] = useState<UseTmsiActivityContext['indexedTmsiReports']>([]);
  const [byTmsiAndLabel, setByTmsiAndLabel] = useState<UseTmsiActivityContext['byTmsiAndLabel']>([]);
  //const [dailyTmsiActivityByProvider, setDailyTmsiActivityByProvider] = useState<Array<DailyTmsiActivityByProviderItem>>();
  //const dailyTmsiActivityByProviderIndexRef = useRef<{[key: number]: DailyTmsiActivityByProviderItem}>();
  const [sampledProviderTmsiActivity, setSampledProviderTmsiActivity] = useState<UseTmsiActivityContext['sampledProviderTmsiActivity']>([]);
  const [dailyByProvider, setDailyByProvider] = useState<UseTmsiActivityContext['dailyByProvider']>([]);
  const [loading, setLoading] = useState<UseTmsiActivityContext['loading']>(false);
  const [cutoffDate, setCutoffDate] = useState<UseTmsiActivityContext['cutoffDate']>(new Date());

  const [enabledProviders, setEnabledProviders] = useState<UseTmsiActivityContext['enabledProviders']>({
    'at&t':true,
    'verizon':true,
    't-mobile':true,
    'other':true
  });

  const [sensorId, setSensorId] = useState<UseTmsiActivityContext['sensorId']>(passedSensorId||'');
  //const [epochStart, setEpochStart] = useState<number>(1659330000);
  //const [epochEnd, setEpochEnd] = useState<number>(1659848400);

  const initDateMode = useMemo(function(){
    if(passedDateMode && preserveByName) {
      localStorage.setItem('useTmsiActivity_date_mode_'+preserveByName, passedDateMode);
    }
    if(!passedDateMode && preserveByName){
      const preservedItem = localStorage.getItem('useTmsiActivity_date_mode_'+preserveByName);
      if(preservedItem){
          return preservedItem as UseTmsiActivityContext['dateMode'];
      }
    }
    return passedDateMode||'week';
  },[preserveByName, passedDateMode]);
  const [dateMode, setDateModeState] = useState<UseTmsiActivityContext['dateMode']>(initDateMode);
  const dateModeRef = useRef<UseTmsiActivityContext['dateMode']>(initDateMode);
  const setDateMode = (value: UseTmsiActivityContext['dateMode']) => {
    if(preserveByName){
      localStorage.setItem('useTmsiActivity_date_mode_'+preserveByName, value);
    }
    setDateModeState(value);
    dateModeRef.current = value;
  };
  const [dateRange, setDateRange] = useState<UseTmsiActivityContext['dateRange']>(function(){
    // assign default date
    const defaultDates = [];
    const now = passedStartDate;

    if(dateMode === 'day'){
      const startDate = startOfDay(now);
      const endDate = endOfDay(now);

      defaultDates.push(startDate, endDate);
    }
    else if (dateMode === 'week'){
      const startDate = startOfWeek(now);
      const endDate = endOfWeek(now);

      defaultDates.push(startDate, endDate);
    }
    else if (dateMode === 'month'){
      const startDate = startOfMonth(now);
      const endDate = endOfMonth(now);

      defaultDates.push(startDate, endDate);
    }
    return defaultDates as [Date, Date?];
  }());

  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  useEffect(function(){
    setSensorId(passedSensorId||'');
  },[passedSensorId, setSensorId]);


  // MQTT subscribtion
  const onNewTmsiDataRef = useRef<(item: RawTmsiReportItem) => void>();
  const onCloseFn = useRef<() => void>();
  useMQTTCellRaw(sensorId, {
    onNext: function(newData){
      const rawTmsiActivity = processRawCellMqttToRawTmsi(newData);

      console.log('[useTmsiActivity] got new data', {newData, rawTmsiActivity});
      if(rawTmsiActivity){
        onNewTmsiDataRef.current?.(rawTmsiActivity);
      }
    },
    onClose: function(){
      onCloseFn.current?.();
      console.log('[useTmsiActivity] closed connection');
    }
  });

  // GET AND INDEX DATA
  // get test data set
  const processRawitemsToState = useCallback(function(rawItems: RawTmsiReport){
    const newUnsortedTmsiReport: TmsiReport = [];
    const newIndexedTmsiReportsObject: {
      [key: string]: IndexedTmsiReport
    } = {};
    const newByTmsiAndLabelIndex: {
      [key:string]: UseTmsiActivityContext['byTmsiAndLabel'][0];
    } = {};
    const newDailyByProviderIndex: {
      [key: string]: {
        label: string,
        items: any[]
      }
    } = {};
    const newSampledProviderTmsiActivityIndex: {
      [key: string]: SampledProviderTmsiActivityItem
    } = {};

    const matchProvidersRegex = new RegExp(
      PROVIDERS_ARRAY.join('|'),
      'i'
    );

    // process and idex items
    rawItems.forEach(rawItem => {
      const processedItem = processRawTmsiItem(rawItem);

      const label = processedItem.networkProvider.match(matchProvidersRegex)?.[0].toLowerCase()||'other';

      if(label){
        // -- indexed report
        if(!newIndexedTmsiReportsObject[label]){
          newIndexedTmsiReportsObject[label] = {
            label,
            items: [] as TmsiReport
          };
        }
        const indexedReport = newIndexedTmsiReportsObject[label].items;
        indexedReport.push(processedItem);

        // -- date sampled index
        const sampledDate = dateModeRef.current === 'day'
          ?
          startOfHour(processedItem.date)
          :
          startOfDay(processedItem.date);

        const sampledIndexLabel = String(sampledDate.getTime())+'_'+label;

        // create dailyByProvider item if does not exist
        if(!newDailyByProviderIndex[label]){
          newDailyByProviderIndex[label] = {
            label,
            items: []
          };
        }

        // create day index group item if sampledIndexLabel is first encountered
        if(!newSampledProviderTmsiActivityIndex[sampledIndexLabel]){
          const dayItem = {
            items: [],
            amount: 0,
            powerMin: 0,
            powerMax: 0,
            date: sampledDate,
            label
          };

          newSampledProviderTmsiActivityIndex[sampledIndexLabel] = dayItem;
          // if new day item -> push to day index
          newDailyByProviderIndex[label].items.push(dayItem);
        }

        const dailyIndex = newSampledProviderTmsiActivityIndex[sampledIndexLabel];
        dailyIndex.amount += 1;
        if(!dailyIndex.powerMin || processedItem.powerEstimate < dailyIndex.powerMin){
          dailyIndex.powerMin = processedItem.powerEstimate;
        }
        if(!dailyIndex.powerMax || processedItem.powerEstimate > dailyIndex.powerMax){
          dailyIndex.powerMax = processedItem.powerEstimate;
        }

        dailyIndex.items.push(processedItem);

        // -- tmsi label based index
        const {tmsi} = processedItem;
        const tmsiLabel = tmsi+'_'+label;
        if(!newByTmsiAndLabelIndex[tmsiLabel]){
          newByTmsiAndLabelIndex[tmsiLabel] = {
            value: String(tmsi),
            powerMin: 0,
            powerMax: 0,
            label,
            items: [] as TmsiReportItem[]
          };
        }

        const tmsiLabelItem = newByTmsiAndLabelIndex[tmsiLabel];
        if(!tmsiLabelItem.powerMin || processedItem.powerEstimate < tmsiLabelItem.powerMin){
          tmsiLabelItem.powerMin = processedItem.powerEstimate;
        }
        if(!tmsiLabelItem.powerMax || processedItem.powerEstimate > tmsiLabelItem.powerMax){
          tmsiLabelItem.powerMax = processedItem.powerEstimate;
        }
        tmsiLabelItem.items.push(processedItem);

      }
      newUnsortedTmsiReport.push(processedItem);
    });

    setUnsortedTmsiReport(newUnsortedTmsiReport);
    setIndexedTmsiReports(Object.values(newIndexedTmsiReportsObject));
    setSampledProviderTmsiActivity(Object.values(newSampledProviderTmsiActivityIndex));
    setDailyByProvider(Object.values(newDailyByProviderIndex));
    setByTmsiAndLabel(Object.values(newByTmsiAndLabelIndex));
    //setDailyTmsiActivityByProvider(Object.values(newDailtyActivityIndex));
    // preserve index for quick search
    //dailyTmsiActivityByProviderIndexRef.current = newDailtyActivityIndex;

    console.log('[ActivityReport] raw items', {
      rawItems,
      newUnsortedTmsiReport,
      newIndexedTmsiReportsObject,
      newSampledProviderTmsiActivityIndex,
      newDailyByProviderIndex,
      newByTmsiAndLabelIndex
    });

  },[]);

  useEffect(function(){
    if(!sensorId){ return; }

    const epochStart = Math.round(startDate.getTime()/1000);
    const epochEnd = Math.round( (!endDate ? new Date() : endDate).getTime()/1000);

    const isLiveGraph = allowLive && (!endDate||endDate.getTime() >= Date.now());

    console.log('[useTmsiActivity] its live graph', {isLiveGraph});

    const rawItems: RawTmsiReport = [];
    //
    // let arbitraryStartEpoch = Math.round((startDate.getTime() + ((endDate||new Date()).getTime()-startDate.getTime())/5)/1000);
    // let arbitraryStep = 60 * 60;

    (async function getTmsiReportData(){
      try {

        setLoading(true);

        const newRawItems = //testData["Items Returned"]; // use testData instead
          await getRawTmsiReport({
            sensorId: Number(sensorId),
            epochStart: Number(epochStart),
            epochEnd: Number(epochEnd)
          }) || [];

        if(isLiveGraph){
          setCutoffDate(Date.now());
        }

        rawItems.push(...newRawItems);
        processRawitemsToState(rawItems);
        setLoading(false);

      } catch (error){
        console.error('[useTmsiActivity] error getting TMSI activity data', error);
        setLoading(false);
      }
    })();

    if(isLiveGraph){
      onNewTmsiDataRef.current = function(newRawTmsiItem: RawTmsiReport[0]){
        rawItems.splice(0,0,newRawTmsiItem);
        console.log('[useTmsiActivity] pushing new raw item', {newRawTmsiItem, rawItems});
        processRawitemsToState(rawItems);
        setCutoffDate(Date.now());
      };
    }

    // cleanup
    return () => {
      onNewTmsiDataRef.current = undefined;
    };
  }, [startDate, endDate, sensorId, allowLive, processRawitemsToState]);

  const filteredTmsiReport = useMemo(() =>
    dailyByProvider?.filter(group => enabledProviders[group.label]),
    [enabledProviders, dailyByProvider]);

  const filteredByTmsiAndLabel = useMemo(() =>
    byTmsiAndLabel.filter(group => enabledProviders[group.label]),
    [byTmsiAndLabel, enabledProviders]);

  return {
    unsortedTmsiReport,
    indexedTmsiReports,
    sampledProviderTmsiActivity,
    dailyByProvider,
    byTmsiAndLabel,
    loading,
    enabledProviders,
    setEnabledProviders,
    sensorId,
    dateMode,
    setDateMode,
    dateRange,
    setDateRange,
    filteredTmsiReport,
    filteredByTmsiAndLabel,
    cutoffDate
  };
};
