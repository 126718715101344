import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';

import axios from 'axios';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import { getIdTokenResult, getAuth, onAuthStateChanged, User, reload} from 'firebase/auth';

import { firebaseApp } from 'index';

export type FBUser = User;

export const AuthContext = createContext<{
  fbUser: FBUser | null;
  fbUserLoaded: boolean;
  fbUserEmailVerified: boolean | undefined;

  checkUserEmailVerification: () => Promise<void>;
  reset: () => void;
}>(undefined!);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: React.FC = (props) => {
  const [fbUser, setFBUser] = useState<FBUser | null>(null);
  const [fbUserLoaded, setFBUserLoaded] = useState(false);
  // variable to trigger state change on user reload since user is mutable
  const [reloadedAt, setReloadedAt] = useState(new Date());
  const fbUserEmailVerified = fbUser?.emailVerified;
  const auth = useMemo(() => getAuth(),[]);

  // This is used to force us to reload everything INCLUDING firebase auth stuff
  // Without this, its possible to reset our data in a race condition with firebase reporting new auth state
  const [resetCount, setResetCount] = useState(0);

  const resetTokensTimeoutRef = useRef<any>();

  const setCommonHeadersAndRefreshTimeout = useCallback(async function setCommonHeadersAndRefreshTimeout(refresh=false){
    //await setUserCommonHeaders();
    const user = getAuth().currentUser;
    const uid = user?.uid;
    const tokenResult = user ? await getIdTokenResult(user,refresh) : null;

    console.log('[AuthProvider] acquired token', {user, refresh, tokenResult, auth: getAuth(), uid});

    // -- Update axios headers to include auth data
    axios.defaults.headers.common = (tokenResult && uid ? {
      'token': String(tokenResult.token),
      'uid': uid
    } : {
      'token': '',
      'uid': ''
    });

    clearTimeout(resetTokensTimeoutRef.current);
    if(tokenResult){
      console.log('[AuthProvider] setting timeout:', {duration: new Date(tokenResult.expirationTime).getTime() - Date.now(), fn: setCommonHeadersAndRefreshTimeout });
      resetTokensTimeoutRef.current = setTimeout(
        () => setCommonHeadersAndRefreshTimeout(true),
        new Date(tokenResult.expirationTime).getTime() - Date.now()
      );
    }
  },[]);

  // Watch Firebase auth
  useEffect(() => {

    const authSub = onAuthStateChanged(auth, async (u) => {
      await setCommonHeadersAndRefreshTimeout();

      setFBUser(u);
      setFBUserLoaded(true);
    });

    return () => {
      authSub();
      setFBUser(null);
      setFBUserLoaded(false);
    };
  }, [resetCount, auth, setCommonHeadersAndRefreshTimeout]);

  // Set Firebase Analytics User Id
  const firebaseUserUid = fbUser?.uid;
  useEffect(() => {

    const analytics = getAnalytics(firebaseApp);

    if (firebaseUserUid) {
      setUserId(analytics, firebaseUserUid);
    }
    return () => {
      setUserProperties(analytics, {
        uid: null
      });
    };
  }, [firebaseUserUid]);

  const checkUserEmailVerification = useCallback(async () => {
    try {
      const auth = getAuth();

      if(!auth.currentUser){ return; }

      await reload(auth.currentUser);

      if (auth.currentUser?.emailVerified) {
        setFBUser(auth.currentUser);
        setReloadedAt(new Date());
      }
    } catch (e) {
      console.error(e);
    }
  }, [setFBUser]);


  useEffect(() => {
    let intervalId: ReturnType<typeof setTimeout>;

    if (fbUser && !fbUserEmailVerified ) {
      checkUserEmailVerification();
      intervalId = setInterval(checkUserEmailVerification, 5 * 1000); // 5s
    }
    else {
      // not need to return anything
      return;
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [reloadedAt, fbUser, fbUserEmailVerified, checkUserEmailVerification]);

  const reset = useCallback(() => {
    setFBUser(null);
    setFBUserLoaded(false);
    setResetCount((c) => c + 1);
  }, []);

  const value = useMemo(
    () => ({
      fbUser,
      fbUserLoaded,
      checkUserEmailVerification,
      fbUserEmailVerified,
      reset,
    }),
    [fbUser, fbUserLoaded, checkUserEmailVerification, fbUserEmailVerified, reset],
  );

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};
