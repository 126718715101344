import {
  createContext,
  useContext,
  useEffect,
  useCallback,
  
  useMemo,
  useState,
} from 'react';

import { getDatabase, ref, onValue, query, equalTo, orderByChild, off } from 'firebase/database';

import {
  SavedTmsiReport,
  SavedTmsiReportDoc,
  getTmsiReportDoc
} from 'api';
import {
  useAuthContext
} from 'context';

type UseReportsApi = {
  savedReportsIndex: SavedTmsiReport[];
  loadReportDoc: (docId: string) => Promise<SavedTmsiReportDoc|null>;
  reportsLoaded: boolean;
  loadedReportDocs: {
    [key: string]: SavedTmsiReportDoc
  }
}

export const UserReportsContext = createContext<UseReportsApi>(undefined!);

export const useUserReportsContext = () => useContext(UserReportsContext);

export const UserReportsProvider: React.FC = ({children}) => {
  const {fbUser} = useAuthContext();
  const [savedReportsIndex, setSavedReportsIndex] = useState<SavedTmsiReport[]>([]);
  const [loadedReportDocs, setLoadedReportDocs] = useState<{[key: string]: SavedTmsiReportDoc}>({});
  const [reportsLoaded, setReportsLoaded] = useState<boolean>(false);

  // subscribe to user's reports
  useEffect(function(){
    function processSnapsot(snapshot: any){
      if(snapshot.exists()){
        const val = snapshot.val() as {
          [key: string]: SavedTmsiReport
        };
        console.log('[UserReportsProvider] got value', val);
        const reportsArray = Object
          .entries(val)
          .sort((entry) => -entry[1].createdAt )
          .map(([key, value]) => {
          value.uid = key;
          return value;
        });
        setSavedReportsIndex(reportsArray);
      }
    }

    if(fbUser?.uid){
      const userUid = fbUser?.uid;
      const db = getDatabase();
      const savedReportsRef = ref(db, 'tmsiReports/');
      const savedUsersReportsRef = query(savedReportsRef, orderByChild('userUid'), equalTo(userUid));

      console.log('[UserReportsProvider]', {savedReportsRef, savedUsersReportsRef});

      // get(savedUsersReportsRef).then(snapshot => {
      //   console.log('[UserReportsProvider] get user report value', {userUid, snapshot});
      //   processSnapsot(snapshot);
      // });

      onValue(savedUsersReportsRef, snapshot => {
        console.log('[UserReportsProvider] on user report value', {userUid, snapshot});
        processSnapsot(snapshot);
        setReportsLoaded(true);
      });

      return () => off(savedUsersReportsRef);
    }
  }, [fbUser, setReportsLoaded]);

  const loadReportDoc = useCallback(async (docId: string) => {
    const value = await getTmsiReportDoc(docId);

    if(value){
      setLoadedReportDocs({...loadedReportDocs, [docId]: value});
    }
    
    return value;
  },[loadedReportDocs, setLoadedReportDocs]);

  const value = useMemo(() => ({
    savedReportsIndex,
    reportsLoaded,
    loadReportDoc,
    loadedReportDocs
  }), [savedReportsIndex, reportsLoaded, loadReportDoc, loadedReportDocs]);

  return <UserReportsContext.Provider value={value}>{children}</UserReportsContext.Provider>;
};