import React from 'react';

import styled from 'styled-components';

//
// import {
//   Logo
// } from 'components';
import UbietyLogo from 'assets/svgs/ubiety-logo/ubiety-mark-color.svg';

export const Footer = () => {
  return (
    <FooterContainer>
      <a href="https://roadmap.homeaware.com">Give Feedback</a>
      <a href="https://ubiety.notion.site/Knowledge-Base-13898f25d260458f878fd9ca3622a8fb">Get Support</a>
      <a href="https://www.homeaware.com/privacy-policies">
        Privacy Policy
      </a>

      <h4>POWERED BY UBIETY</h4>
      <StyledUbietyLogo src={UbietyLogo} />
    </FooterContainer>
  );
};

export default Footer;

const StyledUbietyLogo = styled.img.attrs( ({theme}) => ({
  variant: 'icon',
  height: theme.fontSize.base*2,
  width: theme.fontSize.base*2
}) )`
  vertical-align: bottom;
  margin-left: ${({theme}) => theme.boxMargins.l}px;
`;

const FooterContainer = styled.div`${({theme}) => `
  position: relative;
  padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl2}px;

  z-index: 100;
  display: flex;
  justify-content: flex-end;

  background-color: ${theme.colors.sky.lightest};

  > * {
    margin-left: ${theme.boxMargins.base}px;
  }

  a {
    line-height: ${theme.fontSize.base*2}px;
    font-size: ${theme.fontSize.base}px;
    color: ${theme.colors.ink.light};

    margin-left: ${theme.fontMargins.xl3}px;
  }

  @media (max-width: 800px){
    a {
      text-align: center;
      font-size: ${theme.fontSize.xs}px;
      line-height: auto;
      white-space: no-wrap !important;
    }
  }

  /*position: sticky;*/
  bottom: 0;

  h4 {
    line-height: ${theme.fontSize.base*2}px;
    font-size: ${theme.fontSize.base}px;
    color: ${theme.colors.ink.light};
    margin-top: 0;
    margin-bottom: 0;
    margin-left: ${theme.boxMargins.base*2}px;
    padding-left: ${theme.boxMargins.base*2}px;
    border-left: 1px solid ${theme.colors.ink.lighter};
  }
`}`;
