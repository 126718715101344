export const fontSize = {
  xxxxs: 3,
  xxxs: 5,
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  base: 16,
  l: 18,
  xl: 22,
  xl2: 24,
  xl3: 28,
  xl4: 32,
  xl5: 40,
  xl6: 46,
  xl7: 52,
  xl8: 60,
  xl9: 70,
};

export const fontFamily = {
  inter: "'Inter', sans-serif"
};

export const fontMargins = {
  xxxxs: 3,
  xxxs: 5,
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  base: 16,
  l: 18,
  xl: 20,
  xl2: 24,
  xl3: 28,
  xl4: 34,
  xl5: 40,
  xl6: 48,
  xl7: 52,
  xl8: 60,
  xl9: 70,
};

export const fontPadding = {
  xxxxs: 3,
  xxxs: 5,
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  base: 16,
  l: 18,
  xl: 22,
  xl2: 24,
  xl3: 28,
  xl4: 34,
  xl5: 40,
  xl6: 48,
  xl7: 52,
  xl8: 60,
  xl9: 70,
};