//TODO: typescript this
import React, {useRef, useEffect} from 'react';

import {UID} from '../../utils/UID.js';
import { Select, Text, Checkbox, Radio, DateInput, TextArea, Submit ,
  CheckboxGroupProps,

  SelectProps,
  CheckboxProps,
  // TextProps,
  // RadioProps,

} from './index';
import { File } from './Input.file';
import styles from './Input.module.css';

export type InputType = {
  form?: React.MutableRefObject<{
    [key:string]: any
  }>,
  disabled?: boolean,
  status?: 'accepted' | 'bad' | 'unfulfilled' | undefined,

  refference?: React.MutableRefObject<any>, // refference

  type: 'file'|'submit'|'button'|'textarea'|'select'|'checkbox'|'radio'|'date'|'text';

  label?: string,
  name?: string,
  placeholder?: string,
  defaultValue?: string,
  value?: string,

  // select
  values?: {
    [key: string]: any
  }, // |  ether one
  multiple?: boolean,

  // checkbox
  checked?: boolean,
  defaultChecked?: boolean,

  className?:string,
  id?:string,

  areaPlaceholder?: string,
  changeSelectionPlaceholder?: string,

  variant?: 'normal'|CheckboxGroupProps['variant']|SelectProps['variant']|CheckboxProps['variant']; //|TextProps['variant']|RadioProps['variant']|SelectBoxProps['variant'],

  // actions
  onChange?: (e: React.ChangeEvent<any>) => void,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,

  //
  onFocus?: (e: React.FocusEvent<any>) => void,
  onBlur?: (e: React.FocusEvent<any>) => void,

  // datePicker
  onFormattedValue?: (value: Date) => void;
}
export const Input: React.FC<InputType> = ({

  form,
  disabled=false,
  status, //

  refference, // refference

  type,
  name='',
  placeholder,
  defaultValue,
  value,

  // select
  values, // |  ether one
  multiple,

  // checkbox
  checked,
  defaultChecked,

  className:classNameInput='',
  id:passedId='',

  areaPlaceholder,
  changeSelectionPlaceholder,

  variant:passedVariant='normal',

  // actions
  onChange,
  onClick,

  // hooks
  onFocus,
  onBlur,

  // date
  onFormattedValue

}) => {

  const defaultRef = useRef();
  const el = refference || defaultRef;
  const className = 'input '+(disabled?styles.disabled+' ':'')+(classNameInput||'');

  const id=useRef(passedId||UID());

  const variant = passedVariant as any;

  // hook it to passed form object
  useEffect(function(){
    if(form?.current){
      form.current[name] = el;
    }
  },[el, form, name]);

  return type === 'file'
    ?
    <File
      el={el}
      disabled={disabled}
      name={name}
      id={id.current}
      placeholder={placeholder}
      className={className}

      areaPlaceholder={areaPlaceholder}
      changeSelectionPlaceholder={changeSelectionPlaceholder}

      onChange={onChange}
      multiple={multiple} />
    // SUBMIT
    :
    type === 'submit'
    ?
    <Submit
      el={el}
      disabled={disabled}
      id={id.current}
      className={className}
      onClick={onClick}
      value={value} />
    // TEXAREA
    :
    type === 'textarea'
    ?
    <TextArea
      el={el}
      disabled={disabled}
      id={id.current}
      name={name}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value} />
    // SELECT
    :
    type === 'select'
    ?
    <Select
      el={el}
      disabled={disabled}
      status={status}
      variant={variant}
      name={name}
      id={id.current}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      values={values} />
    // CHECKBOX
    :
    type === 'checkbox'
    ?
    <Checkbox
      el={el}
      disabled={disabled}
      name={name}
      variant={variant}
      id={id.current}
      className={className}
      onChange={onChange}
      checked={checked}
      defaultChecked={defaultChecked}
      value={value} />
    // RADIO
    :
    type === 'radio'
    ?
    <Radio
      el={el}
      disabled={disabled}
      name={name}
      id={id.current}
      className={className}
      onChange={onChange}
      checked={checked}
      defaultChecked={defaultChecked}
      value={value} />
    // DATE
    :
    type === 'date'
    ?
    <DateInput
      el={el}
      disabled={disabled}
      status={status}
      name={name}
      id={id.current}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onFormattedValue={onFormattedValue}
      defaultValue={defaultValue}
      value={value} />
    :
    // TEXT
    // DEFAULT
    <Text
      el={el}
      disabled={disabled}
      status={status}
      name={name}
      type={type}
      id={id.current}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value} />;
};

export default Input;
