import React, { Suspense } from 'react';

import { Route, Routes, Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Loading ,
  OverlayDisplay
} from 'components';
import {
  AuthProvider,
  AccountProvider,
  GraphQLProvider,
  OverlayProvider,
  UserReportsProvider,
  ConfigProvider
} from 'context';
import { GlobalStyle , ContentWrapper } from 'layout';
import {
  AuthWrapper,
  Landing,
  TMSIDashboard,
  TMSIReport,
  NewTMSIExport,
  ActivityReport,
  TmsiActivity,
} from 'pages';
import * as theme from 'theme';
import {
  ScrollToTop
} from 'utils';

import {
  EventsHandler
} from './EventsHandler';

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <AccountProvider>
          <GraphQLProvider>
            <OverlayProvider>
             <ConfigProvider>
              <AppRoutes />
             </ConfigProvider>
            </OverlayProvider>
          </GraphQLProvider>
        </AccountProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

const AppRoutes: React.FC = () => (<>
  <ScrollToTop />
  <EventsHandler />
  <Routes>
    <Route path='*' element={<AuthWrapper />}>
      <Route path='*' element={<Pages />}>

        <Route index element={<Landing />} />
        <Route path="tmsi-dashboard" element={<TMSIDashboard />} />
        <Route path='tmsi-report' element={<UserReportsProvider><Outlet /></UserReportsProvider>}>
          <Route index element={<TMSIReport />} />
          <Route path='new-tmsi-export' element={<NewTMSIExport />} />
        </Route>
        <Route path=''>
          <Route path="activity-report" element={<ActivityReport />}/>
          <Route path="tmsi-activity-report" element={<TmsiActivity />}/>
        </Route>
      </Route>
    </Route>

  </Routes>
  </>
);

// routes that we want to explicitly name
// see https://github.com/icd2k3/use-react-router-breadcrumbs#usage for details
export const NamedRoutes = [
  {path: '/tmsi-report', breadcrumb: 'TMSI Report'},
  {path: '/tmsi-report/new-tmsi-export', breadcrumb: 'New TMSI Export'}
];

const Pages: React.FC = () => (
  <ContentWrapper>
    <Suspense fallback={<Loading />}>

      <Outlet />
      <OverlayDisplay />

    </Suspense>
  </ContentWrapper>
);
