import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

//import NetInfo from '@react-native-community/netinfo';
//import analytics from '@react-native-firebase/analytics';
import * as Sentry from '@sentry/react';
import { getAnalytics, setUserProperties } from 'firebase/analytics';

import {
  getUser as getUserData,
  getAccount as getAccountData,
//  updateUserDeviceInfo,
} from 'api';
import { useAuthContext } from 'context';
import { firebaseApp } from 'index';
import { Account, User } from 'types';
import { 
  //getDeviceData, 
  processRawAccount, 
  processRawUser } from 'utils';

//import { useErrorDataContext } from './ErrorDataContext';
type LoadingError = {
  message: string;
  error: Error;
};

export const AccountContext = createContext<{
  user?: User;
  userLoaded: boolean;
  account?: Account;
  accountLoaded: boolean;
  allAccountDataLoaded: boolean;
  getAllData: () => Promise<{
    user?: User;
    account?: Account;
  }>;
  getUser: () => Promise<User | undefined>;
  getAccount: () => Promise<Account | undefined>;
  reset: () => void;

  userError: LoadingError | null;
  accountError: LoadingError | null;
  dataError: LoadingError | null;
}>(undefined!);
export const useAccountContext = () => useContext(AccountContext);

export const AccountProvider: React.FC = (props) => {
  const { fbUser, fbUserEmailVerified } = useAuthContext();
  const firebaseUserUid = fbUser?.uid;
  const firebaseUserEmail = fbUser?.email;

  const [user, setUser] = useState<User>();
  const [userLoaded, setUserLoaded] = useState(false);

  const [account, setAccount] = useState<Account>();
  const [accountLoaded, setAccountLoaded] = useState(false);

  const [userError, setUserError] = useState<LoadingError|null>(null);
  const [accountError, setAccountError] = useState<LoadingError|null>(null);
  const [dataError, setDataError] = useState<LoadingError|null>(null);

  const allAccountDataLoaded = useMemo( () => accountLoaded && userLoaded, [accountLoaded, userLoaded] );

  // Set Sentry User Information
  useEffect(() => {
    const setSentryUserInformation = async () => {
      if (firebaseUserUid && firebaseUserEmail) {
        Sentry.setUser({
          id: firebaseUserUid,
          email: firebaseUserEmail,
          // TODO: potentially we can use 3rd party service to set a value on this
          // or impletement out own
          ip_address: undefined,
        });
      }
    };
    setSentryUserInformation();
    return () => {
      Sentry.configureScope((scope) => scope.setUser(null));
    };
  }, [firebaseUserUid, firebaseUserEmail]);

  // Set Firebase Analytics User Properties
  useEffect(() => {
    
    const analytics = getAnalytics(firebaseApp);

    // Set Analytics
    // TODO: Determine if there is a better way to clear
    if (firebaseUserUid && firebaseUserEmail && account?.uid) {
      setUserProperties(analytics, {
        // TODO: Deprecate account_id here (Ask Sam and Josh about backend routes referencing this and update firebase in all envs to look for the new key)
        account_id: account?.uid,
        accountUid: account?.uid,
      });
    }
    return () => {
      setUserProperties(analytics, {
        account_id: null,
        accountUid: null,
      });
    };
  }, [firebaseUserUid, firebaseUserEmail, account?.uid]);

  const getAllData = useCallback(async () => {

    try {
      // firebaseUserUid is required to proceed;
      if(!firebaseUserUid){
        throw new Error('No Firebase User UID');
      }

      const userDataResponse = await getUserData(firebaseUserUid);
      const userData = processRawUser(userDataResponse?.data?.Data);
      setUser(userData);
      setUserLoaded(true);

      // TODO:
      // Currently assuming only one account per user
      //  In the future this will be updated and the data flow will shift
      if (userData?.permissions && userData.permissions.length > 0) {
        const { account_id } = userData.permissions[0];
        const accountDataResponse = await getAccountData(account_id!);
        const accountData = processRawAccount(accountDataResponse?.data?.Data);
        setAccount(accountData);
        setAccountLoaded(true);
        return { user: userData, account: accountData };
      }
      return { user: userData };
    } catch (e) {
      console.error('[AccountContext] Data loading error occured', e);
     
      setDataError({
        message: 'Error loading user data',
        error: e as Error
      });
       // we behave and dont throw shit at people.
      return {};
    }

  }, [firebaseUserUid]);

  const getUser = useCallback(async () => {
    try {
      if (!firebaseUserUid) {
        throw new Error('No Firebase User UID');
      }
      const userDataResponse = await getUserData(firebaseUserUid);
      const userData = processRawUser(userDataResponse?.data?.Data);
      setUser(userData);
      setUserLoaded(true);
      return userData;
    } catch (e) {
      console.error('[AccountContext] User loading error occured:', e);
      setUserError({
        message: 'Error loading user',
        error: e as Error
      });
      return;
    }
  }, [firebaseUserUid]);

  const getAccount = useCallback(async () => {
    try {
      if (!account?.uid) {
        throw new Error('No Account Id');
      }
      const accountDataResponse = await getAccountData(account.uid);
      const accountData = processRawAccount(accountDataResponse?.data?.Data);
      setAccount(accountData);
      setAccountLoaded(true);
      return accountData;
    } catch (e) {
      console.error('[AccountContext] Account  loading error occured', e);
      setAccountError({
        message: 'Error loading account',
        error: e as Error
      });
      return;
    }
  }, [account?.uid]);

  const reset = useCallback(() => {
    setUser(undefined);
    setUserLoaded(false);
    setAccount(undefined);
    setAccountLoaded(false);
    setUserError(null);
    setAccountError(null);
    setDataError(null);
  }, []);

  // Load data on user change if user meets our criteria
  useEffect(() => {
    if(fbUser && fbUserEmailVerified){
      getAllData();
    } 
    return reset;
  }, [firebaseUserUid, fbUser, fbUserEmailVerified, getAllData, reset]);

  const value = useMemo(
    () => ({
      user,
      userLoaded,
      account,
      accountLoaded,
      allAccountDataLoaded,
      getAllData,
      getUser,
      getAccount,
      reset,

      userError,
      accountError,
      dataError,
    }),
    [
      user,
      userLoaded,
      account,
      accountLoaded,
      allAccountDataLoaded,
      getAllData,
      getUser,
      getAccount,
      reset,

      userError,
      accountError,
      dataError,
    ],
  );

  return (
    <AccountContext.Provider value={value}>
      {props.children}
    </AccountContext.Provider>
  );
};
