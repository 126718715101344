import { useEffect, useState } from 'react';

import { Amplify } from 'aws-amplify';

import { 
  MQTTResponse,
  RfVisionRawCell,
  RfVisionCellScan,
  RfVisionBtAdvPacket,
  RfVisionWifiProbeRequest
} from 'types';

export interface MQTTHookConfig<T> {
  onNext?: (data: T) => void;
  onError?: (error: Error) => void;
  onClose?: () => void;
  onEffect?: () => void;
  onEffectCleanup?: () => void;
  shouldBeSubscribed?: boolean;
}
// TODO: Add "collect" argument to collect all data
// TODO: Add "autoreconnect" arguement to automatically manage reconnection attempts
export const useMQTT = <T>(
  route: string | string[],
  config?: MQTTHookConfig<T>,
) => {
  const {
    onNext,
    onError,
    onClose,
    onEffect,
    onEffectCleanup,
    shouldBeSubscribed = true,
  } = config || {};
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    console.log('[useMQTT] useEffect run');

    let sub: any | undefined;
    if (shouldBeSubscribed) {

      sub = Amplify.PubSub.subscribe(route).subscribe({
        next: (data: MQTTResponse<T>) => {
          // console.log((new Date()).toLocaleTimeString()+' [mqtt] on next called', {
          //   route,
          //   data
          // });
          onNext?.(data.value);
        },
        error: (e: Error) => {
          // console.log((new Date()).toLocaleTimeString()+' [mqtt] error', {
          //   route,
          //   error: e
          // });
          console.log(e);
          onError?.(e);
        },
        close: () => {
          // console.log((new Date()).toLocaleTimeString()+' [mqtt] route closed', {
          //   route
          // });
          setClosed(true);
          onClose?.();
        },
      });
      onEffect?.();
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
      setClosed(false);
      onEffectCleanup?.();
    };
  }, [
    route,
    onNext,
    onError,
    onClose,
    onEffect,
    onEffectCleanup,
    shouldBeSubscribed,
  ]);

  return { closed };
};
export const useMQTTCellRaw = (
  sensorId: string | number,
  config?: MQTTHookConfig<RfVisionRawCell>,
) => {
  return useMQTT<RfVisionRawCell>(
    `rf_vision/sensor/${sensorId}/cell`,
    config,
  );
};
export const useMQTTCellScan = (
  sensorId: string | number,
  config?: MQTTHookConfig<RfVisionCellScan>,
) => {
  return useMQTT<RfVisionCellScan>(
    `rf_vision/sensor/${sensorId}/cell-scan`,
    config,
  );
};
// export const useMQTTWiFiNetworked = (
//   sensorID: string | number,
//   config?: MQTTHookConfig<WifiNetworkedPayload>,
// ) => {
//   return useMQTT<WifiNetworkedPayload>(
//     `${ENV.environment}/sensor/${sensorID}/data/wifi/networked`,
//     config,
//   );
// };
export const useMQTTWiFiProbeRequests = (
  sensorId: string | number,
  config?: MQTTHookConfig<RfVisionWifiProbeRequest>,
) => {
  return useMQTT<RfVisionWifiProbeRequest>(
    `rf_vision/sensor/${sensorId}/passive-wifi`,
    config,
  );
};
export const useMQTTBluetoothAdvPackets = (
  sensorId: string | number,
  config?: MQTTHookConfig<RfVisionBtAdvPacket>,
) => {
  return useMQTT<RfVisionBtAdvPacket>(
    `rf_vision/sensor/${sensorId}/passive-bt`,
    config,
  );
};
