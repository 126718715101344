import React, {useState, useCallback, useRef, useEffect} from 'react';

import {
  format
} from 'date-fns';
import styled from 'styled-components';

import {UID} from 'utils';

import {
  DatePickerPane,
  DatePickerApi,
  makeDateObj
} from './index';
import styles from './Input.module.css';

export type DateInputProps = {
  name?:string;
  placeholder?:string;
  defaultValue?:string;
  value?:string;
  id?:string;
  className?:string;
  disabled?: boolean;
  el?: React.MutableRefObject<HTMLInputElement>;

  status?: 'accepted' | 'bad' | 'unfulfilled' | undefined;
  onFormattedValue?: (date: Date) => void;
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e:React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e:React.FocusEvent<HTMLInputElement>) => void;
}
// Input types
export const DateInput: React.FC<DateInputProps> = ({
  name='',
  placeholder,
  defaultValue,
  value: passedValue,
  id=UID(),
  className='',
  disabled=false,
  el=null,
  status,
  onFormattedValue,
  onChange,
  onFocus,
  onBlur
}) => {
  const [value, setValue] = useState(passedValue||defaultValue||'');
  const [paneShown, setPaneShown] = useState<Date|false>(false);
  const [buttonClicked, setButtonClicked] = useState<Date|null>(null);
  const datepickerApiRef = useRef<DatePickerApi>(null);
  const localElRef = useRef<HTMLInputElement>(null);
  const elRef = el||localElRef;

  const localPropagationBlockedRef = useRef<boolean>(false);
  // set hiding panel on outside click
  useEffect(function(){
    const hidePane = function(){
      if(!localPropagationBlockedRef.current){
        setPaneShown(false);
      } else {
        localPropagationBlockedRef.current = false;
      }
    };
    window.addEventListener('click', hidePane);

    return function(){
      window.removeEventListener('click', hidePane);
    };
  },[setPaneShown]);

  const handleValue = useCallback(function(e){
    const val = e.target.value;
    const pointer = e.target.selectionEnd;

    const formatted = val
      .replace(/[^/\d]|[/](?!$)/g,'') // this replaces everything non digit unless its forwardslash the very end (no space after it) //.replace(/\D/g,'')
      .replace(/^((\d{2}){0,}\d)\//g,'$1') // make sure that forwardshlashes are only after even number of digits
      .replace(/(.{0,6}).*/g,'$1') // replace everything over 6 digits
      .replace(/(\d{2})(?=(\d))/g, '$1/'); // add forward slashes

    const typedMonth = Number(val.replace(/^(\d{2}).*/g,'$1'))-1;

    if(typedMonth<12&&typedMonth>-1){
      datepickerApiRef.current?.setSelectedMonth?.(typedMonth);
    }

    const typedYear = val.replace(/((\d{2}\D*){2}(\d{2}))?.*/g,'$3');

    if(typedYear) {
      datepickerApiRef.current?.setSelectedYear?.(Number(20+typedYear));
    }

    const isCompleteDate = formatted.length === 8 && !isNaN(Number(new Date(formatted)));

    if(isCompleteDate){
      const selectedDate = new Date(formatted);
      onFormattedValue?.(selectedDate);
      datepickerApiRef.current?.setSelectedDate( makeDateObj(selectedDate).str );
    }

    e.target.value = formatted;
    if(pointer<val.length){
      e.target.setSelectionRange(pointer,pointer);
    }

    onChange?.(e);
    setValue(formatted);
  },[
    setValue,
    onChange,
    onFormattedValue
  ]);

  return <InputContainer
    className={'input-container datePicker'+(paneShown?' paneShown':' paneHidden')}
    onClick={() => {
      localPropagationBlockedRef.current = true;
    }}
    >

    <StyledInput
      key={id}
      id={id}
      className={className+' '+styles.input}
      ref={elRef}

      disabled={disabled}
      type="text"
      name={name}
      placeholder={placeholder}
      value={value}

      status={status}

      onChange={handleValue}
      onFocus={(e) => {
        if(!paneShown){
          setPaneShown(new Date());
        }
        onFocus?.(e);
      }}
      onBlur={(e) => {
        onBlur?.(e);
      }}
      onKeyDown={(e) => {
        if(e.code === 'Tab'){
          setPaneShown(false);
        } else if(e.key === 'Enter'){
          elRef.current?.blur();
          setPaneShown(false);
        }
      }}
      />

    <div className="showCalendarTrigger"
      onClick={() => {
        const now = new Date();
        setPaneShown(paneShown === buttonClicked ? false : now);
        setButtonClicked(now);
      }} />

    <DatePickerPane
      apiRef={datepickerApiRef}
      onSelect={([v]) => {
        const newValue = format(v, 'MM/dd/yy');
        if(elRef.current){
          const el = elRef.current;

          el.value = newValue;
          el.blur();
        }

        setValue(newValue);
        onFormattedValue?.(new Date(newValue));

        setTimeout(function(){
          setPaneShown(false);
        }, 100);
      }}
       />

  </InputContainer>;
};

const InputContainer = styled.div`${({theme}) => `
  .datePickerIcon {
    position: static;
    height: 0 !important;
    overflow: visible;
    display: block;

    > svg {
      vertical-align: middle;
    }
    cursor: pointer;
  }

  .datePickerIcon {
    position: static;
  }

  .datePickerIcon > svg {
    display: none;
  }

  .datePickerPane {
    position: absolute;
    left: -${theme.boxPadding.xl3}px;
    top: 100%;
    margin-top: 10px;
  }
  .datePickerPane * {

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  }
  &.paneShown .datePickerPane {

  }
  &.paneHidden .datePickerPane {
    display: none;
  }

  .showCalendarTrigger {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 0 !important;
    cursor: pointer;

    width: 50px
  }


  position: relative;
  diplay: inline-block;

`}`;

const StyledInput = styled.input.attrs({} as {
  status: string;
})`
  display: block;
  border: 1px solid ${({theme}) => theme.colors.sky.light};
  padding: ${({theme}) => theme.fontPadding.base}px;
  border-radius: ${({theme}) => theme.fontPadding.base/2}px;
  font-family: ${({theme}) => theme.fontFamily.inter};
  font-size: ${({theme}) => theme.fontSize.base}px;
  color: ${({theme}) => theme.colors.ink.darkest};

  &::placeholder {
    color: ${({theme}) => theme.colors.ink.lighter};
  }
  &:-ms-input-placeholder{
    color: ${({theme}) => theme.colors.ink.lighter};
  }
  &::-ms-input-placeholder {
    color: ${({theme}) => theme.colors.ink.lighter};
  }


  /* remove default icon */
  ::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    display: none;
  }


  /* add out own icon */
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");


  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position */
  background-position: right 1em top 50%;

  /* icon size, then gradient */
  background-size: 1em auto;

  outline: none;

  outline-offset: -2px;
  outline-width: 2px;

  &:focus {
    box-shadow: 0 0 10px rgba(${({status, theme}) =>
      (!status && theme.colorsRgb.blue.base.toString())
      || (status === 'accepted' && theme.colorsRgb.blue.base.toString())
      || (status === 'unfulfilled' && theme.colorsRgb.orange.base.toString())
      || (status === 'bad' && theme.colorsRgb.red.base.toString()) }, 0.7);
  }

  ${({status, theme}) => !status && `
    outline-color: rgba(${theme.colorsRgb.blue.base}, 0.7);
  `}
  ${({status, theme}) => status === 'accepted' && `
    outline-color: rgba(${theme.colorsRgb.blue.light}, 0.7);

    outline-style: solid;
  `}

  ${({status, theme}) => status === 'unfulfilled' && `
    outline-color: rgba(${theme.colorsRgb.orange.light}, 0.7);
    outline-style: solid;
  `}

  ${({status, theme}) => status === 'bad' && `
    outline-color: rgba(${theme.colorsRgb.red.light}, 0.7);
    outline-style: solid;
  `}

  &:focus {
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid;

    ${({status, theme}) => !status && `
      outline-color: rgba(${theme.colorsRgb.blue.base}, 0.5);
    `}
  }

}`;
