import React from 'react';

import styled from 'styled-components';

import styles from './Input.module.css';

export type PlainFormProps = {
  condenced?:boolean;
  refference?:React.RefObject<HTMLFormElement>;
  warnHeader?:React.ReactNode;
  infoHeader?:React.ReactNode;
  successHeader?:React.ReactNode;
}
export const PlainForm: React.FC<PlainFormProps & React.HTMLProps<HTMLFormElement>> = ({
  refference,
  condenced=false,
  className='',
  warnHeader,
  infoHeader,
  successHeader,
  onSubmit,
  children
}) =>
  <StyledForm
    ref={refference||null}
    condenced
    className={(condenced?' '+styles.condenced:'')+' '+className}
    onSubmit={onSubmit} >
    {warnHeader
      ?
      <WarnHeader>{warnHeader}</WarnHeader>
      :
     infoHeader
      ?
      <InfoHeader>{infoHeader}</InfoHeader>
      :
     successHeader
      ?
      <SuccessHeader>{successHeader}</SuccessHeader>
      :
      null
    }

    {children}
  </StyledForm>;

const InfoHeader = styled.div`
  background-color: ${({theme}) => theme.colors.blue.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const WarnHeader = styled.div`
  background-color: ${({theme}) => theme.colors.red.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const SuccessHeader = styled.div`
  background-color: ${({theme}) => theme.colors.green.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const StyledForm = styled.form.attrs({} as PlainFormProps)`${({theme, condenced}) => `

  overflow: hidden;

  font-family: ${theme.fontFamily.inter};

  input:not([type="checkbox"]):not([type="radio"]),
  select {
    min-width: 327px;
    box-sizing: border-box;
  }
  .input-container {
    position: relative;
  }

  .type-checkbox.input-group:not(.variant-pill) {
    display: flex;
    align-items: center;

    padding: ${theme.boxPadding.m}px ${theme.boxPadding.base}px;
  }
  .type-checkbox.input-group > input:first-child {
    margin-right: ${theme.boxPadding.base}px;
  }
  .type-checkbox.input-group > .input {
    flex-shrink: 0;
  }

  .type-checkbox label {
    margin: 0;
  }

  ${condenced ? `
    label {
      font-weight: normal !important;
    }
    input:not([type="checkbox"]):not([type="radio"]),
    input:not([type="checkbox"]):not([type="radio"]) + *,
    select {
      margin-top: ${theme.fontMargins.s}px;
    }
    .input-group a {
      margin-top: ${theme.fontMargins.s}px;
    }
  `:''}

`}`;
