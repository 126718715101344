import { GraphQLSensorEdge } from 'api/data/globalGraphQL';
import { Maybe, Sensor, SensorWifiSignalStrength } from 'types';
import { isPresent } from 'utils/typescript';

export const processRawSensorArray = (
  rawSensorArray: Maybe<GraphQLSensorEdge>[],
) => {
  const newSensorArray: Sensor[] = rawSensorArray
    ?.map((e) => {
      if (!e?.node) {
        return null;
      }

      const { sensorHealth, ...rest } = e.node;

      let wifiSignalStrengthString: SensorWifiSignalStrength = 'waiting...';
      if (sensorHealth?.wifiSignalLevel) {
        if (sensorHealth.wifiSignalLevel <= -70) {
          wifiSignalStrengthString = 'WEAK';
        } else if (
          sensorHealth.wifiSignalLevel > -70 &&
          sensorHealth.wifiSignalLevel <= -40
        ) {
          wifiSignalStrengthString = 'OKAY';
        } else if (sensorHealth.wifiSignalLevel > -40) {
          wifiSignalStrengthString = 'GOOD';
        }
      }

      return {
        sensorType: sensorHealth?.sensorType,
        softwareVersion: sensorHealth?.softwareVersion,
        wifiIp: sensorHealth?.wifiIp,
        wifiMac: sensorHealth?.wifiMac,
        wifiQuality: sensorHealth?.wifiQuality,
        wifiCanPingInternet: sensorHealth?.wifiCanPingInternet,
        wifiFrequency: sensorHealth?.wifiFrequency,
        wifiSignalLevel: sensorHealth?.wifiSignalLevel,
        wifiSignalStrengthString,
        wifiSsid: sensorHealth?.wifiSsid,
        ...rest,
      };
    })
    .filter(isPresent);

  // Active sensor is the most recently setup and active
  const newActiveSensor: Sensor | undefined = newSensorArray
    .sort((a, b) => (b.setupTimestamp ?? 0) - (a.setupTimestamp ?? 0))
    .find((sensor) => sensor.active && sensor.setupTimestamp);

  const newSensors: Record<string, Sensor> = {};
  newSensorArray.forEach((s) => {
    newSensors[s.uid] = s;
  });

  return {
    newActiveSensor,
    newSensorArray,
    newSensors,
  };
};
