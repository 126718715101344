import React from 'react';

import styled from 'styled-components';

import {UID} from 'utils';

import styles from './Input.module.css';

export type TextProps = {
  type?:string;
  name?:string;
  placeholder?:string;
  defaultValue?:string;
  value?:string;
  id?:string;
  className?:string;
  disabled?: boolean;
  el?: React.MutableRefObject<HTMLInputElement>;

  status?: 'accepted' | 'bad' | 'unfulfilled' | undefined;

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
// Input types
export const Text: React.FC<TextProps> = ({
  type='text',
  name='',
  placeholder,
  defaultValue,
  value,
  id=UID(),
  className='',
  disabled=false,
  el=null,
  status,

  onChange,
}) =>

  <StyledInput

    key={id}
    id={id}
    className={className+' '+styles.input}
    ref={el||null}

    disabled={disabled}
    type={type}
    name={name}
    placeholder={placeholder}
    value={value}
    defaultValue={defaultValue}

    status={status}

    onChange={onChange}

    />;


const StyledInput = styled.input.attrs({} as {
  status: string;
})`
  border: 1px solid ${({theme}) => theme.colors.sky.light};
  padding: ${({theme}) => theme.fontPadding.base}px;
  border-radius: ${({theme}) => theme.fontPadding.base/2}px;
  font-family: ${({theme}) => theme.fontFamily.inter};
  font-size: ${({theme}) => theme.fontSize.base}px;
  color: ${({theme}) => theme.colors.ink.darkest};

  &::placeholder {
    color: ${({theme}) => theme.colors.ink.lighter};
  }
  &:-ms-input-placeholder{
    color: ${({theme}) => theme.colors.ink.lighter};
  }
  &::-ms-input-placeholder {
    color: ${({theme}) => theme.colors.ink.lighter};
  }

  outline: none;

  outline-offset: -2px;
  outline-width: 2px;

  &:focus {
    box-shadow: 0 0 10px rgba(${({status, theme}) =>
      (!status && theme.colorsRgb.blue.base.toString())
      || (status === 'accepted' && theme.colorsRgb.blue.base.toString())
      || (status === 'unfulfilled' && theme.colorsRgb.orange.base.toString())
      || (status === 'bad' && theme.colorsRgb.red.base.toString()) }, 0.7);
  }

  ${({status, theme}) => !status && `
    outline-color: rgba(${theme.colorsRgb.blue.base}, 0.7);
  `}
  ${({status, theme}) => status === 'accepted' && `
    outline-color: rgba(${theme.colorsRgb.blue.light}, 0.7);

    outline-style: solid;
  `}

  ${({status, theme}) => status === 'unfulfilled' && `
    outline-color: rgba(${theme.colorsRgb.orange.light}, 0.7);
    outline-style: solid;
  `}

  ${({status, theme}) => status === 'bad' && `
    outline-color: rgba(${theme.colorsRgb.red.light}, 0.7);
    outline-style: solid;
  `}

  &:focus {
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid;

    ${({status, theme}) => !status && `
      outline-color: rgba(${theme.colorsRgb.blue.base}, 0.5);
    `}
  }
}`;
