//TODO: typescript this
import React, {useRef, useState} from "react";

import styles from "./Input.module.css";
import UID from "../../utils/UID.js";

import {makeEvent} from "../../utils/domHelpers.js";

export const File = ({
    disabled,

    name,
    el, 
    id=('a'+UID()), 
    className,
    placeholder="Attach Files",
    areaPlaceholder="Drop Files Here", 
    changeSelectionPlaceholder="Change Selection", 
    multiple=true,
    ...args}) => {
  
  const [files, setFiles] = useState([]);

  function onChange(e){
    //console.log('files changed', e, e.target.files)

    if(typeof args.onChange === 'function') args.onChange(e);

    if(e.isDefaultPrevented()) return;

    //console.log('files changed', e, e.target.files)
    const files = multiple ? [...e.target.files] : e.target.files[0] ? [e.target.files[0]] : [];

    //console.log('files', files, files.length)

    setFiles(files)
  }

  return (
    <div className={className+' '+styles.fileContainer+' input'}>
      
      <input disabled={disabled} name={name} ref={el} type="file" id={id} multiple={multiple}
        {...args}
        onChange={onChange}
        />

      {areaPlaceholder ? 
        <p className={styles.fileAreaPlaceHolder}>{areaPlaceholder}</p> : null}

      <label htmlFor={id} className={styles.fileHook}>{files.length ? changeSelectionPlaceholder: placeholder}</label><br />
      
      {[...Array(files.length)].map( (none, i) => 
          <React.Fragment key={(files[i].name+"").replace(/[^a-zA-Z0-9]/g,'')}>
          <span className={styles.fileName}>{files[i].name}</span>

          {(i < files.length-1 ? ', ' : '')}
          </React.Fragment>
        )}
    </div>
  )
}

export default File;