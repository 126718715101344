//TODO: typescript this
import React, {
  useRef,
  useMemo,
  //useState,
  DragEvent} from 'react';

import {makeEvent} from '../../utils/domHelpers.js';
import {UID} from '../../utils/UID.js';
import {
  Input,
  Label,
  SubLabel,
  CheckboxGroup,
  CheckboxGroupProps,

  // SelectProps,
  // CheckboxProps,
  // TextProps,
  // RadioProps,
  // SelectBoxProps,
  InputType

} from './index';
import styles from './Input.module.css';

export type FormIndex = {
  [key: string]: React.MutableRefObject<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>
}

export type InputGroupType = {
  type: 'filedrop'|'file'|'submit'|'button'|'textarea'|'select'|'checkbox'|'radio'|'date'|'text'|string;
  form?: React.MutableRefObject<FormIndex>;
  name?: string;

  id?: string;
  className?: string;

  label?: string;
  sublabel?: string;

  variant?: 'normal'|CheckboxGroupProps['variant']|InputType['variant']; //|TextProps['variant']|RadioProps['variant']|SelectBoxProps['variant'],

  // options
  disabled?: boolean;
  reverse?: boolean;
  condenced?: boolean;
} & Omit<InputType, 'type'>

export const InputGroup: React.FC<InputGroupType> = ({
  type: passedType,
  form,
  id:passedId='a'+UID()+'b',
  className:classNameInput='',
  name=UID(),
  label='',
  sublabel='',
  variant='normal',
  // options
  disabled=false,
  reverse=false,
  condenced=false,
  children,

  ...args}) => {

  const [Comp, type] = useMemo(function(){
    switch (passedType){
      case 'filedrop':
        return [Filedrop, 'file'];
      case 'checkbox':
        return [CheckboxGroup, 'checkbox'];
      default:
        return [Def, passedType];
    }
  },[passedType]);

  const id = useRef(passedId);

  const className = useMemo(() =>
    (disabled?styles.disabled+' ':'')+(classNameInput||'')+(condenced?' '+styles.condenced:'')+' type-'+type+' variant-'+variant,
    [
      disabled,
      classNameInput,
      condenced,
      type,
      variant
    ]);

  const body: any[] = useMemo(() => [
    <Label key={id.current+'label'} htmlFor={id.current}>{label}{sublabel?<SubLabel>{sublabel}</SubLabel>:null}</Label>,
    <Input
      key={id.current+'input'}
      type={type as any}
      form={form}
      name={name}
      id={id.current}
      disabled={disabled}
      variant={variant}
      {...args} />
  ], [
    label,
    sublabel,
    id,
    type,
    form,
    name,
    disabled,
    variant,
    args,
  ]);

  if(reverse ||
    // force reverce for pill checkbox
    (type === 'checkbox' && variant === 'pill')){

    body.reverse();
  }

  if(children){
    body.push(children);
  }

  return (
    <Comp
      variant={variant as any}
      className={' input-group ' + className+' '+styles.inputGroup}>

      {body}

    </Comp>
    );
};

export default InputGroup;

type DefaultGroupType = Record<string, unknown>;
// Input group types
const Def: React.FC<DefaultGroupType> = ({
  children,
  ...args}) =>

  <div {...args}>{children}</div>;

export type FileDropType = {
  inputElRef?: React.MutableRefObject<HTMLInputElement>;

  className?: string;
}
const Filedrop: React.FC<FileDropType> = ({
  inputElRef,

  className,

  children,

  ...args}) => {

  const container = useRef<HTMLDivElement>(null);

  let inoutcount = 0; // nothing entered, nothing left
  let timeout: ReturnType<typeof setTimeout>;

  function toggleClass(
    el: HTMLElement|null,
    className: string,
    toAdd?: boolean){
    if(!el){ return; }
    const classes = el.getAttribute('class')||'';
    const shouldAdd = toAdd !== undefined
      ? toAdd
      : (new RegExp(className)).exec(classes)
        // if found -> aim to remove
        ? false
        // if not found -> aim to add
        : true;

    el.setAttribute( 'class', classes.replace(className, '').replace(/\s{2,}/, ' ') + (shouldAdd ? ' '+className : ''));
  }

  function onDrop(e: DragEvent<HTMLDivElement>){
    //console.log('DROPED', e, form, name);
    e.stopPropagation();
    e.preventDefault();

    const inputEl = inputElRef?.current;

    if(inputEl){
      // set files
      const files = e.dataTransfer.files;

      inputEl.files = files;

      // fire an event
      const changeEvent = makeEvent('change', true, false);

      inputEl.dispatchEvent(changeEvent);
    }
  }

  function onEnterLeave(e: DragEvent<HTMLDivElement>){
    // it might have left into a child element, in which case 'enter' on it will be fired before 'leave' on parent. think about it
    inoutcount = inoutcount + (e.type === 'dragenter' ? 1 : -1);
    // timeout prevents ultiple class changes when traveling though children elements
    clearTimeout(timeout);
    timeout = setTimeout( () => toggleClass(container.current, 'dragover', inoutcount !== 0), 10 );
  }

  return (
    <div
      ref={container}
      className={(className||'')+' '+styles['input-filedrop-container']}
      onDragOver={ e => e.preventDefault() }
      onDragEnter={ onEnterLeave }
      onDragLeave={ onEnterLeave }
      onDrop={ (e)=> {
        onDrop(e);
        toggleClass(container.current, 'dragover', false);
      }}
      {...args}>

      {children}

    </div>
    );
};
