import {useEffect} from 'react';

import {
  useAuthContext
} from 'context';
import {
  perSessionStorage
} from 'storage';

export const EventsHandler: React.FC = () => {
  const { fbUser } = useAuthContext();

  // clear local storage if user empty
  useEffect(function(){
    if(!fbUser){
      perSessionStorage.clear();
    }
  },[fbUser]);

  return null;
};
