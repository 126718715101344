import './index.css';
import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`${({theme}: any) => `
  
  /* Headers */

  h1 > svg,
  h2 > svg,
  h3 > svg,
  h4 > svg,
  h5 > svg,
  h6 > svg {
    vertical-align: bottom;
    margin-bottom: 2px;
  }

  h1.subheading,
  h2.subheading,
  h3.subheading,
  h4.subheading,
  h5.subheading,
  h6.subheading {
    font-weight: normal;
    color: ${theme.colors.ink.dark};
    margin: 0;
  }

  h2 {
    font-size: ${theme.fontSize.xl4}px;
    margin: ${theme.fontMargins.l}px 0;
  }

  h3 {
    font-size: ${theme.fontSize.xl2}px;
    margin: ${theme.fontMargins.xs}px 0;
  }
  h3 + .subheading {
    margin-top: -${theme.fontMargins.xs}px;
  }

  h5 {
    font-size: ${theme.fontSize.l}px;
    margin: ${theme.fontMargins.xxs}px 0;
  }
  h5 + .subheading {
    margin-top: -${theme.fontMargins.xxs}px;
  }

  h6 {
    font-size: ${theme.fontSize.base}px;
    margin: ${theme.fontMargins.xxxs}px 0;
  }
  h6 + .subheading {
    margin-top: -${theme.fontMargins.xxxs}px;
  }
  

  p {
    font-size: ${theme.fontSize.l}px;
    margin: ${theme.fontMargins.xs}px 0;
  }

  a {
    color: ${theme.colors.primary.base};
  }

  a:hover {
    color: ${theme.colors.primary.dark};
  }
`}`;