import { useEffect, useMemo, useState } from 'react';

import {
  useMQTTCellRaw,
  useMQTTWiFiProbeRequests,
  useMQTTBluetoothAdvPackets,
  MQTTHookConfig
} from 'hooks';
import { RfVisionBtAdvPacket, RfVisionRawCell, RfVisionWifiProbeRequest } from 'types';
import { binaryToDecimal } from 'utils';

export const getCellularProviderName = (
  provider?: string,
): string | undefined => {
  // TODO: update this to use some regex instead
  switch (provider) {
    case 'Verizon Wireless':
      return 'Verizon';
    case 'AT&T Mobility':
      return 'AT&T';
    case 'T-Mobile USA':
    case 'Sprintcom Inc':
      return 'T-Mobile';
    default:
      return provider;
  }
};
export type Identifier = {
  deviceInterface: 'cell' | 'wifi' | 'ble';
  type: 'tmsi' | 'ssid' | 'deviceName';
  label: string;
  value: string;
  timestamp: number;
  count: number;
  averageSignalStrength: number;
  metadata?: string;
};

export const useIdentifiers = (sensorUid?: string, config?: MQTTHookConfig<Identifier>) => {
  const [identifiers, setIdentifiers] = useState<Record<string, Identifier>>(
    {},
  );

  const {
    onNext
  } = config || {};

  // Reset Identifiers anytime the Sensor Uid Changes
  useEffect(() => {
    setIdentifiers({});
  }, [sensorUid]);

  const cellRawConfig: MQTTHookConfig<RfVisionRawCell> = useMemo(
    () => ({
      onNext: (d: RfVisionRawCell) => {
        const { timestamp, cell } = d;
        const tmsi =
          binaryToDecimal(cell?.rach_message?.['s-TMSI']?.['m-TMSI']) ??
          undefined;
        const provider = getCellularProviderName(
          cell?.downlink_info?.networkProvider,
        );
        if (provider && timestamp && tmsi) {
          const signalStrength = cell?.rf_power_estimate ?? 0;
          onNext?.({
            deviceInterface: 'cell',
            type: 'tmsi',
            label: 'Cell ID',
            value: tmsi.toString(),
            timestamp,
            count: 1,
            averageSignalStrength: signalStrength,
            metadata: provider,
          });
          setIdentifiers((prev) => ({
            ...prev,
            [tmsi]: prev[tmsi]
              ? {
                  ...prev[tmsi],
                  timestamp,
                  count: prev[tmsi].count + 1,
                  averageSignalStrength:
                    (prev[tmsi].averageSignalStrength * prev[tmsi].count +
                      signalStrength) /
                    (prev[tmsi].count + 1),
                }
              : {
                  deviceInterface: 'cell',
                  type: 'tmsi',
                  label: 'Cell ID',
                  value: tmsi.toString(),
                  timestamp,
                  count: 1,
                  averageSignalStrength: signalStrength,
                  metadata: provider,
                },
          }));
        }
      },
      shouldBeSubscribed: !!sensorUid,
    }),
    [sensorUid, onNext],
  );
  useMQTTCellRaw(sensorUid ?? '', cellRawConfig);

  const wifiConfig: MQTTHookConfig<RfVisionWifiProbeRequest> = useMemo(
    () => ({
      onNext: (d: RfVisionWifiProbeRequest) => {
        const { 'wifi-probe-request': wifiProbeRequestArray } = d;
        wifiProbeRequestArray.forEach((wifiProbeRequest) => {
          const { manufacturer, ssid, timestamp } = wifiProbeRequest;
          if (timestamp && ssid) {
            const signalStrength = wifiProbeRequest?.rfPowerEstimate ?? 0;
            onNext?.({
              deviceInterface: 'wifi',
              type: 'ssid',
              label: 'Network',
              value: ssid,
              timestamp,
              count: 1,
              averageSignalStrength: signalStrength,
              metadata: manufacturer,
            });

            setIdentifiers((prev) => ({
              ...prev,
              [ssid]: prev[ssid]
                ? {
                    ...prev[ssid],
                    timestamp,
                    count: prev[ssid].count + 1,
                    averageSignalStrength:
                      (prev[ssid].averageSignalStrength * prev[ssid].count +
                        signalStrength) /
                      (prev[ssid].count + 1),
                  }
                : {
                    deviceInterface: 'wifi',
                    type: 'ssid',
                    label: 'Network',
                    value: ssid,
                    timestamp,
                    count: 1,
                    averageSignalStrength: signalStrength,
                    metadata: manufacturer,
                  },
            }));
          }
        });
      },
      shouldBeSubscribed: !!sensorUid,
    }),
    [sensorUid, onNext],
  );
  useMQTTWiFiProbeRequests(sensorUid ?? '', wifiConfig);

  const passiveBluetoothConfig: MQTTHookConfig<RfVisionBtAdvPacket> =
    useMemo(
      () => ({
        onNext: (d: RfVisionBtAdvPacket) => {
          const { 'bt-adv-packet': btAdvPacketArray } = d;
          btAdvPacketArray.forEach((btAdvPacket) => {
            const { manufacturerName: manufacturer, timestamp } =
              btAdvPacket || {};
            // TODO: replace with device name once available
            const deviceName = manufacturer; // nada
            
            if (timestamp && deviceName) {
              const signalStrength = btAdvPacket?.rssi ?? 0;
              onNext?.({
                deviceInterface: 'ble',
                type: 'deviceName',
                label: 'Device Name',
                value: deviceName,
                timestamp,
                count: 1,
                averageSignalStrength: signalStrength,
                metadata: manufacturer,
              });
              setIdentifiers((prev) => ({
                ...prev,
                [deviceName]: prev[deviceName]
                  ? {
                      ...prev[deviceName],
                      timestamp,
                      count: prev[deviceName].count + 1,
                      averageSignalStrength:
                        (prev[deviceName].averageSignalStrength *
                          prev[deviceName].count +
                          signalStrength) /
                        (prev[deviceName].count + 1),
                    }
                  : {
                      deviceInterface: 'ble',
                      type: 'deviceName',
                      label: 'Device Name',
                      value: deviceName,
                      timestamp,
                      count: 1,
                      averageSignalStrength: signalStrength,
                      metadata: manufacturer,
                    },
              }));
            }
          });
        },
        shouldBeSubscribed: !!sensorUid,
      }),
      [sensorUid, onNext],
    );
  useMQTTBluetoothAdvPackets(sensorUid ?? '', passiveBluetoothConfig);

  const identifierArray = useMemo(
    () => Object.values(identifiers).sort((a, b) => b.count - a.count),
    [identifiers],
  );

  return identifierArray;
};
