// Contains Types and Queries colocated to keep consistent

import { apiRequest } from 'api/apiRequest';
import { TimeZone } from 'config/localData';
import { RawDeviceType , Maybe } from 'types';

/* Home */
export type GraphQLHomeQueryTypes = {
  data: {
    allHomes: {
      edges: Maybe<GraphQLHomeEdge>[];
    };
  };
};
export type GraphQLHomeEdge = {
  node?: Maybe<GraphQLHome>;
};
export type GraphQLHome = {
  accountId: string;
  name: Maybe<string>;
  uid: string;
  timezone: Maybe<TimeZone>;
};
/* Sensors */
export type GraphQLSensorsQueryTypes = {
  data: {
    allHomes: {
      edges: Maybe<{
          node?: Maybe<{
            sensors: {
              edges: Maybe<GraphQLSensorEdge>[];
            };
          }>;
        }>[];
    };
  };
};
export type GraphQLSensorEdge = {
  node?: Maybe<GraphQLSensorNode>;
};
export type GraphQLSensorNode = {
  uid: string;
  id: string;
  name: Maybe<string>;
  active: Maybe<boolean>;
  online: Maybe<boolean>;
  setupTimestamp: Maybe<number>;
  hardwareId: Maybe<string>;
  sensorHealth?: Maybe<{
    hardwareId: Maybe<string>;
    sensorType: Maybe<string>;
    softwareVersion: Maybe<string>;
    wifiIp: Maybe<string>;
    wifiMac: Maybe<string>;
    wifiQuality: Maybe<string>;
    wifiCanPingInternet: Maybe<boolean>;
    wifiFrequency: Maybe<string>;
    wifiSignalLevel: Maybe<number>;
    wifiSsid: Maybe<string>;
  }>;
};
/* Profiles */
export type GraphQLProfilesQueryTypes = {
  data: {
    allHomes: {
      edges: Maybe<{
          node?: Maybe<{
            profiles: {
              edges: Maybe<GraphQLProfileEdge>[];
            };
          }>;
        }>[];
    };
  };
};
export type GraphQLProfileEdge = {
  node?: Maybe<GraphQLProfileNode>;
};
export type GraphQLProfileNode = {
  id: string;
  uid: string;
  name: Maybe<string>;
  photoUrl: Maybe<string>;
  description: Maybe<string>;
  email: Maybe<string>;
  createdAt: Maybe<number>;
  phoneNumber: Maybe<string>;
  primaryDeviceId: Maybe<number>;
  alertOnEnter: Maybe<boolean>;
  alertOnLeave: Maybe<boolean>;
  presence?: Maybe<{
    state: Maybe<'present' | 'left'>;
    lastSeen: Maybe<number>;
    lastSeenIntervalStart: Maybe<number>;
  }>;
};
/* Devices */
export type GraphQLDevicesQueryTypes = {
  data: {
    allHomes: {
      edges: Maybe<{
          node?: Maybe<{
            devices: {
              edges: Maybe<GraphQLDeviceEdge>[];
            };
          }>;
        }>[];
    };
  };
};
export type GraphQLDeviceEdge = {
  node?: Maybe<GraphQLDeviceNode>;
};
export type GraphQLDeviceNode = {
  id: string;
  uid: string;
  name: Maybe<string>;
  deviceType: Maybe<RawDeviceType>;
  acknowledgedTime: Maybe<number>;
  model: Maybe<string>;
  createdAt: Maybe<number>;
  suggestedName: Maybe<string>;
  suggestedDeviceType: Maybe<RawDeviceType>;
  suggestedEntity: Maybe<string>;
  profileId: Maybe<number>;
  presence?: Maybe<{
    state: Maybe<'present' | 'left'>;
    lastSeen: Maybe<number>;
    lastSeenIntervalStart: Maybe<number>;
  }>;
  wifiid?: Maybe<{
    netbiosName: Maybe<string>;
    hostName: Maybe<string>;
    manufacturer: Maybe<string>;
    mac: Maybe<string>;
    macType: Maybe<'PUBLIC' | 'RANDOM'>;
    ip: Maybe<string>;
    isRouter: Maybe<boolean>;
    mdnsDetails: Maybe<any>;
    fingerbankDetails: Maybe<any>; // TODO: Improve type
  }>;
  wifiprobeid?: Maybe<{
    mac: Maybe<string>;
    ssidVals: Maybe<string>;
    manufacturer: Maybe<string>;
  }>;
};

type GraphQLQueryData = {
  accountId: string;
  home?: boolean;
  sensors?: boolean;
  profiles?: boolean;
  devices?: boolean;
};
const graphQLDataQuery = (data: GraphQLQueryData) => {
  const { accountId, home, sensors, profiles, devices } = data;
  return `{
		allHomes(filters: {accountId: "${accountId}"}){
			edges{
				node{
      ${
        home
          ? `
					accountId,
					name,
					uid,
					timezone,`
          : ''
      }
      ${
        sensors
          ? `
					sensors{
						edges{
							node{
								uid,
								id,
								name,
								active,
								online,
                setupTimestamp,
                hardwareId,
                sensorHealth {
                  sensorType,
                  softwareVersion,
                  wifiIp,
                  wifiMac,
                  wifiQuality,
                  wifiCanPingInternet,
                  wifiFrequency,
                  wifiSignalLevel,
                  wifiSsid,
                },
							}
						}
					}`
          : ''
      }
      ${
        profiles
          ? `
					profiles{
						edges{
						  node{
								id,
								uid,
								name,
								photoUrl,
								description,
								email,
                createdAt,
								phoneNumber,
                primaryDeviceId,
                alertOnEnter,
                alertOnLeave,
                presence {
                  state,
                  lastSeen,
                  lastSeenIntervalStart,
                },
						  }
						}
					}`
          : ''
      }
      ${
        devices
          ? `devices{
              edges{
                node{
                  id,
                  uid,
                  createdAt,
                  name,
                  deviceType,
                  acknowledgedTime,
                  suggestedName,
                  suggestedDeviceType,
                  suggestedEntity,
                  profileId,
                  presence {
                    state,
                    lastSeen,
                    lastSeenIntervalStart,
                  },
                  wifiid{
                    netbiosName,
                    hostName,
                    manufacturer,
                    mac,
                    macType,
                    ip,
                    isRouter,
                    mdnsDetails,
                    fingerbankDetails,
                  },
                  wifiprobeid{
                    mac,
                    ssidVals,
                    manufacturer,
                  },
                }
              }
            }`
          : ''
      }
				}
			}
		}
	}`.replace(/\s+/g, '');
};

export type GraphQLGetAllData = GraphQLHomeQueryTypes &
  GraphQLProfilesQueryTypes &
  GraphQLSensorsQueryTypes &
  GraphQLDevicesQueryTypes;
export const getAllGraphQLQuery = (accountId: string) =>
  graphQLDataQuery({
    accountId,
    home: true,
    sensors: true,
    profiles: true,
    devices: true,
  });

export const getGraphQLHomeQuery = (accountId: string) =>
  graphQLDataQuery({
    accountId,
    home: true,
  });
export const getGraphQLSensorsQuery = (accountId: string) =>
  graphQLDataQuery({
    accountId,
    sensors: true,
  });
export const getGraphQLProfilesQuery = (accountId: string) =>
  graphQLDataQuery({
    accountId,
    profiles: true,
  });
export const getGraphQLDevicesQuery = (accountId: string) =>
  graphQLDataQuery({
    accountId,
    devices: true,
  });

export const getGraphQLData = <T>(query: string) =>
  apiRequest<T>({
    service: 'dataAPI',
    path: '/graphql',
    method: 'GET',
    params: { query },
  });

export const getAllGraphQLData = (accountId: string) =>
  getGraphQLData<GraphQLGetAllData>(getAllGraphQLQuery(accountId));
