import { AlertBase } from '@ha_schemas/ha_schemas';

import { Alert, Maybe } from 'types';

import { apiRequest } from '../apiRequest';
import { getGraphQLData } from './globalGraphQL';

export type GraphQLAlertEdge = {
  node: GraphQLAlertNode;
};
export type GraphQLAlertNode = {
  id: string;
  uid: string;
  createdAt: Maybe<number>;
  alertType: Maybe<AlertBase['alert_type']>;
  acknowledgedTime: Maybe<number>;
  contributingFactors: Maybe<string>;
};
export type GraphQLAlertResponseData = {
  data: {
    allAlerts: Maybe<{
      totalCount: Maybe<number>;
      pageInfo: {
        startCursor?: Maybe<string>;
        endCursor?: Maybe<string>;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
      edges: Maybe<GraphQLAlertEdge>[];
    }>;
  };
};
export const getUnreadAlertsGraphQLData = (homeUid: string, limit: number) => {
  const query = `
      {
        allAlerts(filters: {homeId: ${homeUid}, acknowledgedTimeIsNull: true, alertTypeNotIn: ["INCREASED_ACTIVITY","NEW_METADATA"]}, sort: CREATED_AT_DESC${
    limit > 0 ? `, first: ${limit}` : ''
  }) {
          totalCount
          pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              createdAt
              uid
              alertType
              acknowledgedTime
              contributingFactors
              id
            }
          }
        }
      }
    `;

  return getGraphQLData<GraphQLAlertResponseData>(query);
};

type PaginatedAlertType = {
  homeUid: string;
  pagination?: string;
  alertTypeArray: Alert['alertType'][];
  filter?: string;
};

export const getAlertNewestPaginatedGraphQLData = (
  data: PaginatedAlertType,
) => {
  const { homeUid, pagination, alertTypeArray, filter } = data;
  const query = `
      {
        allAlerts(sort: CREATED_AT_DESC, filters: {homeId: ${homeUid}, alertTypeIn: [${alertTypeArray.map(
    (i) => `"${i}"`,
  )}]${filter ? `, ${filter}` : ''}}${pagination ? `, ${pagination}` : ''}) {
          totalCount
          pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              createdAt
              uid
              alertType
              acknowledgedTime
              contributingFactors
              id
            }
          }
        }
      }
    `;
  return getGraphQLData<GraphQLAlertResponseData>(query);
};
export const getAlertsInTimeRange = (
  homeUid: string,
  lowerBound: number, // Seconds
  upperBound?: number, // Seconds
) => {
  const query = `
      {
        allAlerts(sort: CREATED_AT_ASC, filters: {homeId: ${homeUid}, ${`createdAtRange: {begin: ${lowerBound}, end: ${
    upperBound ?? Math.round(Date.now() / 1000)
  }}`} }) {
          edges {
            node {
              createdAt
              uid
              alertType
              acknowledgedTime
              contributingFactors
              id
            }
          }
        }
      }
    `;
  return getGraphQLData<GraphQLAlertResponseData>(query);
};
export const getAlertByUid = (uid: string) => {
  const query = `
      {
        allAlerts(filters: {uid: "${uid}"}) {
          edges {
            node {
              createdAt
              uid
              alertType
              acknowledgedTime
              contributingFactors
              id
            }
          }
        }
      }
    `;
  return getGraphQLData<GraphQLAlertResponseData>(query);
};
export const getAlertByGraphQLId = (alertGraphQLId: string) => {
  const query = `
    {
      alert(id: "${alertGraphQLId}") {
        createdAt
        uid
        alertType
        acknowledgedTime
        contributingFactors
        id
      }
    }
    `;
  return getGraphQLData<{
    data: { alert: GraphQLAlertNode };
  }>(query);
};

export const markAlertAsRead = (alertUid: string) =>
  apiRequest({
    service: 'dataAPI',
    path: `/api/alert/${alertUid}`,
    method: 'PUT',
    data: {
      acknowledged_time: new Date().getTime() / 1000,
    },
  });

export const markAllAlertsAsRead = async (homeUid: string) => {
  try {
    const unreadAlertRespone = await getUnreadAlertsGraphQLData(homeUid, 0);
    const rawAlertArray = unreadAlertRespone.data.data?.allAlerts?.edges ?? [];
    const responseArray = await Promise.all(
      rawAlertArray.map((r) =>
        r?.node.uid ? markAlertAsRead(r.node.uid) : null,
      ),
    );
    return responseArray;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
