import {useEffect, useState, useRef, useCallback, useMemo} from 'react';

export function toCapitalCase(string){
  return (string+'').replace(/^(.).*/g,'$1').toUpperCase()+(string+'').replace(/^.(.*)/g,'$1');
}
//TODO: I think form should be an object and not a refference,
// so that we rehook blur event if it changes
export function useValidator(formRef={current:{}}, validations, fulfillment){

  const setCheked = useState(new Date())[1];
  const validated = useRef({});
  const api = useRef({
    validated: false,
    statuses: {},
    classes: {},
    messages: {},
    getMessages: function(){
      const allMessages = [];

      Object.values(api.current.messages).forEach( bulk => {
        if(Array.isArray(bulk)){
          allMessages.push(...bulk);
        } else {
          allMessages.push(bulk);
        }
      });

      return allMessages;
    },
    clear: function(){
      api.current.messages = {};
      Object.keys(validated.current).forEach( name => {
        validated.current[name] = '';
      });
    },
    getValues: function(){
      // !! no need for validations names, since its for validation of input, not fullfilment ...Object.keys(validations)
      const names = ([...Object.keys(formRef.current), ...Object.keys(fulfillment||{})]);
      const values = {};

      names.forEach(name => {
        const el = (formRef.current[name]?.current) || undefined;
        const value = el ? el.value : undefined;

        values[name] = value;
      });

      return values;
    }
  });

  const ValidateField = useCallback(function ValidateField(name, value){
    //console.log('value', value);
    const valid = typeof validations?.[name] == 'function' ? validations[name](value) : true;
    const fullfilled = typeof fulfillment?.[name] == 'function' ? fulfillment[name](value) : true;

    const isValid = valid&&!(valid instanceof Error);
    const isFullfilled = fullfilled&&!(fullfilled instanceof Error);

    //console.log('[useValidator] value, ', el, value, fulfillment[name], validations[name]);

    let classes = '';
    let status = '';

    const statuses = {};
    const messages = [];
    const fieldName = toCapitalCase(name);

    messages.name = name;

    if(!isFullfilled){
      status = 'unfulfilled';
      classes += ' unfulfilled';
      statuses.unfulfilled = true;
      const message = fullfilled?.message || fieldName + ' is unfullfilled';

      messages.push( '(*) '+message.replace(/\{(name)\}/gi, fieldName) );
    }
    if(!isValid){
      status = 'bad';
      classes += ' bad';
      statuses.bad = true;
      const message = (valid?.message || toCapitalCase(name) + ' is invalid');
      messages.push( '(!) '+message.replace(/\{(name)\}/gi, fieldName) );
    }

    if(isValid&&isFullfilled) {
      status = 'accepted';
      classes += ' accepted';
      statuses.accepted = true;
    }

    validated.current[name] = status;
    api.current.classes[name] = classes;
    api.current.statuses[name] = statuses;
    api.current.messages[name] = messages;

    return isValid&&isFullfilled;
  }, [
    validations,
    fulfillment
  ]);

  const ValidateForm = useCallback(function ValidateForm(){

    api.current.validated = true;

    // checking that all fields present/visible is valid and all fullfilment requirments are met
    // !! no need since its for validation of input, not fullfilment ...Object.keys(validations)
    ([...Object.keys(formRef.current), ...Object.keys(fulfillment||{})]).forEach((name) => {

      const el = formRef.current[name]?.current || undefined;
      const value = el ? el.value : '';

      if(!ValidateField(name, value)) {api.current.validated = false;}
    });

    setCheked(new Date());

    return api.current.validated;
  }, [
    ValidateField,
    formRef,
    fulfillment,
    setCheked
  ]);

  api.current.validate = ValidateForm;
  api.current.validateField = useCallback(function(name){
    const {value} = formRef.current[name].current || {};

    const isValid = ValidateField(name, value);

    setCheked(new Date());

    return isValid;
  },[ValidateField, setCheked, formRef]);

  // hooking on blur to the form
  // we want to minimize adding and removing event listeners
  // but always use latest version of the form
  const ValidateFieldRefed = useRef(useMemo(() => ValidateField,[ValidateField])).current;
  useEffect(function HookOnOutCheck(){
    const killFns = [];
    Object.entries(formRef?.current).forEach(([name, ref]) => {

      const el = ref.current;

      if(!el || !el.addEventListener) {return;}

      const onBlur = () => {
        //console.log('blur set on ', {date: String(date), name, el: el.value});
        ValidateFieldRefed?.(name, el.value);
        setCheked(new Date());
      };

      el.addEventListener('blur', onBlur);

      killFns.push(function(){
        el.removeEventListener('blur', onBlur);
      });
    });

    return function(){
      // run all kill functions
      killFns.forEach(fn => { fn(); });
    };
  }, [formRef, setCheked, ValidateFieldRefed]);

  return [validated.current, api.current];
}

export const restrictors = {
  min: function(number, error){
    return (value) => (((value+'').length >= number)||(error||new Error('{name} has to be a minimum of '+number)));
  },
  minWords: function(number, error){
    return (value) =>
      (new RegExp(`^([^\\s]{1,}(\\s|$){1,}){${number},}$`,'g'))
        .exec(value.trim().replace(/\./g,'').replace(/\s{1,}/g,' '))

      ||

      (error||new Error('{name} has to be a minimum of '+number+' words'));
  },
  required: function(nada, error=new Error('{name} is required')){
    return (value) => !(!value)||(error);
  }
};

export const validations = {
  email: (value) => /^\s*(\w|\w-|\w\+|\w\.)(\w|-\w|\+\w|\.\w){0,}@(\w|\w-|\w\.)(\w|-\w|\.\w){0,}\.\w{1,}\s*$/gi.exec(value+'')
};

/*
  usage:
  import useValidator, {restrictors, validations} from

  const [validated, validatorApi] = useValidator(form, {
    email: validations.email
  }, {
    name: restrictors.min(5)
  });
*/
