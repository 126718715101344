import {useState, useEffect, useMemo, useRef, useCallback} from 'react';

import {
  format
} from 'date-fns-tz';
import {
  BsFullscreen,
  BsFullscreenExit
} from 'react-icons/bs';
import styled from 'styled-components';

import {
  useFibonachiPowerPlot,
  DataTable,
  ScrollableDataTableContainer
} from 'components';
import {
  useGraphQLContext
} from 'context';
import {
  SensorSelect
} from 'elements';
import {
  //useMQTTCellRaw,
  useIdentifiers,
  MQTTHookConfig,
  Identifier,
  useMQTTCellScan,
} from 'hooks';
import {
  FlexContainer,
  Flex12,
  Flex6,

  digestedAgent
} from 'layout';
import {
  CellItem,
  processRawCellScanMqttToCellItem
} from 'utils';

const PROVIDERS_COLORS: {
  [key: string]: string
} = {
  'Verizon': '#F00202',
  'AT&T': '#0FB5DA',
  'T-Mobile': '#E100AF',
  'Other': '#EF9A26'
};

export type CellIdentifier = {
  deviceInterface: 'cell-scan';
  type: 'scan';
  timestamp: number;
  averageSignalStrength: number;
  metadata: string;

  value?: string
}

export type TMSIMonitorProps = {
  variant?: 'inline'|'normal';
}

export const TMSIMonitor: React.FC<TMSIMonitorProps & React.HTMLAttributes<HTMLDivElement>> = ({
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const [latestIdentifier, setLatestIdentifier] = useState<Identifier|CellIdentifier>();
  const [allCapturedidentifiers, setAllCapturedidentifiers] = useState<(Identifier|CellIdentifier)[]>([]);
  const [logsVisible, setLogsVisible] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isJellyFullScreen, setIsJellyFullScreen] = useState<boolean>(false);

  useEffect(function(){
    console.log('[TMSIMonitor] new all captured identifiers:', allCapturedidentifiers);
  },[allCapturedidentifiers]);
  const [sensorId, setSensorId] = useState<string>();

  const {home} = useGraphQLContext();

  const fibonachiSettings = useMemo(() => (isJellyFullScreen ? {
    accentDimStep: 0.005,
    dotSize: 3,
    linesWidth: 1,
    linesColorHex: '#3e50a3',
    // dotsColorHex: '#3e50a3',
  } : {
    // D: 0.25,
    // K: 0.23,
    // M: 1,
    accentDimStep: 0.005,
    // bgColor: '#182e4d',
    cameraX: 0,
    cameraY: 105,
    cameraZ: 140,
    // circlesNumber: 15,
    // distributionFormula: 'fibonachiSpiral',
    dotSize: 4,
    linesWidth: 1,
    // dotsAccentColorHex: '#e100af',
    // dotsColorHex: '#3e50a3',
    // framePerSecond: 15,
    // height: 370,
    // kickDiviation: 0.05,
    linesColorHex: '#3e50a3',
    // logcalctime: false,
    // logdrawtime: false,
    // numberOfDots: 1000,
    // scaleAdjustment: 150,
    // speed: 0.8,
    // viewBoxOffsetX: 0,
    // viewBoxOffsetY: 75,
    // waveDimStep: 0.01,
    // width: 800
  }),[
    isJellyFullScreen
  ]);

  // instantiate fibonachi power plot
  const {
    addWave,
    play,
    stop,
    resetScene,
    //
    clearScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    kickInWawesFrame,
    getSceneVertices
  } = useFibonachiPowerPlot(svgRef, fibonachiSettings);

  // log
  useEffect(function logFibanachi(){
    console.log('[TMSIMonitor] fibonachi api:', {addWave, play, stop, resetScene, clearScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    kickInWawesFrame,
    getSceneVertices});

  }, [addWave, play, stop, resetScene,
    clearScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    kickInWawesFrame,
    getSceneVertices]);

  // helpers
  const getProviderColor = useCallback((provider) =>

    PROVIDERS_COLORS[provider]||PROVIDERS_COLORS.Other

  ,[]);

  // set interval for plot
  useEffect(function(){
    play();
    return stop;
  },[play, stop]);

  // kick in wave on TMSI
  useEffect(function kickInTmsiToPlot(){
    if(!latestIdentifier){ return; }
    const color = getProviderColor(latestIdentifier.metadata);
    addWave( Math.min(15, Math.round( 15*((latestIdentifier.averageSignalStrength*-1)-40)/80 // we are interested in diviation between 40 and 120 dbm, our plot has 15 circles
  )), color);
  }, [latestIdentifier, addWave, getProviderColor]);

  // Subscribe to identifiers stream
  const indetifiersConfig = useMemo<MQTTHookConfig<Identifier>>(() => ({
    onNext: function(data){
      if(data.type === 'tmsi'){
        setLatestIdentifier(data);
        setAllCapturedidentifiers((prev) => [data, ...prev]);
        console.log('[TMSIMonitor] new tmsi spotted', data);
      }
    }
  }), []);
  useIdentifiers(sensorId, indetifiersConfig);

  // Subscribe to cell stream
  // MQTT subscribtion
  const onNewCellScanDataRef = useRef<(item: CellItem) => void>();
  const onCloseFn = useRef<() => void>();
  useMQTTCellScan(sensorId||'', {
    onNext: function(newData){
      const cellItem = processRawCellScanMqttToCellItem(newData);

      if(!cellItem){ return; }

      console.log('[useActivityReport] got new data', {newData, cellItem});
      if(cellItem){
        onNewCellScanDataRef.current?.(cellItem);
      }
    },
    onClose: function(){
      onCloseFn.current?.();
      console.log('[useActivityReport] closed connection');
    }
  });

  useEffect(function onNewCellScanData(){
    onNewCellScanDataRef.current = function(cellItem){
      //const color = getProviderColor(cellItem.networkProvider);
      const identifier = {
        deviceInterface: 'cell-scan',
        type: 'scan',
        timestamp: cellItem.timestamp,
        averageSignalStrength: cellItem.powerEstimate,
        metadata: cellItem.networkProvider,
      } as CellIdentifier;

      setLatestIdentifier(identifier);
      setAllCapturedidentifiers((prev) => [identifier, ...prev]);

      //addWave( Math.round((Number(cellItem.powerEstimate)*-1)/10), color);
      //kickInSomeMotion(Math.round((Number(cellItem.powerEstimate)*-1)/10), color);
    };
  },[addWave, getProviderColor]);

  const handleSensorSelect = useCallback(function handleSensorSelect(sensorId){
    setSensorId(sensorId);
    setLatestIdentifier(undefined);
    setAllCapturedidentifiers([]);
    resetScene();
  },[resetScene]);


  const containerRef = useRef<HTMLDivElement>(null);
  // this fixes extremely weird behavio obseverved on iphone horiontal mode,
  // where that container's background goes strangely color pixelated
  useEffect(function(){
    //console.log('[TMSI monitor] digested agent', {digestedAgent});
    if(digestedAgent.apple.device){
      let val = 1;
      const interval: ReturnType<typeof setInterval> = setInterval( () => {
        // window.scrollTo(window.scrollX, window.scrollY+val);
        val *= -1;
        // console.log('scrolling works!?');
        containerRef.current?.setAttribute('style', 'background-color: rgba(22, 28, 35, 0.99'+(1-val)+');');
      }, 100);

      return () => clearInterval(interval);
    }
  }, []);

  return <>
    <TMSIMonitorContainer ref={containerRef} id="tmsiMonitor" {...rest} fullScreen={isFullScreen}>
      <StyledSensorSelect
        variant="inverse"
        onSelect={handleSensorSelect}
        preserveByName="tmsi-monitor"
        />

      <ProvidersList>
        {Object.entries(PROVIDERS_COLORS).map(([name, color]) =>
          <li key={name}><i style={{backgroundColor: color}} /> {name}</li>
          )}
      </ProvidersList>


      <TmsiMonitorCell className="tmsi-monitor-cell" fullScreen={isJellyFullScreen}>
        <PlotSvg ref={svgRef} width="540" height="auto" viewBox="0 75 800 370" />

        <FullScreenButton
          onClick={() => setIsJellyFullScreen(prev => !prev)}
          >
        {!isJellyFullScreen ?
            <BsFullscreen />
            :
            <BsFullscreenExit />
        }
        </FullScreenButton>

      </TmsiMonitorCell>


      <Flex6 style={isJellyFullScreen?{visibility:'hidden'}:{visibility:'visible'}}>
        {latestIdentifier ?
          <LabelsList
            onClick={() => {
              const color = getProviderColor(latestIdentifier.metadata);
              addWave( Math.round((latestIdentifier.averageSignalStrength*-1)/10), color);
            }}
            >
            <li>Time: <b>{ format((new Date(latestIdentifier.timestamp)), 'hh:mm:ss a zzz', {timeZone: String(home?.timezone)||undefined})}</b></li>
            <li>Network provider: <b>{latestIdentifier.metadata}</b></li>
            <li className="sea"><u className="tertiaryLighter">TMSI:</u> <b className="insightBlueBase">{latestIdentifier.value||'N/A'}</b></li>
            <li><u>RF Power:</u> <b className="waveGreenBase">{latestIdentifier.averageSignalStrength}</b></li>
            <li>Type: <b>{latestIdentifier.deviceInterface === 'cell-scan' ? 'cell scan' : 'cell'}</b></li>
          </LabelsList>

          : null}
      </Flex6>


      <FullScreenButton
        onClick={() => setIsFullScreen(prev => !prev)}
        >
      {!isFullScreen ?
          <BsFullscreen />
          :
          <BsFullscreenExit />
      }
      </FullScreenButton>

    </TMSIMonitorContainer>

    <TMSIMonitorLogs className={logsVisible ? 'active' : ''}>
      <a href="#nada" className="activate-link" onClick={e => {e.preventDefault(); setLogsVisible(prev => !prev);}}>
        {!logsVisible && allCapturedidentifiers.length ? 'show activity log (' + allCapturedidentifiers.length+')' : 'hide activity log ('+allCapturedidentifiers.length+')'}
      </a>
      <ScrollableDataTableContainer >
        <DataTable
          data={allCapturedidentifiers}
          index={['timestamp', 'metadata', 'deviceInterface', 'averageSignalStrength', 'value']}
          dataFormating={{
            timestamp: v => String(format(v, ' h:mm:ss b')),
            averageSignalStrength: v => Math.round(v*100)/100,
            value: v => v||''
          }}
          titlesFormatting={{
            timestamp: () => 'Time (' + format(new Date(),'zzz') + ')',
            metadata: () => 'Provider',
            deviceInterface: () => 'Source',
            averageSignalStrength: () => 'Signal Strength (dBm)',
            value: () => 'TMSI'
          }} />
      </ScrollableDataTableContainer>
    </TMSIMonitorLogs>
  </>;
};

const TMSIMonitorContainer = styled(FlexContainer).attrs({} as Pick<TMSIMonitorProps, 'variant'> & {fullScreen?: boolean})`${({
  theme,
  variant='normal',
  fullScreen=false
}) => `
  will-change: transform;
  min-height: 300px;

  background-color: #161C23;
  padding: ${theme.boxPadding.xl10}px ${theme.boxPadding.xl7}px ${theme.boxPadding.xl3}px;

  select, input {
    color: ${theme.colors.sky.dark};
  }

  border-radius: ${theme.boxPadding.base}px;

  position: relative;

  .tertiaryLighter{
    color: ${theme.colors.tertiary.lighter};
  }
  .insightBlueBase{
    color: ${theme.colors.insightBlue.base};
  }
  .waveGreenBase{
    color: ${theme.colors.waveGreen.base};
  }

  > * {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  ${variant === 'inline' ? `

    .tmsi-monitor-cell {

      margin-top: ${theme.boxPadding.xl}px;

      display: flex-block;
      justify-content: center;
      align-items: center !important;
      min-width: 100%;
    }
  `
  : ''}

  ${fullScreen ? `
    position: fixed;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: 2000;
    margin: 0 !important;
    border-radius: 0;
    `:''}

  .tmsi-monitor-cell {
    align-items: flex-end;
  }
`}`;

const PlotSvg = styled.svg`
  max-width: 100%;
  user-select: none !important;
  pointer-events: none !important;
`;

const StyledSensorSelect = styled(SensorSelect)`${({theme}) => `
  position: absolute;
  top: ${theme.boxMargins.xl}px;
  right: ${theme.boxMargins.xl}px;
`}`;

const ProvidersList = styled.ul.attrs({
  className: 'providers-list'
})`${({theme}) => `
  position: absolute;
  left: ${theme.boxPadding.xl3}px;
  top: 0;
  flex-wrap: wrap;

  padding: 0;
  display: flex;
  flex-direction: row;

  max-width: 540px;
  max-width: 200px;
  width: 30%;

  justify-content: flex-start;

  li {
    min-width: 100px;
    line-height: 30px;
    list-style-type: none;

    color: ${theme.colors.sky.lightest};
    font-size: ${theme.fontSize.base}px;

    display: inline-block;

  }

  li > i {
    display: inline-block;
    height: ${theme.fontSize.base}px;
    width: ${theme.fontSize.base}px;
    border-radius: ${theme.fontSize.base/2}px;
  }

  z-index: -1;
`}`;

const LabelsList = styled.ul`${({theme}) => `
  padding: 0;
  padding-left: ${theme.boxPadding.xl5}px;

  margin: ${theme.boxPadding.xl5}px 0;

  li {
    list-style-type: none;
    white-space: no-wrap;
    color: ${theme.colors.sky.lightest};
    font-size: ${theme.fontSize.l}px;
  }

  li:not(:last-child){
    margin-bottom: ${theme.boxMargins.l}px;
  }

  .underlined {
    text-decoration: underline;
  }

`}`;

const TMSIMonitorLogs = styled(Flex12)`${({theme}) => `
  margin-top: ${theme.boxPadding.xl2}px;
  .activate-link {
    display: block;
    text-align: center;
    margin: ${theme.boxMargins.l}px;
  }
  &.active .activate-link {
    text-align: right;
  }
  &:not(.active) > *:not(.activate-link) {
    display: none;
  }
`}`;

const FullScreenButton = styled.div.attrs({
  className: 'full-screen-button'
})`${({theme}) =>  `
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: ${theme.colors.sky.light};
`}`;

const TmsiMonitorCell = styled(Flex6).attrs({} as {
  fullScreen?: boolean
})`${({theme, fullScreen=false}) => `
  position: relative;

  .providers-list {
    display: none;
  }

  .full-screen-button {
    position: absolute;
    top: 20px;
    right: 0;
    opacity: 0.15;
    height: 30px;
    z-index: 1000;
  }

  .full-screen-button:hover {
    opacity: 0.5;
  }

  ${fullScreen?`

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center !important;
    margin: 0 !important;

    > svg {
      width: 95%;
      height: 95%;
    }

    .full-screen-button {
      opacity: 0.5;
      top: ${theme.boxPadding.xl4*3}px;
      right: ${theme.boxPadding.xl2}px;
      z-index: 1000;
    }

    .providers-list {
      display: flex;
    }
    `:''}


`}`;
