import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import qs from 'qs';

import { ENV } from 'config';

const {
  apiDebugging,
  acctMgmtApi,
  acctMgmtApiV3,
  dataApiUrl,
  eckleburgApiUrl,
} = ENV;

/* istanbul ignore next */
const customSerializer = (params: any) =>
  qs.stringify(params, { arrayFormat: 'comma' });

export type API_SERVICE =
  | 'accountManagement'
  | 'accountManagementV3'
  | 'dataAPI'
  | 'eckleburgAPI';

export type ApiRequestData = AxiosRequestConfig & {
  service?: API_SERVICE;
  path?: string;
  withRetries?: boolean;
  retryConfig?: IAxiosRetryConfig;
  // this is to handle flask endpoints that are an exception to the standard we're heading towards
  // Gets with params on flask endpoints need to set this to be true
  dontUseCustomSerializer?: boolean;
};
export const apiRequest = async <T>(apiData: ApiRequestData) => {
  const {
    service,
    path,
    headers,
    url,
    withRetries = true,
    retryConfig,
    dontUseCustomSerializer,
    ...rest
  } = apiData;

  try {
    const customAxios = axios.create();
    if (withRetries) {
      axiosRetry(customAxios, {
        retryDelay: axiosRetry.exponentialDelay,
        retries: 4,
        ...retryConfig,
      });
    }
    customAxios.interceptors.request.use(
      async (config) => {
        const newConfig = config;
        /* istanbul ignore next */
        if (apiDebugging) {
          console.warn(`API REQUEST - ${path ?? url}:`, newConfig);
        }
        return newConfig;
      },
      (e) => e,
    );

    // Build URL
    const apiURL = url ? url : buildApiUrl({ service, path });

    const apiConfig: AxiosRequestConfig = {
      url: apiURL,
      headers: { 'Content-Type': 'application/json', ...headers },
      // This was added because of the way fastapi parses serialized arrays
      // https://github.com/tiangolo/fastapi/issues/50#issuecomment-760306781
      paramsSerializer: dontUseCustomSerializer ? undefined : customSerializer,
      ...rest,
    };
    const response = await customAxios.request<T>(apiConfig);

    /* istanbul ignore next */
    if (apiDebugging) {
      console.warn(`API RESPONSE - ${path ?? url}:`, response);
    }
    return response;
  } catch (e: any) {
    console.warn('API Error', {
      apiData,
      e,
      message: e.message,
      response: e.response
    });
    Sentry.captureException(e, {
      tags: {
        service,
        path,
      },
      contexts: {
        message: e.message,
        response: e.response,
      },
    });
    throw e;
  }
};

type buildApiUrlData = {
  service?: API_SERVICE;
  path?: string;
};
export const buildApiUrl = (d: buildApiUrlData) => {
  const { service, path } = d;

  switch (service) {
    case 'accountManagement':
      return `${acctMgmtApi}${path}`;
    case 'accountManagementV3':
      return `${acctMgmtApiV3}${path}`;
    case 'dataAPI':
      return `${dataApiUrl}${path}`;
    case 'eckleburgAPI':
      return `${eckleburgApiUrl}${path}`;
    default:
      throw new Error('Invalid Service Provided');
  }
};
