export const arrayOfObjectToCsv = function(data: any[], keys: string[]){

  const lines = [keys.join(',')];
  
  data.forEach(obj => {
    let line = '';

    keys.forEach( (key,i) => {
      if(obj[key]){
        line += obj[key];
      }

      if(i<keys.length){
        line += ',';
      }
    });

    lines.push(line);
  });

  return 'data:text/csv;charset=utf-8,'+lines.join('\n');
};