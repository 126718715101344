import {
  useState
} from 'react';

import {
  FiLogOut
,
  FiActivity,
  FiFileText
} from 'react-icons/fi';
import {
  VscRadioTower
} from 'react-icons/vsc';
import {
  NavLink
} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {
  Avatar
,
  Logo,
  Popover
} from 'components';
import {
  useAuthContext
} from 'context';

export const Navbar = () => {
  const [userPopoverActive, setUserPopoverActive] = useState<boolean>(false);
  const { fbUser } = useAuthContext();
  const theme = useTheme() as any;

  return (
    <Container>
      <div className="menu left-menu flex">
        <NavLink className={({isActive}) => (String(isActive ? 'active logo' : 'logo'))} to="/" >
          <Logo
            variant='inline'
            />
        </NavLink>

        <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="tmsi-report"><FiFileText /><span>TMSI Report</span></NavLink>
        <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="activity-report"><FiActivity /><span>Activity Report</span></NavLink>
        <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="tmsi-dashboard"><VscRadioTower /><span>TMSI Dashboard</span></NavLink>

      </div>

      <div className="userAccount right-menu flex">

        <Avatar
          username={fbUser?.displayName}
          photoURL={fbUser?.photoURL}
          size={theme.fontSize.xl4}
          variant="iconplaceholder"
          onClick={() => setUserPopoverActive((prev) => !prev)}
          />

        <Popover
          align="right"
          active={userPopoverActive}>
          <RowList>
            <span>
              <b>Account</b><br />
              {fbUser?.email}
            </span>

            <a href="#signout">Sign Out <FiLogOut /></a>
          </RowList>
        </Popover>
      </div>


    </Container>
  );
};

export default Navbar;

const Container = styled.div`${({theme}) => `

  display: flex;
  padding: 0 ${theme.boxPadding.xl2}px;

  border-bottom: 1px solid ${theme.colors.sky.light};

  justify-content: space-between;
  align-items: center;

  > .flex {
    display: flex !important;
    width: auto !important;
    align-items: center;
  }

  > * > * {
    padding-top: ${theme.boxPadding.l}px;
    padding-bottom: ${theme.boxPadding.l}px;
  }

  .menu a:not(:first-child) {
    margin-left: ${theme.boxMargins.xl2/2}px;
    margin-right: ${theme.boxMargins.xl2/2}px;
  }
  .menu a:first-child{
    margin-left: 0;
  }
  .menu a svg {
    margin: auto 3px;
    vertical-align: middle;
    margin-bottom: 5px;
  }
  .left-menu {
    justify-content: flex-start;
  }

  a {
    font-size: ${theme.fontSize.l}px;
    color: ${theme.colors.ink.light};
    line-height: ${theme.fontSize.xl2}px;
  }

  @media (max-width: 800px){
    *:not(.popoverMarker) a {
      text-align: center;
      font-size: ${theme.fontSize.xs}px;
      line-height: auto;
    }
    a > svg {
      font-size: ${theme.fontSize.xl3}px;
    }
    a > span {
      display: block;
    }
    .left-menu {
      justify-content: space-around;
      flex-grow: 10;
    }
  }

  a.logo {
    display: inline-block;
    height: 100%;
  }

  .logo > svg {
    width: 155px;
    height: ${theme.fontSize.xl2}px;
  }

  a:hover {
    color: ${theme.colors.primary.base};
  }

  a.active:not(:first-child) {
    box-shadow: 0 3px 0 ${theme.colors.ink.light} inset;
  }

  .userAccount {
    display: flex;
    justify-content: flex-end;
    user-select: none;
  }
  .userAccount .popoverMarker {
    align-self: flex-end;
    margin-bottom: -5px;
  }

  .userAccount > svg {
    cursor: pointer;
  }

  /*position: sticky;*/
  top: 0;
  background-color: white;
  z-index: 1000;

`}`;

const RowList = styled.div`${({theme}) => `

  background-color: ${theme.colors.sky.white};

  > * {
    display: block;
    white-space: nowrap;
    padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl}px;
  }

  > *:not(:first-child) {
    border-top: 1px solid ${theme.colors.sky.light}
  }

  a {
    font-size: ${theme.fontSize.base}px !important;
    font-family: ${theme.fontFamily.inter};
    line-height: ${theme.fontSize.xl}px;
    vertical-align: middle;
    text-align: left !important;
  }

  a > svg {
    font-size: ${theme.fontSize.base}px !important;
  }

`}`;
