import {
  useState
} from 'react';

import {
  endOfDay,
} from 'date-fns';
import {
  format
} from 'date-fns-tz';
import {
  FiActivity
} from 'react-icons/fi';
import styled from 'styled-components';

import {
  ScrollableDataTableContainer
,
  DataTable,
  InputGroup,
  Switchgroup,
  DatePickerIcon,
  Loading,
  Breadcrumbs
} from 'components';
import {
  NetworkProviderLabel,
  PROVIDERS_ARRAY,

  TMSILabelsMap,
  TMSILabelColorsMap
} from 'config';
import {
  SensorSelect
} from 'elements';
import {
  FlexContainer,
  Flex2,
  Flex3,
  Flex6,
  Flex8,
  Flex9,
  Flex10
} from 'layout';
import {
  AmountOverDateGraph,
  useActivityReport,
  UseActivityReportPayload
} from 'pages';


// NOTE: ALL items are mutable since processed from raw server reply!

const LabelsMap = TMSILabelsMap;
const ColorsMap = TMSILabelColorsMap;

export const ActivityReportModule: React.FC<UseActivityReportPayload> = (activityPayload) => {

  const {
    loading,
    enabledProviders,
    setEnabledProviders,
    dateMode,
    filteredActivityReport,
    startDate,
    endDate,
    cutoffDate
  } = useActivityReport(activityPayload);

  return <StyledActivityReportModule>

    <Flex9>
      <AmountOverDateGraph
        dataGroups={filteredActivityReport}
        pathsCutoff={cutoffDate}
        width={800}
        height={500}
        labelToColorMap={ColorsMap}
        dateTicks={dateMode === 'day' ? 'hours' : dateMode === 'week' ? 'days' : dateMode === 'month' ? 'weeks' : 10}
        padding={{
          top: 25,
          right: 30,
          bottom: 40,
          left: 50
        }}
        startDate={startDate}
        endDate={endDate}
        numberOfVerticalTicks={7}
        numberOfBins={dateMode==='day'?24:20}
        label="# of Cellular Packets"
        >
        {loading?
          <Loading x="340" y="160" />:null}

        {!loading&&!filteredActivityReport?.length?
          <g className="noData" style={{transform: 'translate(360px,170px)'}}>
            <svg width="94" height="86" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M33.4932 55.4999H66.8265M37.6598 30.4999H37.7015M62.6598 30.4999H62.7015M17.8746 16.6582C25.5154 7.30472 37.1403 1.33325 50.1598 1.33325C73.1717 1.33325 91.8265 19.9881 91.8265 42.9999C91.8265 66.0118 73.1717 84.6666 50.1598 84.6666C27.148 84.6666 8.49316 66.0118 8.49316 42.9999C8.49316 40.8757 8.65212 38.7887 8.95879 36.75" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.6602 12.1276L20.03 19.4974C21.4874 20.9539 22.4802 22.8099 22.8827 24.8308C23.2852 26.8516 23.0793 28.9464 22.2911 30.8502C21.5029 32.754 20.1677 34.3812 18.4546 35.5261C16.7414 36.671 14.7272 37.2821 12.6667 37.2821C10.6062 37.2821 8.59193 36.671 6.87877 35.5261C5.16561 34.3812 3.83049 32.754 3.04227 30.8502C2.25406 28.9464 2.04817 26.8516 2.45066 24.8308C2.85314 22.8099 3.84591 20.9539 5.30339 19.4974L12.6602 12.1276Z" stroke="currentColor" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            <text y="115" x="22" fill="currentColor">no data</text>
          </g>
          : null}
      </AmountOverDateGraph>
    </Flex9>
    <Flex2>
      <StyledProvidersChekboxes className="providersCheckboxes">
        {PROVIDERS_ARRAY.map(label =>
          <StyledInputGroup
            key={String(label)}
            variant="custom"
            type="checkbox"
            checked={enabledProviders[label]}
            reverse
            label={' '+(LabelsMap.get(label as NetworkProviderLabel)||label)}
            className=""
            color={ColorsMap.get(label as NetworkProviderLabel)|| 'black'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEnabledProviders({...enabledProviders, [label]:e.target.checked}); }}
            />
        )}
      </StyledProvidersChekboxes>
    </Flex2>
  </StyledActivityReportModule>;
};

export const ActivityReport: React.FC = () => {

  const [sensorId, setSensorId] = useState<string>();

  const {
    unsortedActivityReport,
    dailyProviderActivity,
    loading,
    enabledProviders,
    setEnabledProviders,
    dateMode,
    setDateMode,
    setDateRange,
    filteredActivityReport,
    startDate,
    endDate,
    cutoffDate
  } = useActivityReport({
    sensorId,
    preserveByName: 'activityReportPage'
  });

  return <>
    <Breadcrumbs />

    <Header>

      <Flex6>
        <h2><FiActivity /> Activity Report</h2>
        <p>Welcome to your activity report! Here you can see all the most recent cellular activity as well as explore historical activity.</p>

      </Flex6>

      <Flex6>
        <GrayInset>
          <h5>Page Elements</h5>

          <a href="#activity-graph">Activity Graph</a>
          <a href="#activity-data-table">Activity Data Table</a>
          <a href="#activity-insights">Activity Insights</a>

        </GrayInset>
      </Flex6>

    </Header>

    <Section id="activity-graph" className="header">
      <Flex2 />
      <Flex10>
        <StyledSensorSelect
          onSelect={(sensorId) => setSensorId(sensorId)}
          />
        <h3>Activity Graph</h3>

      </Flex10>
    </Section>

    <FlexContainer>
      <Flex3 />
      <Flex6>
        <Switchgroup>
          <InputGroup
            reverse
            type="radio"
            name="sample-size"
            value="day"
            label="D"
            onChange={() => {
              setDateMode('day');
            }}
            checked={dateMode === 'day'}
            />
          <InputGroup
            reverse
            type="radio"
            name="sample-size"
            value="week"
            label="W"
            onChange={() => {
              setDateMode('week');
            }}
            checked={dateMode === 'week'}
            />
          <InputGroup
            reverse
            type="radio"
            name="sample-size"
            value="month"
            label="M"
            onChange={() => {
              setDateMode('month');
            }}
            checked={dateMode === 'month'}
            />
        </Switchgroup>

        <StyledDateSelection>
          <span>
            {(startDate)?format(startDate, 'MMM dd'):null}
            {(dateMode!=='day'&&endDate)?' - '+format(endDate, 'MMM dd'):null}
          </span>

          <DatePickerIcon
            mode={dateMode}
            onSelect={([dateStart, dateEnd]) => {
              setDateRange([dateStart, dateEnd||endOfDay(dateStart)]);
            }}
            preserveByName="activityReportPage"/>

        </StyledDateSelection>

      </Flex6>
      <Flex3 />
    </FlexContainer>

    <AmountOverDateGraphSection>
      <Flex3 />
      <Flex6>

        <AmountOverDateGraph
          dataGroups={filteredActivityReport}
          pathsCutoff={cutoffDate}
          width={800}
          height={500}
          labelToColorMap={ColorsMap}
          dateTicks={dateMode === 'day' ? 'hours' : dateMode === 'week' ? 'days' : dateMode === 'month' ? 'weeks' : 10}
          padding={{
            top: 25,
            right: 30,
            bottom: 40,
            left: 50
          }}
          startDate={startDate}
          endDate={endDate}
          numberOfVerticalTicks={7}
          numberOfBins={dateMode==='day'?24:20}
          >
          {loading?
            <Loading x="340" y="160" />:null}

          {!loading&&!filteredActivityReport?.length?
            <g className="noData" style={{transform: 'translate(360px,170px)'}}>
              <svg width="94" height="86" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.4932 55.4999H66.8265M37.6598 30.4999H37.7015M62.6598 30.4999H62.7015M17.8746 16.6582C25.5154 7.30472 37.1403 1.33325 50.1598 1.33325C73.1717 1.33325 91.8265 19.9881 91.8265 42.9999C91.8265 66.0118 73.1717 84.6666 50.1598 84.6666C27.148 84.6666 8.49316 66.0118 8.49316 42.9999C8.49316 40.8757 8.65212 38.7887 8.95879 36.75" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.6602 12.1276L20.03 19.4974C21.4874 20.9539 22.4802 22.8099 22.8827 24.8308C23.2852 26.8516 23.0793 28.9464 22.2911 30.8502C21.5029 32.754 20.1677 34.3812 18.4546 35.5261C16.7414 36.671 14.7272 37.2821 12.6667 37.2821C10.6062 37.2821 8.59193 36.671 6.87877 35.5261C5.16561 34.3812 3.83049 32.754 3.04227 30.8502C2.25406 28.9464 2.04817 26.8516 2.45066 24.8308C2.85314 22.8099 3.84591 20.9539 5.30339 19.4974L12.6602 12.1276Z" stroke="currentColor" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <text y="115" x="22" fill="currentColor">no data</text>
            </g>
            : null}
          </AmountOverDateGraph>

      </Flex6>
      <Flex3>
        <StyledProvidersChekboxes>
          {PROVIDERS_ARRAY.map(label =>
            <StyledInputGroup
              key={String(label)}
              variant="custom"
              type="checkbox"
              checked={enabledProviders[label]}
              reverse
              label={' '+(LabelsMap.get(label as NetworkProviderLabel)||label)}
              className=""
              color={ColorsMap.get(label as NetworkProviderLabel)|| 'black'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEnabledProviders({...enabledProviders, [label]:e.target.checked}); }}
              />
          )}
        </StyledProvidersChekboxes>

      </Flex3>
    </AmountOverDateGraphSection>
    {
      dailyProviderActivity?.length
        ?
      <Section id="activity-data-table">
        <Flex2 />
        <Flex8>
          <h3>Activity Data Table</h3>

          <ScrollableDataTableContainer>

              <DataTable
                data={ unsortedActivityReport }

                index={['date', 'amount', 'label']}
                dataFormating={{
                  date: v => format(v as Date, 'EEEE, MMMM do - h  bbb zzz'),
                  label: v => LabelsMap.get(String(v) as NetworkProviderLabel)
                }}
                titlesFormatting={{
                  date: () => <>Time</>,
                  amount: () => <>Amount of Activity</>,
                  label: () => <>Provider</>
                }}
                />

          </ScrollableDataTableContainer>
        </Flex8>
        <Flex2 />
      </Section>
        : null
    }

    <Section id="activity-insights">
      <Flex2 />
      <Flex8>
        <h3>Activity Insights</h3>
      </Flex8>
      <Flex2 />

      <Flex2 />
      <StyledInsights>
        <p className="placeholder">No insights available yet.</p>
      </StyledInsights>
      <Flex2 />
    </Section>
  </>;
};

// const DataTableContainer = styled.div`${({theme}) => `
//   max-height: 200px;
//   overflow-x: scroll;
//   border-radius: 16px;
//   border: 1px solid ${theme.colors.sky.base};
//
//   table {
//     border-spacing: 0px;
//     border-collapse: collapse;
//   }
//
//   th {
//     background-color: ${theme.colors.sky.lighter};
//     font-weight: normal;
//     text-align: left;
//   }
//
//   th, td {
//     border: 1px solid ${theme.colors.sky.base};
//     padding: ${theme.fontPadding.xxs}px ${theme.fontPadding.m}px;
//     font-size: ${theme.fontSize.base}px;
//   }
//
//   table {
//     width:101%;
//     margin-left:-1px;
//     margin-top:-1px;
//     margin-bottom:-1px;
//     margin-right:-1px;
//   }
//
//   table {
//     position: relative;
//   }
//
//   th {
//     position: sticky;
//     top: -1px;
//   }
//
// `}`;

const StyledInputGroup = styled(InputGroup).attrs({} as {
  color: string;
})`${({color}) => `
  svg {
    color: ${color};
  }
`}`;

const StyledProvidersChekboxes = styled.div`${({theme}) => `
  display: inline-block;
  vertical-align: top;
  margin-left: ${theme.boxMargins.xl4}px;
  margin-top: 25px;
`}`;

const Section = styled(FlexContainer)`${({theme}) => `
  margin-top: ${theme.boxMargins.xl5}px;
  h3 {
    margin-bottom: ${theme.fontPadding.m}px;
  }

  @media (max-width: 570px){
    &#activity-graph.header{
      margin-bottom: ${theme.boxPadding.base}px;
    }
  }


`}`;

const AmountOverDateGraphSection = styled(FlexContainer)`${({theme}) => `
  .amountOverDateGraph {
    width: 100%;
    height: auto;
    overflow: visible;
  }
  .amountLabel {
    fill: ${theme.colors.ink.lighter};
    font-size: ${theme.fontSize.m}px;
  }
  .noData {
    color: ${theme.colors.ink.lighter};
  }
`}`;

const StyledDateSelection = styled.div`${({theme}) => `
  font-size: ${theme.fontSize.base}px;
  padding: ${theme.fontPadding.xxs}px 0;

  svg {
    vertical-align: middle;

    margin: ${theme.fontMargins.s}px;
  }
  > span {
    margin-right: ${theme.fontMargins.xs}px;
  }

  float: right;
`}`;

const Header = styled(FlexContainer)`${({theme})=> `
  padding: ${theme.boxPadding.xl3}px ${theme.boxPadding.xl7}px;
  border-radius: ${theme.boxPadding.l}px;
  background-color: ${theme.colors.sky.lightest};

  > * {
    position: relative;
  }

  p {
    margin-right: ${theme.boxMargins.xl9*2}px;
  }
`}`;

const GrayInset = styled.div`${({theme}) => `
  padding: ${theme.boxPadding.xl5}px;
  border-radius: ${theme.boxPadding.base}px;
  background-color: ${theme.colors.sky.light};
  min-height: 100%;
  box-sizing: border-box;

  a {

    display: block;

    color: ${theme.colors.ink.lighter};
    cursor: pointer;

    padding: ${theme.boxPadding.base}px ${theme.boxPadding.xl}px;

    margin-left: ${-theme.boxPadding.xl+theme.boxPadding.base}px;

  }
`}`;

const StyledInsights = styled(Flex8)`${({theme}) => `
  p {
    font-size: ${theme.fontSize.base}px;
    color: ${theme.colors.ink.base};

    padding: ${theme.fontPadding.s}px ${theme.fontPadding.xl}px;
    border: 1px solid ${theme.colors.sky.base};
    border-radius: ${theme.fontPadding.xs}px;
    margin: 0;
  }
`}`;

const StyledActivityReportModule = styled(FlexContainer)`${({theme}) => `
  padding-left: 30px;
  .amountOverDateGraph {
    width: 100%;
    height: auto;
    overflow: visible;
  }
  .amountLabel {
    fill: ${theme.colors.ink.lighter};
    font-size: ${theme.fontSize.m}px;
  }
  .noData {
    color: ${theme.colors.ink.lighter};
  }
  .providersCheckboxes {
    margin-left: 0;
  }
`}`;

const StyledSensorSelect = styled(SensorSelect)`${() => `
  float: right;
`}`;
