import {
  FiArrowRight
} from 'react-icons/fi';
import {
  VscRadioTower
} from 'react-icons/vsc';
import {
  useNavigate
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Breadcrumbs,
  Button
} from 'components';
import {
  FlexContainer,
  Flex12,
  Flex6,
  Flex7,
  Flex5,
} from 'layout';
import {
  TmsiActivity,
  TMSIMonitor
} from 'pages';

export const TMSIDashboard = () => {
  const navigate = useNavigate();

  return <>
    <Breadcrumbs />

    <Header>

      <Flex6>
        <h2><VscRadioTower /> TMSI Dashboard</h2>
        <p>TMSI stands for temporary mobile susbscriber identity. Devices use this number to communicate with cell towers and access cell networks - it’s kind of like how wifi devices use an IP address on a wifi network. </p>
        <p>TMSIs have interesting metadata, like cell provider, that we can use to establish baselines for the area around your sensor. For example, if we’re used to seeing AT&T TMSIs, and all of a sudden a T-mobile TMSI shows up in the middle of the night, this may indicat there is an unusual or unwanted device on site.</p>

      </Flex6>

      <Flex6>
        <GrayInset>
          <h5> Page Elements</h5>
          <LinksList>
            <a href="#tmsi-pings">Real-time TMSI Pings</a>
            <a href="#tmsi-in-time">TMSIs Over Time </a>
          </LinksList>
        </GrayInset>
      </Flex6>

    </Header>

    <Section id="tmsiPingsSection">

      <Flex7>
        <h3 id="tmsi-pings">TMSI Pings</h3>
        <p>This animation represents the most recent TMSI the sensor has seen. You can also see in real-time the information your sensor has captured for that TMSI.</p>
      </Flex7>
      <Flex5 />

      <TMSIMonitor />

    </Section>

    <div id="tmsi-in-time">
      <TmsiActivity />
    </div>

    <Section>
      <Flex12>
        <Button variant="faint" onClick={() => navigate('/tmsi-report')}>TMSI Report&nbsp;&nbsp;&nbsp;&nbsp;<FiArrowRight /></Button>
      </Flex12>
    </Section>

  </>;
};

// Dashboard
const Header = styled(FlexContainer)`${({theme})=> `
  padding: ${theme.boxPadding.xl3}px ${theme.boxPadding.xl7}px;
  border-radius: ${theme.boxPadding.l}px;
  background-color: ${theme.colors.sky.lightest};
  min-height: 272px;

  > * {
    position: relative;
  }

  button {
    position: absolute;
    bottom: 0;
  }

  p {
    margin-right: ${theme.boxMargins.xl9*2}px;
  }
`}`;

const Section = styled(FlexContainer)`${({theme}) => `
  margin: ${theme.boxPadding.xl9}px ${theme.boxPadding.xl5}px;

  #tmsiMonitor {
    width: 100%;
    margin-top: ${theme.boxPadding.xl2}px;
  }

  flex-direction: row;

  button {
    float: right;
    margin: ${theme.boxMargins.xl3}px 0;
  }

`}`;

const GrayInset = styled.div`${({theme}) => `
  padding: ${theme.boxPadding.xl5}px;
  border-radius: ${theme.boxPadding.base}px;
  background-color: ${theme.colors.sky.light};
  min-height: 100%;
  box-sizing: border-box;
`}`;

const LinksList = styled.div.attrs({} as {
  variant: 'plain' | 'gray';
})`${({theme, variant}) => `

  padding: 0;
  display: inline-block;

  border-radius: ${theme.boxPadding.base}px;

  > a {
    display: block;

    padding: ${theme.boxPadding.base}px;
    padding-left: ${theme.boxPadding.base}px;

    margin: ${theme.boxMargins.base}px 0;
  }

  > a {
    color: ${theme.colors.ink.base};
  }

  > a > svg:first-child{
    margin-right: ${theme.boxPadding.base}px;
  }

  ${variant === 'gray' ? `

    padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl2}px;
    background-color: ${theme.colors.sky.lighter};

  ` : ''};

`}`;
