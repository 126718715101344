import styled from 'styled-components';

import {UID} from 'utils';

export type CheckboxProps = {
  disabled?: boolean,
  checked?: boolean,
  defaultChecked?: boolean|undefined,
  id?: string,
  el?: React.MutableRefObject<HTMLInputElement>,
  name?: string,
  placeholder?: string,
  className?: string,
  value?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  variant?:'custom'|'normal'
}

export const Checkbox: React.FC<CheckboxProps> = ({
  disabled=false,
  checked,
  defaultChecked=undefined,
  id=UID(),
  el=null,
  name,
  className,
  value,
  onChange,
  variant='normal'
}) => {

  const checkbox = <StyledInput
      id={id}
      ref={el}
      key={id}
      type="checkbox"
      name={name}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      className={className}
      value={value}
      disabled={disabled}
      />;

  return variant === 'custom'
    ?
    <StyledLabel>{checkbox}<svg className="checkmark" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="currentColor" stroke="currentColor" />
        <path d="M17 8.25L10.125 15.125L7 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </StyledLabel>
    :
    checkbox;
};

const StyledInput = styled.input`${({theme}) => `
  width: ${theme.fontSize.xl2}px;
  height: ${theme.fontSize.xl2}px;
`}`;

const StyledLabel = styled.label`${({theme}) => `
  input {
    display: none;
  }

  .checkmark{
    display: inline-block;

    width: ${theme.fontSize.xl2}px;
    height: ${theme.fontSize.xl2}px;

    color: ${theme.colors.primary.blue};
  }

  input:not(:checked) + .checkmark rect{
    fill: none !important;
  }
`}`;
