import {
  TmsiReport
} from 'api';
import {
  arrayOfObjectToCsv,
  saveFile
} from 'utils';

export function downloadTmsiReport(report: TmsiReport, filename='tmsiExport.csv'){
  const csv = arrayOfObjectToCsv(report, [
    'type',
    'networkProvider',
    'powerEstimate',
    'tmsi',
    'date',
  ]);

  saveFile(filename, csv);
}
